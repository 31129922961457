import React from "react";
import coverImage from "../assets/img/coverImage.png";
import policyPurchaseSuccess from "../assets/img/policy_purchased_success.svg";
import Button from "../components/ui/Button";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { apiService } from "../api.service";
import moment from "moment";
import axios from 'axios';
import loadingBarService from "../service/loadingbar.Service";

export default function PurchaseStatus() {
  const navigate = useNavigate();
  const params = useParams();
  console.log(params);
  const [policySummary, setPolicySummary] = useState();

  const onCreateNewPolicyClick = () => {
    navigate("service/generate-policy");
  };

  // const getPolicySummary = async () => {
  //   await apiService.getPolicySummary(params.policyNumber).then((res) => {
  //     setPolicySummary(res.data.data);
  //   });
  // };

  const getPolicySummary = async () => {
    try {
      console.log(params);
      loadingBarService.start(); // Start the loading bar
      const res = await apiService.getPolicySummary(params.policyNumber);
      setPolicySummary(res.data.data);
    } catch (error) {
      console.error("Error fetching policy summary", error);
    } finally {
      loadingBarService.complete(); // Complete the loading bar
    }
  };

  useEffect(() => {
    getPolicySummary();
  }, []);

  const downloadPolicyPDF = async () => {
    try {
      loadingBarService.start();
      const response = await apiService.downloadPolicyPdf(params.policyNumber);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `policy_${params.policyNumber}.pdf`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error("Error downloading the PDF", error);
    } finally {
      loadingBarService.complete(); // Complete the loading bar
    }
  };

  return (
    <div className="flex flex-col w-full items-center">
      <div className="sticky -z-10 top-12 w-full">
        {/* <i className="material-symbols-outlined hidden md:block fixed text-white ml-4 mt-2">
          arrow_back
        </i> */}
        <img
          src={coverImage}
          className=" w-full h-36 object-cover"
          alt="cover_image"
        />
      </div>
      <div className=" md:w-1/2 min-w-fit">
        <div className=" bg-white -mt-28 px-6 py-4 md:-mt-20 border border-neutral-light rounded">
          <div className="flex flex-col items-center">
            <img
              src={policyPurchaseSuccess}
              className="w-36"
              alt="policy_purchase_success"
            />
            <h3 className="mt-4 text-xl font-semibold">Congratulations!</h3>
            <p className="text-sm">
              The policy has been successfully purchased.
            </p>
            <div className="flex justify-center w-60 my-4 mx-auto">
              <Button
                type="button"
                label="Download Policy PDF"
                variant="primary"
                onClick={downloadPolicyPDF}
              />
            </div>
            <div className="my-4">
              <table className="text-sm space-x-4">
                <tr>
                  <td className="w-40 text-neutral-darker text-right pr-4">
                    Policy Number
                  </td>
                  <td>{policySummary?.policyNumber}</td>
                </tr>
                <tr>
                  <td className="w-40 text-neutral-darker text-right pr-4">
                    Plan Name
                  </td>
                  <td>{policySummary?.planName}</td>
                </tr>
                <tr>
                  <td className="w-40 text-neutral-darker text-right  pr-4">
                    Customer Name
                  </td>
                  <td>{policySummary?.customerName}</td>
                </tr>
                <tr>
                  <td className="w-40 text-neutral-darker text-right  pr-4">
                    Mobile Number
                  </td>
                  <td>{policySummary?.mobileNumber}</td>
                </tr>
                <tr>
                  <td className="w-40 text-neutral-darker text-right  pr-4">
                    Email
                  </td>
                  <td>{policySummary?.email}</td>
                </tr>
                <tr>
                  <td className="w-40 text-neutral-darker text-right  pr-4">
                    Plan Amount
                  </td>
                  <td>Rs. {policySummary?.policyAmount}/-</td>
                </tr>
                <tr>
                  <td className="w-40 text-neutral-darker text-right  pr-4">
                    Policy Start Date
                  </td>
                  <td>
                    {moment(policySummary?.policyStartDate).format("LLL")}
                  </td>
                </tr>
                <tr>
                  <td className="w-40 text-neutral-darker text-right  pr-4">
                    Policy End Date
                  </td>
                  <td>
                    {moment(policySummary?.policyEndDate).format("LLL")}
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
        <div className="flex justify-center">
          <div className="flex justify-center py-4 pb-8 w-72 md:w-96 space-x-4">
            <Button
              type="submit"
              label="Create New Policy"
              variant="primary"
              className="w-48"
              onClick={onCreateNewPolicyClick}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
