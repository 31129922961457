import React from "react";
import pageNotFound from "../assets/img/policy_purchase_failed.svg";
import { Link } from "react-router-dom";

export const ServerDown = () => {
    return (
        <>
            <div className="flex justify-center mt-12">
                <div className="flex flex-col text-center">
                    <img src={pageNotFound} className="w-72" alt="page_not_found" />
                    <p className="mt-8  text-lg">Server Down!</p>
                    
                </div>
            </div>

        </>
    )
}
