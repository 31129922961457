// Set data to local storage

export const service = {
    setLocalStorageItem: (key, value) => {
        try {
            localStorage.setItem(key, JSON.stringify(value));
        } catch (error) {
            console.error('Error setting localStorage item:', error);
        }
    },
    getLocalStorageItem: (key) => {
        try {
            const item = localStorage.getItem(key);
            return item ? JSON.parse(item) : null;
        } catch (error) {
            console.error('Error getting localStorage item:', error);
            return null;
        }
    },
    setSessionStorageItem: (key, value) => {
        try {
            sessionStorage.setItem(key, JSON.stringify(value));
        } catch (error) {
            console.error('Error setting sessionStorage item:', error);
        }
    },
    getSessionStorageItem: (key) => {
        try {
            const item = sessionStorage.getItem(key);
            return item ? JSON.parse(item) : null;
        } catch (error) {
            console.error('Error getting sessionStorage item:', error);
            return null;
        }
    }
}