import React, { useState, useEffect } from "react";
import { ResponsivePie } from "@nivo/pie";
import { ResponsiveLine } from "@nivo/line";
import { ResponsiveBar } from "@nivo/bar";
import CountUp from "react-countup";
import imageGold from "../../../assets/img/gold.png";
import imageSilver from "../../../assets/img/silver.png";
import imageBronze from "../../../assets/img/bronze.png";
import chroma from "chroma-js";
import moment from "moment";
import toast, { Toaster } from "react-hot-toast";
import { apiService } from "../../../api.service";
import { async } from "q";

// Top sales data (unchanged)
const topSales = [
  { id: 1, icon: "login", cases: "Logged In Dealers (FTD)", value: -1 },
  { id: 2, icon: "policy", cases: "Sold Policy (FTD)", value: 13400 },
  { id: 4, icon: "dashboard", cases: "Sold Policy (MTD)", value: 34500 },
  { id: 3, icon: "person", cases: "Active Users (MTD)", value: 7487 },
];

const topModelSales = [
  { id: 1, product: "TVS Apache RR 310", sales: 120, revenue: 9400 },
  { id: 2, product: "TVS Apache RTR 160", sales: 90, revenue: 8800 },
  { id: 3, product: "TVS Ntorq 125", sales: 75, revenue: 7500 },
  { id: 4, product: "TVS Raider 125", sales: 75, revenue: 6500 },
  { id: 5, product: "TVS Apache RTR 180", sales: 75, revenue: 5500 },
  // { id: 6, product: 'TVS Apache RTR 200 4V', sales: 75, revenue: 4500 },
  // { id: 7, product: 'TVS Jupiter', sales: 75, revenue: 3500 },
  // { id: 8, product: 'TVS Radeon', sales: 75, revenue: 2500 },
  // { id: 9, product: 'TVS Ronin', sales: 75, revenue: 1500 },
  // { id: 10, product: 'TVS Sport', sales: 75, revenue: 1500 },
];

// const channelBasedVolumeData = [
//   {
//     type: "Volume",
//     Inclusion: 4000,
//     Sales: 3455,
//     Service: 5455,
//     Website: 3000,
//     "QR Code": 450,
//     "Mobile App": 3455,
//   },
// ];
// Pie chart data (unchanged)
// const pieData = topModelSales.map(sale => ({
//   id: sale.product,
//   label: sale.product,
//   value: sale.revenue,
// }));

const customColors = [
  "#051B34", // darkest
  "#092E58", // default
  "#A5CFFF", // light
  "#C9DFF9", // lighter
  "#E8F2FD", // lightest
];

const chartColorSet = [
  "#092E58", // default
  "#A5CFFF", // light
  "#FF5733", // Vibrant Orange
  "#6B5B95", // Ultra Violet
  "#88B04B", // Greenery
  "#F7CAC9", // Rose Quartz
  "#92A8D1", // Serenity
  "#955251", // Marsala
  "#009473", // Emerald
  "#DD4124", // Tangerine Tango
  "#D94F70", // Honeysuckle
  "#45B8AC", // Turquoise
  "#EFC050", // Mimosa
  "#9B2335", // Chilli Pepper
  "#5B5EA6", // Royal Blue
  "#DFCFBE", // Sand Dollar
  "#55B4B0", // Biscay Bay
  "#E15D44", // Burnt Orange
  "#7FCDCD", // Aquamarine
  "#BC243C", // Scarlet
  "#C3447A", // Raspberry Radiance
  "#98B4D4", // Pastel Blue
];

// Plan names with random counts
const plans = [
  "APACHE SERVICE",
  "BASIC RSA (MOPED Renewal) + 15 Lac",
  "Basic Service",
  "COMBO",
  "COVID",
  "Doctor Consultation",
  "Gold",
  "HC",
  "I-Qube (15Lac)",
  "I-Qube (5Lac)",
  "I-Qube(5Lac)",
  "I-Qube(Renewal)",
  "IQUBE (50KM)",
  "IQUBE (50KM) Trial Pack User",
  "IQUBE EV (50KM)",
  "LIMITLESS ASSIST (Ronin)",
  "LIMITLESS ASSIST E (RR 310)",
  "Limitless Assist Plus (Renewal-Ronin)",
  "Limitless Assist Plus (Renewal-RR-310)",
  "Limitless Assist Plus (Renewal-RTR 310)",
  "LIMITLESS ASSIST RENEWAL",
  "LIMITLESS ASSIST(RR 310)",
  "Platinum",
  "Rider Care",
  "RSA",
  "RSA Basic",
  "RSA SERVICE",
  "Sapphire",
  "Sapphire Plus",
  "Service Beyond Assistance",
  "Service Beyond Assistance (AMC-MOPED)",
  "Service Beyond Assistance (AMC)",
  "Service Beyond Assistance (Renewal MOPED)",
  "Service Beyond Assistance (Renewal with 5 Lac PA)",
  "Service Beyond Assistance (Renewal)",
  "Service Beyond Assistance +",
  "Service Beyond Assistance Plus(15 Lac)",
  "Service Beyond Assistance Plus(5 Lac)",
  "Service Beyond Assistance Plus(AMC)",
  "Service Beyond Assistance Plus(Renewal)",
  "Silver",
  "SNT",
  "SOS",
];

// const topDealers = [
//   { rank: 1, dealerName: 'GLOBAL AUTOCRAFT LLP', value: 72 },
//   { rank: 2, dealerName: 'LOTUS AUTO PRIVATE LIMITED', value: 39 },
//   { rank: 3, dealerName: 'PATEL MOBILITY LLP', value: 38 },
//   { rank: 4, dealerName: 'HEMANTH MOTORS', value: 38 },
//   { rank: 5, dealerName: 'BRIDGEWELL MOTORS LLP', value: 31 },
//   { rank: 6, dealerName: 'PRESTIGE AUTOMOTIVE PRIVATE LIMITED', value: 26 },
//   { rank: 7, dealerName: 'SHANTI KANGAN MOTORS PVT LTD', value: 26 },
//   { rank: 8, dealerName: 'MAHALAXMI GRAND WHEELERS LLP', value: 26 },
//   { rank: 9, dealerName: 'THREE S AUTOMOTIVES PVT.LTD', value: 26 },
//   { rank: 10, dealerName: 'TWO S AUTOMOTIVES PVT.LTD', value: 26 },
// ]

const targetVsAchievementData = [
  {
    id: "Target",
    color: "hsl(217, 70%, 50%)",
    data: [
      { x: "Jan", y: 500 },
      { x: "Feb", y: 600 },
      { x: "Mar", y: 700 },
      { x: "Apr", y: 800 },
      { x: "May", y: 900 },
      { x: "Jun", y: 1000 },
      { x: "Jul", y: 1100 },
      { x: "Aug", y: 1200 },
      { x: "Sep", y: 1300 },
      { x: "Oct", y: 1400 },
      { x: "Nov", y: 1500 },
      { x: "Dec", y: 1600 },
    ],
  },
  {
    id: "Achievement",
    color: "hsl(136, 70%, 50%)",
    data: [
      { x: "Jan", y: 450 },
      { x: "Feb", y: 550 },
      { x: "Mar", y: 600 },
      { x: "Apr", y: 700 },
      { x: "May", y: 850 },
      { x: "Jun", y: 950 },
      { x: "Jul", y: 1050 },
      { x: "Aug", y: 1150 },
      { x: "Sep", y: 1200 },
      { x: "Oct", y: 1300 },
      { x: "Nov", y: 1400 },
      { x: "Dec", y: 1550 },
    ],
  },
];
// Add random count values to plans and sort by count in descending order
const sortedPlans = plans
  .map((name, index) => ({
    id: index + 1,
    name,
    count: Math.floor(Math.random() * 1000) + 1, // Random count between 1 and 1000
  }))
  .sort((a, b) => b.count - a.count); // Sort by count in descending order

// Select top 10 plans
const topPlans = sortedPlans.slice(0, 10);

// Select remaining plans for line chart
const restPlans = sortedPlans.slice(10);

// Generate sample data for line chart
const generateLineChartData = () => {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  return months.map((month, index) => ({
    month,
    count: Math.floor(Math.random() * 1000) + 1, // Random count between 1 and 1000
  }));
};

export const ServiceDashboard = () => {
  const [loggedInUserCount, setLoggedInUserCount] = useState(0);
  const [key1, setKey1] = useState(0);
  const [totalSoldPolicyCount, setTotalSoldPolicyCount] = useState(0);
  const [totalSoldPolicyCountFTD, setTotalSoldPolicyCountFTD] = useState(0);
  const [key2, setKey2] = useState(0);
  const [totalSoldPolicyCountMTD, setTotalSoldPolicyCountMTD] = useState(0);
  const [key3, setKey3] = useState(0);
  const [activeUserCount, setActiveUserCount] = useState(0);
  const [key4, setKey4] = useState(0);
  const [topDealers, setTopDealers] = useState([]);
  const [modelWiseReport, setModelWiseReport] = useState([]);
  const [serviceTargetVsAchievement, setServiceTargetVsAchievement] = useState(
    []
  );
  const [channelBasedVolumeReport, setChannelBasedVolumeReport] = useState([]);
  const [zoneOptions, setZoneOptions] = useState([]);
  const [areaOptions, setAreaOptions] = useState([]);
  const [selectedArea, setSelectedArea] = useState("");
  const [selectedZone, setSelectedZone] = useState("");
  const [selectedMonth1, setSelectedMonth1] = useState("");
  const [selectedYear2, setSelectedYear2] = useState("");
  const [selectedMonth3, setSelectedMonth3] = useState("");
  const [selectedMonth4, setSelectedMonth4] = useState("");
  const initalPayload1 = {
    zoneId: 0,
    areaId: 0,
    month: moment().format("YYYY-MM"),
  };
  const initalPayload2 = {
    zoneId: 0,
    areaId: 0,
    year: moment().format("YYYY"),
  };
  const initalPayload3 = {
    zoneId: 0,
    areaId: 0,
    month: moment().format("YYYY-MM"),
  };
  const initalPayload4 = {
    zoneId: 0,
    areaId: 0,
    month: moment().format("YYYY-MM"),
  };
  const [payload1, setPayload1] = useState(initalPayload1);
  const [payload2, setPayload2] = useState(initalPayload2);
  const [payload3, setPayload3] = useState(initalPayload3);
  const [payload4, setPayload4] = useState(initalPayload4);
  const [prevPayload1, setPrevPayload1] = useState(initalPayload1);
  const [prevPayload2, setPrevPayload2] = useState(initalPayload2);
  const [prevPayload3, setPrevPayload3] = useState(initalPayload3);
  const [prevPayload4, setPrevPayload4] = useState(initalPayload4);
  const [yearsOptions, setYearOptions] = useState([]);

  const formatMonthYear = (month) => {
    return moment(month, "YYYY-MM").format("MMM YYYY"); // Format: "Jul 2024"
  };

  const getLabelTextColor = (bgColor) => {
    console.log(bgColor, chroma.contrast(bgColor, "white"));
    const textColor =
      chroma.contrast(bgColor, "white") > 4.5 ? "white" : "black";
    console.log(textColor);
    return textColor;
  };

  const fetchLoggedInUserCount = async () => {
    await apiService.loggedInUserCount().then((res) => {
      console.log(res.data.count);
      setLoggedInUserCount(res.data.count);
      setKey1(prevKey => prevKey + 1);
    }).catch((error) => {
      console.error(error);
      toast.error(error.response.data.message);
    });

  };

  const fetchTotalSoldPolicyCount = async (frequency) => {
    try {
      const payload = {
        frequency,
      };
      await apiService.totalSoldPoliciesCount(payload).then((res) => {
        if (frequency === "ftd") {
          setTotalSoldPolicyCountFTD(res.data.count);
          setKey2(prevKey => prevKey + 1);
        } else if (frequency === "mtd") {
          setTotalSoldPolicyCountMTD(res.data.count);
          setKey3(prevKey => prevKey + 1);
        }
        
      });
    } catch (error) {
      console.error("Error fetching total sold policies count:", error);
      // Handle error (e.g., show error message)
    }
  };
  const fetchActiveUsersCount = async () => {
    try {
      await apiService.activeUsersCount().then((res) => {
        const count = res.data.count;
        setActiveUserCount(count);
        setKey4(prevKey => prevKey + 1);
      });
    } catch (error) {
      console.error("Error fetching logged in user count:", error);
      // Handle error (e.g., show error message)
    }
  };
  const handleZoneChange1 = async (event) => {
    const selectedZoneId = parseInt(event.target.value);
    setPayload1((prev) => ({
      ...prev,
      zoneId: selectedZoneId,
    }));

    if (selectedZoneId === 0) {
      // Zone "ALL" selected
      setAreaOptions([{ id: 0, name: "ALL", label: "ALL" }]);
      setSelectedArea(0);
    } else {
      // Specific zone selected, fetch corresponding areas
      try {
        await fetchAreaOptions(selectedZoneId);
      } catch (error) {
        console.error(error);
        toast.error(error.response.data.message);
      }
    }
  };
  const handleZoneChange2 = async (event) => {
    const selectedZoneId = parseInt(event.target.value);
    setPayload3((prev) => ({
      ...prev,
      zoneId: selectedZoneId,
    }));

    if (selectedZoneId === 0) {
      // Zone "ALL" selected
      setAreaOptions([{ id: 0, name: "ALL", label: "ALL" }]);
      setSelectedArea(0);
    } else {
      // Specific zone selected, fetch corresponding areas
      try {
        await fetchAreaOptions(selectedZoneId);
      } catch (error) {
        console.error(error);
        toast.error(error.response.data.message);
      }
    }
  };
  const handleZoneChange3 = async (event) => {
    const selectedZoneId = parseInt(event.target.value);
    setPayload3((prev) => ({
      ...prev,
      zoneId: selectedZoneId,
    }));

    if (selectedZoneId === 0) {
      // Zone "ALL" selected
      setAreaOptions([{ id: 0, name: "ALL", label: "ALL" }]);
      setSelectedArea(0);
    } else {
      // Specific zone selected, fetch corresponding areas
      try {
        await fetchAreaOptions(selectedZoneId);
      } catch (error) {
        console.error(error);
        toast.error(error.response.data.message);
      }
    }
  };
  const handleZoneChange4 = async (event) => {
    const selectedZoneId = parseInt(event.target.value);
    setPayload4((prev) => ({
      ...prev,
      zoneId: selectedZoneId,
    }));

    if (selectedZoneId === 0) {
      // Zone "ALL" selected
      setAreaOptions([{ id: 0, name: "ALL", label: "ALL" }]);
      setSelectedArea(0);
    } else {
      // Specific zone selected, fetch corresponding areas
      try {
        await fetchAreaOptions(selectedZoneId);
      } catch (error) {
        console.error(error);
        toast.error(error.response.data.message);
      }
    }
  };
  const handleAreaChange1 = async (event) => {
    setPayload1((prev) => ({
      ...prev,
      areaId: parseInt(event.target.value),
    }));
    const payload = {
      areaId: payload1.areaId,
    };
  };
  const handleAreaChange2 = async (event) => {
    setPayload2((prev) => ({
      ...prev,
      areaId: parseInt(event.target.value),
    }));
    const payload = {
      areaId: payload2.areaId,
    };
  };
  const handleAreaChange3 = async (event) => {
    setPayload3((prev) => ({
      ...prev,
      areaId: parseInt(event.target.value),
    }));
    const payload = {
      areaId: payload3.areaId,
    };
  };
  const handleAreaChange4 = async (event) => {
    setPayload4((prev) => ({
      ...prev,
      areaId: parseInt(event.target.value),
    }));
    const payload = {
      areaId: payload4.areaId,
    };
  };
  const handleMonthChange1 = (event) => {
    const selectedMonth = event.target.value;
    //const { value } = event.target;
    setPayload1((prev) => ({
      ...prev,
      month: selectedMonth,
    }));
  };
  const handleYearChange2 = (event) => {
    const selectedYear = event.target.value;
    //const { value } = event.target;
    setPayload2((prev) => ({
      ...prev,
      year: selectedYear,
    }));
  };
  const handleMonthChange3 = (event) => {
    const selectedMonth = event.target.value;
    //const { value } = event.target;
    setPayload3((prev) => ({
      ...prev,
      month: selectedMonth,
    }));
  };
  const handleMonthChange4 = (event) => {
    const selectedMonth = event.target.value;
    //const { value } = event.target;
    setPayload4((prev) => ({
      ...prev,
      month: selectedMonth,
    }));
  };
  const fetchTopDealers = async () => {
    // const response = await apiService.topDealers(payload1);
    await apiService
      .topDealers(payload1)
      .then((res) => {
        console.log(res.data.data);
        setTopDealers(res.data.data); // Assuming API response contains an array of top dealers
        setSelectedMonth1(payload1.month);
        setPrevPayload1(payload1);
      })
      .catch((error) => {
        console.error(error);
        setPayload1(prevPayload1);
        toast.error(error.response.data.message);
      });
  };

  const fetchServiceTargetVsAchievement = async () => {
    await apiService
      .serviceTargetVsAchievement(payload2)
      .then((res) => {
        console.log(res.data.data);
        setServiceTargetVsAchievement(res.data.data);
        setSelectedYear2(payload2.year);
        setPrevPayload2(payload2);
      })
      .catch((error) => {
        console.log(error.response.data.message);
        setPayload2(prevPayload2);
        toast.error(error.response.data.message);
      });
  };
  const fetchModelWiseReport = async () => {
    await apiService
      .modelWiseReport(payload3)
      .then((res) => {
        console.log(res.data.data);
        setModelWiseReport(res.data.data);
        setSelectedMonth3(payload3.month);
        setPrevPayload3(payload3);
      })
      .catch((error) => {
        console.log(error.response.data.message);
        setPayload3(prevPayload3);
        toast.error(error.response.data.message);
      });
  };
  const fetchChannelBasedVolumeReport = async () => {
    await apiService
      .channelBasedVolumeReport(payload4)
      .then((res) => {
        console.log(res.data.data);
        setChannelBasedVolumeReport(res.data.data);
        setSelectedMonth4(payload4.month);
        setPrevPayload4(payload4);
      })
      .catch((error) => {
        console.log(error.response.data.message);
        setPayload4(prevPayload4);
        toast.error(error.response.data.message);
      });
  };
  const fetchAreaOptions = async (zoneId) => {
    try {
      const payload = {
        zoneId: zoneId,
      };
      const res = await apiService.areaOptions(payload);
      console.log(res.data.data);
      setAreaOptions(res.data.data);
      if (res.data.data.length > 0) {
        setSelectedArea(res.data.data[0].id); // Assuming you want to select the first area by default
      }
    } catch (error) {
      console.error(error);
      toast.error(error.response.data.message);
    }
  };
  const fetchZoneOptions = async () => {
    await apiService
      .zoneOptions()
      .then((res) => {
        console.log(res.data.data);
        setZoneOptions(res.data.data);
        if (res.data.data.length > 0) {
          setSelectedZone(res.data.data[0].id);
        }
      })
      .catch((error) => {
        console.error(error);
        toast.error(error.response.data.message);
      });
  };

  const pieData = modelWiseReport.map((item) => ({
    id: item.modelName || "Unknown", // Handle null modelName
    label: item.modelName || "Unknown", // Handle null modelName
    value: item.count,
  }));

  const generateYearsOptions = () => {
    const currentYear = moment().year();
    const yearsArray = [];
    for (let year = 2016; year <= currentYear; year++) {
      yearsArray.push(year);
    }
    setYearOptions(yearsArray);
  };

  useEffect(() => {
    generateYearsOptions();
    fetchZoneOptions();
    fetchAreaOptions();

    fetchLoggedInUserCount();
    fetchTotalSoldPolicyCount("ftd"); // Fetch FTD count
    fetchTotalSoldPolicyCount("mtd"); // Fetch MTD count
    fetchActiveUsersCount();
  }, []);

  useEffect(() => {
    if (selectedZone !== "") {
      fetchAreaOptions(selectedZone);
    }
  }, [selectedZone]);
  useEffect(() => {
    fetchTopDealers();
  }, [payload1]);

  useEffect(() => {
    fetchServiceTargetVsAchievement();
  }, [payload2]);

  useEffect(() => {
    fetchModelWiseReport();
  }, [payload3]);

  useEffect(() => {
    fetchChannelBasedVolumeReport();
  }, [payload4]);

  return (
    <div className="min-h-screen bg-gray-100 pt-2 px-6 w-full pb-5">
      <div className="w-full">
        {/* Dashboard Header */}
        <header className="flex justify-between items-center pt-1 pb-4">
          {/* <h1 className="text-3xl font-bold text-gray-900">Dashboard</h1> */}
        </header>

        {/* Cards Section (unchanged) */}
        {/* <div className="flex justify-between mb-8">
          {topSales.map(sale => (
            <div
              key={sale.id}
              className="bg-white shadow rounded-lg p-4 transform transition duration-300 w-64 flex border border-neutral-light space-x-4"
            >
              <h3 className=" flex items-center">
                <span className="material-symbols-outlined text-5xl font-bold text-gray-700 ">{sale.icon}</span>

              </h3>
              <h2 className=' flex flex-col  '>
                <p className="mt-2 text-gray-700 text-2xl font-semibold">
                  <CountUp start={0} end={sale.value} duration={2.5} separator="," />
                </p>
                <span className="capitalize text-xs">{sale.cases}</span>
              </h2>
             
            </div>
          ))}
        </div> */}

        <div className="flex justify-between mb-8">
          <div className="bg-white shadow rounded-lg p-4 transform transition duration-300 w-64 flex border border-neutral-light space-x-4 group">
            <h3 className=" flex items-center">
              <span className="material-symbols-outlined text-5xl font-bold text-gray-700 ">
                login
              </span>
            </h3>
            <div className=" flex">
              <div className="flex flex-col">
                <div className="flex justify-between">
                  <p className="mt-2 text-gray-700 text-2xl font-semibold">
                    <CountUp
                      key={key1}
                      start={0}
                      end={loggedInUserCount}
                      duration={2.5}
                      separator=","
                    />
                  </p>

                </div>

                <span className="capitalize text-xs">
                  Logged In Dealers (FTD)
                </span>
              </div>
              <div>

                <button
                  className="flex items-center justify-center active:bg-neutral-lightest w-1 h-1 rounded-full opacity-0 group-hover:opacity-100 transition-opacity duration-300 absolute right-4"
                  onClick={fetchLoggedInUserCount}
                >
                  <span className="material-symbols-outlined font-bold text-neutral-darker hover:text-black text-sm ">
                    refresh
                  </span>
                </button>
              </div>

            </div>
            {/* Assuming sale.value is already formatted as currency */}
          </div>
          <div className="bg-white shadow rounded-lg p-4 transform transition duration-300 w-64 flex border border-neutral-light space-x-4 group">
            <h3 className=" flex items-center">
              <span className="material-symbols-outlined text-5xl font-bold text-gray-700 ">
                description
              </span>
            </h3>
            <div className=" flex">
              <div className="flex flex-col">
                <div className="flex justify-between">
                  <p className="mt-2 text-2xl font-semibold">
                    <CountUp
                      key={key2}
                      start={0}
                      end={totalSoldPolicyCountFTD}
                      duration={2.5}
                      separator=","
                    />
                  </p>
                </div>
                <span className="capitalize text-xs">Sold Policy (FTD)</span>
              </div>
            </div>
            <div>

              <button
                className="flex items-center justify-center active:bg-neutral-lightest w-1 h-1 rounded-full opacity-0 group-hover:opacity-100 transition-opacity duration-300 absolute right-4"
                onClick={() => fetchTotalSoldPolicyCount('ftd')}

              >
                <span className="material-symbols-outlined font-bold text-neutral-darker hover:text-black text-sm ">
                  refresh
                </span>
              </button>
            </div>
            {/* Assuming sale.value is already formatted as currency */}
          </div>
          <div className="bg-white shadow rounded-lg p-4 transform transition duration-300 w-64 flex border border-neutral-light space-x-4 group">
            <h3 className=" flex items-center">
              <span className="material-symbols-outlined text-5xl font-bold text-gray-700 ">
                dashboard
              </span>
            </h3>
            <div className="flex">
            <div className=" flex flex-col  ">
              <div className="flex justify-between">
              <p className="mt-2 text-gray-700 text-2xl font-semibold">
                <CountUp
                  key={key3}
                  start={0}
                  end={totalSoldPolicyCountMTD}
                  duration={2.5}
                  separator=","
                />
              </p>
              </div>
              <span className="capitalize text-xs">Sold Policy (MTD)</span>
            </div>
            </div>
            <div>

              <button
                className="flex items-center justify-center active:bg-neutral-lightest w-1 h-1 rounded-full opacity-0 group-hover:opacity-100 transition-opacity duration-300 absolute right-4"
                onClick={() => fetchTotalSoldPolicyCount('mtd')}

              >
                <span className="material-symbols-outlined font-bold text-neutral-darker hover:text-black text-sm ">
                  refresh
                </span>
              </button>
            </div>
            {/* Assuming sale.value is already formatted as currency */}
          </div>
          <div className="bg-white shadow rounded-lg p-4 transform transition duration-300 w-64 flex border border-neutral-light space-x-4 group">
            <h3 className=" flex items-center">
              <span className="material-symbols-outlined text-5xl font-bold text-gray-700 ">
                person
              </span>
            </h3>
            <div className="flex">
            <div className=" flex flex-col  ">
              <div className="flex justify-between">
              <p className="mt-2 text-gray-700 text-2xl font-semibold">
                <CountUp
                  key={key4}
                  start={0}
                  end={activeUserCount}
                  duration={2.5}
                  separator=","
                />
              </p>
              </div>
              <span className="capitalize text-xs">Active Users (MTD)</span>
            </div>
            <button
                className="flex items-center justify-center active:bg-neutral-lightest w-1 h-1 rounded-full opacity-0 group-hover:opacity-100 transition-opacity duration-300 absolute right-4"
                onClick={fetchActiveUsersCount}

              >
                <span className="material-symbols-outlined font-bold text-neutral-darker hover:text-black text-sm ">
                  refresh
                </span>
              </button>
            {/* Assuming sale.value is already formatted as currency */}
            </div>
          </div>
        </div>

        {/* Top Plans and Remaining Plans Section */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
          {/* Top Plans Card */}
          <div className="bg-white shadow rounded-lg p-6 transform transition duration-300 border border-neutral-light ">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-lg font-medium text-gray-700">
                Top 10 Dealers - {formatMonthYear(selectedMonth1)}{" "}
              </h2>
              {/* <button className='flex items-center justify-center active:bg-neutral-lightest w-8 h-8 rounded-full'><span className="material-symbols-outlined font-bold text-neutral-darker hover:text-black ">download</span></button> */}
              <button
                className="flex items-center justify-center active:bg-neutral-lightest w-8 h-8 rounded-full"
                onClick={() => setPayload1(initalPayload1)}
              >
                <span className="material-symbols-outlined font-bold text-neutral-darker hover:text-black ">
                  refresh
                </span>
              </button>
            </div>
            <div className="flex text-sm space-x-4 w-full justify-end">
              <div className="flex flex-col">
                <label for="zone" className="text-xs text-neutral-darker">
                  Zone
                </label>
                <select
                  name="zone"
                  id="zone"
                  className="w-28"
                  value={payload1.zoneId}
                  onChange={handleZoneChange1}
                >
                  {zoneOptions.map((zone) => (
                    <option key={zone.id} value={zone.id}>
                      {zone.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="flex flex-col">
                <label for="area" className="text-xs text-neutral-darker">
                  Area
                </label>
                <select
                  name="area"
                  id="area"
                  className="w-28"
                  value={payload1.areaId}
                  onChange={handleAreaChange1}
                  disabled={payload1.zoneId === 0}
                >
                  {areaOptions.map((area) => (
                    <option key={area.id} value={area.id}>
                      {area.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="flex flex-col">
                <label htmlFor="month" className="text-xs text-neutral-darker">
                  Month
                </label>
                <input
                  type="month"
                  id="month"
                  name="month"
                  className="w-28"
                  value={payload1.month}
                  onChange={handleMonthChange1}
                />
              </div>
            </div>
            <div className="flex flex-col">
              {topDealers.map((plan, index) => (
                <div className="flex items-center">
                  {plan.rank === 1 && (
                    <img
                      src={imageGold}
                      alt="gold-badge"
                      className="w-6 h-9 mr-2"
                    />
                  )}
                  {plan.rank === 2 && (
                    <img
                      src={imageSilver}
                      alt="silver-badge"
                      className="w-6 h-9 mr-2"
                    />
                  )}
                  {plan.rank === 3 && (
                    <img
                      src={imageBronze}
                      alt="bronze-badge"
                      className="w-6 h-9 mr-2"
                    />
                  )}
                  {plan.rank > 3 && (
                    <div className="mr-3">
                      <span className="pl-2 text-xs ">{plan.rank}.</span>
                    </div>
                  )}
                  <div
                    key={plan.id}
                    className={`px-4  rounded flex justify-between items-center w-full text-left ${plan.rank <= 3
                      ? "text-gray-700 font-bold py-1"
                      : "text-gray-700 mb-1"
                      } ${plan.rank === 1
                        ? "bg-primary-light text-gray-700"
                        : plan.rank === 2
                          ? "bg-primary-lighter text-gray-700"
                          : plan.rank === 3
                            ? "bg-primary-lightest text-gray-700"
                            : plan.rank === 3
                              ? "bg-neutral-lightest text-gray-700"
                              : "bg-neutral-lightest text-gray-700"
                      }`}
                  >
                    <div>
                      <span className="text-left text-xs">
                        {plan.dealerName}
                      </span>
                    </div>
                    <span className="ml-4 text-xs">
                      <CountUp
                        start={0}
                        end={plan.count}
                        duration={2.5}
                        separator=","
                      />
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="bg-white shadow rounded-lg p-6 transform transition duration-300 border border-neutral-light">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-lg font-medium text-gray-700 mb-4">
                Sales Target vs Achievement -{" "}
                {moment(selectedYear2).format("YYYY")}
              </h2>
              {/* <button className='flex items-center justify-center active:bg-neutral-lightest w-8 h-8 rounded-full'><span className="material-symbols-outlined font-bold text-neutral-darker hover:text-black ">download</span></button> */}
              <button
                className="flex items-center justify-center active:bg-neutral-lightest w-8 h-8 rounded-full"
                onClick={() => setPayload2(initalPayload2)}
              >
                <span className="material-symbols-outlined font-bold text-neutral-darker hover:text-black ">
                  refresh
                </span>
              </button>
            </div>
            <div className="flex text-sm space-x-4 w-full justify-end">
              <div className="flex flex-col">
                <label for="zone" className="text-xs text-neutral-darker">
                  Zone
                </label>
                <select
                  name="zone"
                  id="zone"
                  className="w-28"
                  value={payload2.zoneId}
                  onChange={handleZoneChange2}
                >
                  {zoneOptions.map((zone) => (
                    <option key={zone.id} value={zone.id}>
                      {zone.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="flex flex-col">
                <label for="area" className="text-xs text-neutral-darker">
                  Area
                </label>
                <select
                  name="area"
                  id="area"
                  className="w-28"
                  value={payload2.areaId}
                  onChange={handleAreaChange2}
                  disabled={payload2.zoneId === 0}
                >
                  {areaOptions.map((area) => (
                    <option key={area.id} value={area.id}>
                      {area.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="flex flex-col">
                <label htmlFor="month" className="text-xs text-neutral-darker">
                  Year
                </label>
                <select
                  name="year"
                  id="year"
                  value={payload2.year}
                  onChange={handleYearChange2}
                  className="w-28"
                >
                  {yearsOptions.map((year) => (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="h-72">
              <ResponsiveLine
                data={serviceTargetVsAchievement}
                margin={{ top: 30, right: 40, bottom: 42, left: 60 }}
                xScale={{ type: "point" }}
                yScale={{
                  type: "linear",
                  min: "auto",
                  max: "auto",
                  stacked: false,
                  reverse: false,
                }}
                curve="catmullRom"
                axisTop={null}
                axisRight={null}
                axisBottom={{
                  orient: "bottom",
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: "Month",
                  legendOffset: 36,
                  legendPosition: "middle",
                }}
                axisLeft={{
                  orient: "left",
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: "Value",
                  legendOffset: -40,
                  legendPosition: "middle",
                }}
                enableGridX={false}
                colors={({ id }) => (id === "Target" ? "#A5CFFF" : "#092E58")}
                pointSize={4}
                pointColor={{ theme: "background" }}
                pointBorderWidth={2}
                pointBorderColor={{ from: "serieColor" }}
                pointLabelYOffset={-12}
                useMesh={true}
                legends={[
                  {
                    anchor: "top-right",
                    direction: "column",
                    justify: false,
                    translateX: 40,
                    translateY: 80,
                    itemsSpacing: 2,
                    itemDirection: "left-to-right",
                    itemWidth: 100,
                    itemHeight: 20,
                    itemOpacity: 0.75,
                    symbolSize: 12,
                    symbolBorderColor: "rgba(0, 0, 0, .5)",
                    effects: [
                      {
                        on: "hover",
                        style: {
                          itemBackground: "rgba(0, 0, 0, .03)",
                          itemOpacity: 1,
                        },
                      },
                    ],
                  },
                ]}
              />
            </div>
          </div>
        </div>

        {/* Revenue Distribution and Bar Chart Section */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 pb-10">
          {/* Pie Chart */}
          <div className="bg-white shadow rounded-lg p-6 transform transition duration-300 border border-neutral-light">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-lg font-medium text-gray-700 mb-4">
                Model Distribution - {formatMonthYear(selectedMonth3)}
              </h2>
              {/* <button className='flex items-center justify-center active:bg-neutral-lightest w-8 h-8 rounded-full'><span className="material-symbols-outlined font-bold text-neutral-darker hover:text-black ">download</span></button> */}
              <button
                className="flex items-center justify-center active:bg-neutral-lightest w-8 h-8 rounded-full"
                onClick={() => setPayload3(initalPayload3)}
              >
                <span className="material-symbols-outlined font-bold text-neutral-darker hover:text-black ">
                  refresh
                </span>
              </button>
            </div>
            <div className="flex text-sm space-x-4 w-full justify-end">
              <div className="flex flex-col">
                <label for="zone" className="text-xs text-neutral-darker">
                  Zone
                </label>
                <select
                  name="zone"
                  id="zone"
                  className="w-28"
                  value={payload3.zoneId}
                  onChange={handleZoneChange3}
                >
                  {zoneOptions.map((zone) => (
                    <option key={zone.id} value={zone.id}>
                      {zone.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="flex flex-col">
                <label for="area" className="text-xs text-neutral-darker">
                  Area
                </label>
                <select
                  name="area"
                  id="area"
                  className="w-28"
                  value={payload3.areaId}
                  onChange={handleAreaChange3}
                  disabled={payload3.zoneId === 0}
                >
                  {areaOptions.map((area) => (
                    <option key={area.id} value={area.id}>
                      {area.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="flex flex-col">
                <label htmlFor="month" className="text-xs text-neutral-darker">
                  Month
                </label>
                <input
                  type="month"
                  id="month"
                  name="month"
                  className="w-28"
                  value={payload3.month}
                  onChange={handleMonthChange3}
                />
              </div>
            </div>
            <div className="h-60">
              <ResponsivePie
                data={pieData}
                margin={{ top: 30, right: 140, bottom: 10, left: 20 }}
                innerRadius={0.7}
                padAngle={0}
                cornerRadius={0}
                colors={chartColorSet}
                activeOuterRadiusOffset={8}
                borderWidth={1}
                borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
                radialLabelsSkipAngle={10}
                radialLabelsTextColor="#333333"
                radialLabelsLinkColor={{ from: "color" }}
                sliceLabelsSkipAngle={10}
                sliceLabelsTextColor="#333333"
                enableArcLabels={true}
                enableArcLinkLabels={false}
                arcLabelsTextColor={(obj) => getLabelTextColor(obj.color)}
                padding={{ bottom: 20 }}
                legends={[
                  {
                    anchor: "top-right",
                    direction: "column",
                    justify: false,
                    translateX: 100,
                    translateY: 10,
                    itemsSpacing: 2,
                    itemDirection: "left-to-right",
                    itemWidth: 100,
                    itemHeight: 20,
                    itemOpacity: 0.75,
                    symbolSize: 12,
                    symbolBorderColor: "rgba(0, 0, 0, .5)",
                    effects: [
                      {
                        on: "hover",
                        style: {
                          itemBackground: "rgba(0, 0, 0, .03)",
                          itemOpacity: 1,
                        },
                      },
                    ],
                  },
                ]}
              />
            </div>
          </div>

          <div className="bg-white shadow rounded-lg p-6  transform transition duration-300 border border-neutral-light">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-lg font-medium text-gray-700 mb-2">
                Channel Based Volume - {formatMonthYear(selectedMonth4)}
              </h2>
              {/* <button className='flex items-center justify-center active:bg-neutral-lightest w-8 h-8 rounded-full'><span className="material-symbols-outlined font-bold text-neutral-darker hover:text-black ">download</span></button> */}
              <button
                className="flex items-center justify-center active:bg-neutral-lightest w-8 h-8 rounded-full"
                onClick={() => setPayload4(initalPayload4)}
              >
                <span className="material-symbols-outlined font-bold text-neutral-darker hover:text-black ">
                  refresh
                </span>
              </button>
            </div>
            <div className="flex text-sm space-x-4 w-full justify-end">
              <div className="flex flex-col">
                <label for="zone" className="text-xs text-neutral-darker">
                  Zone
                </label>
                <select
                  name="zone"
                  id="zone"
                  className="w-28"
                  value={payload4.zoneId}
                  onChange={handleZoneChange4}
                >
                  {zoneOptions.map((zone) => (
                    <option key={zone.id} value={zone.id}>
                      {zone.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="flex flex-col">
                <label for="area" className="text-xs text-neutral-darker">
                  Area
                </label>
                <select
                  name="area"
                  id="area"
                  className="w-28"
                  value={payload4.areaId}
                  onChange={handleAreaChange4}
                  disabled={payload4.zoneId === 0}
                >
                  {areaOptions.map((area) => (
                    <option key={area.id} value={area.id}>
                      {area.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="flex flex-col">
                <label htmlFor="month" className="text-xs text-neutral-darker">
                  Month
                </label>
                <input
                  type="month"
                  id="month"
                  name="month"
                  className="w-28"
                  value={payload4.month}
                  onChange={handleMonthChange4}
                />
              </div>
            </div>
            <div className="h-60">
              <ResponsiveBar
                data={channelBasedVolumeReport}
                keys={[
                  "Inclusion",
                  "Sales",
                  "Services",
                  "Website",
                  "QR Code",
                  "Mobile App",
                ]}
                indexBy="type"
                margin={{ top: 0, right: 100, bottom: 10, left: 60 }} // Increased bottom margin to 70
                groupMode="grouped"
                colors={chartColorSet} // Yellow for customers and Sky blue for conversion rate
                axisTop={null}
                axisRight={null}
                enableLabel={true}
                labelTextColor={(obj) => getLabelTextColor(obj.color)}
                padding={0.2}
                // axisBottom={{
                //   orient: "bottom",
                //   tickSize: 5,
                //   tickPadding: 5,
                //   tickRotation: 0,
                //   // legend: 'Sales',
                //   legendPosition: "middle",
                //   legendOffset: 50, // Increased offset to 50 for more padding
                // }}
                axisLeft={{
                  orient: "left",
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: "Count",
                  legendPosition: "middle",
                  legendOffset: -50,
                }}
                labelSkipWidth={12}
                labelSkipHeight={12}
                legends={[
                  {
                    anchor: "top-right",
                    direction: "column",
                    justify: false,
                    translateX: 120,
                    translateY: 80,
                    itemsSpacing: 2,
                    itemDirection: "left-to-right",
                    itemWidth: 100,
                    itemHeight: 20,
                    itemOpacity: 0.75,
                    symbolSize: 12,
                    symbolBorderColor: "rgba(0, 0, 0, .5)",
                    effects: [
                      {
                        on: "hover",
                        style: {
                          itemBackground: "rgba(0, 0, 0, .03)",
                          itemOpacity: 1,
                        },
                      },
                    ],
                  },
                ]}
              />
            </div>
          </div>
        </div>

        {/* Channel based Volume */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 pb-10">
          {/* Updated Bar Chart */}
        </div>
      </div>
    </div>
  );
};

export default ServiceDashboard;
