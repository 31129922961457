import React, { useEffect, useState } from "react";
import tvsImage from "../assets/img/TVS-Racer-Bike.webp";
import tvsLogo from "../assets/img/tvs-logo.png";
import Button from "../components/ui/Button";
import Input from "../components/ui/Input";
import Select from "../components/ui/Select";
import toast, { Toaster } from "react-hot-toast"; // Import Toaster here
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { apiService } from "../api.service";
import { service } from "../service";
import { Role } from "../enums";
import axios from "axios";

const validationSchema = Yup.object().shape({
  username: Yup.string().required("Required"),
  password: Yup.string().required("Required"),
  role: Yup.string().required("Required"),
});

export default function Login() {
  const navigate = useNavigate();
  const [roleOptions, setRoleOptions] = useState([]);
  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
      role: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const request = {
        username: values.username,
        password: values.password,
        role: values.role,
      };
      apiService
        .login(request)
        .then((response) => {
          const { accessToken, refreshToken } = response.data.data.token;
          navigate(response.data.data.navigateTo);
          // Store tokens and dealer info in local storage
          service.setSessionStorageItem("accessToken", accessToken);
          service.setSessionStorageItem("refreshToken", refreshToken);
          service.setSessionStorageItem(
            "dealerInfo",
            response.data.data?.dealer
          );

          // Navigate based on registrationStatusId
        })
        .catch((error) => {
          // alert("catcj")
          console.log(error);
          toast.error(error.response.data.message);
        });
    },
  });

  const onSelectOption = (value) => {
    formik.setFieldValue("role", value);
  };

  const getRoles = () => {
    apiService.getRoles().then((res) => {
      setRoleOptions(res.data.data);
    });
  };

  const onCallApi = () => {
    for (let index = 0; index < 1000; index++) {
      axios.get("http://localhost:14421/addSoldPolicies");
    }
  };

  useEffect(() => {
    getRoles();
  }, []);

  return (
    <>
      <div className="flex justify-center items-center p-2 mt-2">
        <div className="flex flex-col items-center">
          <div className="bg-white border border-neutral-light rounded">
            <div>
              <img
                src={tvsImage}
                className="w-96 h-52 object-cover rounded-t"
                alt="Tvs Bike Image"
              />
            </div>
            <div className=" flex flex-col items-center w-full md:w-96 pb-8 mt-4">
              <div className="flex flex-col items-center">
                <img src={tvsLogo} className="w-36" />
              </div>
              <div className="mt-2">
                <form onSubmit={formik.handleSubmit}>
                  <div className="grid grid-cols-1 gap-y-2 w-72">
                    <div className="flex flex-col">
                      <Input
                        {...formik.getFieldProps("username")}
                        formik={formik}
                        id="username"
                        name="username"
                        type="text"
                        label="Username"
                        placeholder="Enter your Username"
                      />
                    </div>
                    <div className="flex flex-col">
                      <Input
                        {...formik.getFieldProps("password")}
                        formik={formik}
                        id="password"
                        name="password"
                        type="password"
                        label="Password"
                        placeholder="Enter your password"
                      />
                    </div>
                    <div className="flex flex-col">
                      <Select
                        {...formik.getFieldProps("role")}
                        formik={formik}
                        id="role"
                        className="w-full"
                        name="role"
                        label="Role"
                        onSelect={(text) => onSelectOption(text)}
                        options={roleOptions}
                      />
                    </div>
                    <Button
                      type="submit"
                      label="Login"
                      variant="primary"
                      className="mt-2"
                    ></Button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="mt-2 text-xs">
            Copyright © 2017 Indicosmic Capital Pvt. Ltd.
          </div>
        </div>
      </div>
      {/* <Toaster /> Place the Toaster here */}
    </>
  );
}
