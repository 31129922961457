import React, { useState, useEffect } from "react";
import toast, { Toaster } from "react-hot-toast";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { useNavigate } from "react-router-dom";
import FileSaver from "file-saver";
import Tippy from "@tippyjs/react";
import {
  Description,
  Dialog,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";
import moment from "moment";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
  SortingState,
  getSortedRowModel,
  getPaginationRowModel,
} from "@tanstack/react-table";
import coverImage from "../../assets/img/coverImage.png";
import Input from "../../components/ui/Input";
import Button from "../../components/ui/Button";
import Select from "../../components/ui/Select";
import File from "../../components/ui/File";
import { apiService } from "../../api.service";

export const Pincode = () => {
  const columnHelper = createColumnHelper();

  const [pincodeData, setPincodeData] = useState([]);
  const [noRecordFound, setNoRecordFound] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [showPincodeDialog, setShowPincodeDialog] = useState(false);
  const [stateOptions, setStateOptions] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);

  const [payload, setPayload] = useState({
    pagination: {
      pageNumber: 1,
      pageSize: 5,
    },
  });

  const pincodeForm = useFormik({
    initialValues: {
      stateId: "",
      cityId: "",
      pincode: "",
    },
    validationSchema: Yup.object().shape({
      stateId: Yup.number().required("Required"),
      cityId: Yup.number().required("Required"),
      pincode: Yup.number().required("Required"),
    }),
    onSubmit: async (values) => {
      console.log(values);
      const payload = {
        stateId: values.stateId,
        cityId: values.cityId,
        pincode: values.pincode,
      };

      await apiService
        .addPincode(payload)
        .then((res) => {
          toast.success(res.data.message);
        })
        .catch((error) => {
          toast.error(error.response.data.message);
        })
        .finally(() => {
          pincodeForm.resetForm();
          setShowPincodeDialog(false);
        });
    },
  });

  const pincodeColumns = [
    columnHelper.accessor("srNo", {
      header: "#",
    }),
    columnHelper.accessor("state", {
      header: "State",
    }),
    columnHelper.accessor("city", {
      header: "City",
    }),
    // columnHelper.accessor("district", {
    //   header: "District",
    // }),
    // columnHelper.accessor("area", {
    //   header: "Area",
    // }),
    columnHelper.accessor("pincode", {
      header: "Pincode",
    }),
  ];

  const pincodeTable = useReactTable({
    data: pincodeData,
    columns: pincodeColumns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    manualPagination: true,
  });

  const onSelectState = async (stateId) => {
    pincodeForm.setFieldValue("stateId", stateId);
    await fetchCity(stateId);
  };

  const totalPages = Math.ceil(totalCount / payload.pagination.pageSize);
  const isLastPage = payload.pagination.pageNumber === totalPages;
  const isFirstPage = payload.pagination.pageNumber === 1;

  const handleFirstPage = () => {
    setPayload((prev) => ({
      ...prev,
      pagination: {
        ...prev.pagination,
        pageNumber: 1,
      },
    }));
  };

  const handlePreviousPage = () => {
    setPayload((prev) => {
      const prevPage = prev.pagination.pageNumber - 1;
      return {
        ...prev,
        pagination: {
          ...prev.pagination,
          pageNumber: prevPage < 1 ? 1 : prevPage,
        },
      };
    });
  };

  const handleNextPage = () => {
    setPayload((prev) => {
      const nextPage = prev.pagination.pageNumber + 1;
      return {
        ...prev,
        pagination: {
          ...prev.pagination,
          pageNumber: nextPage > totalPages ? totalPages : nextPage,
        },
      };
    });
  };

  const handleLastPage = () => {
    setPayload((prev) => ({
      ...prev,
      pagination: {
        ...prev.pagination,
        pageNumber: totalPages,
      },
    }));
  };

  const fetchPincode = async () => {
    await apiService
      .fetchPincode(payload)
      .then((res) => {
        setPincodeData(res.data.data);
        setTotalCount(res.data.totalCount);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  const fetchState = async () => {
    await apiService
      .fetchState()
      .then((res) => {
        setStateOptions(res.data.data);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  const fetchCity = async (stateId) => {
    await apiService
      .fetchCity(stateId)
      .then((res) => {
        setCityOptions(res.data.data);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  useEffect(() => {
    fetchPincode();
    fetchState();
  }, [payload]);

  return (
    <>
      <div className="flex flex-col w-full items-center overflow-hidden">
        <div className="sticky -z-10 w-full">
          <img
            src={coverImage}
            className=" w-full h-36 object-cover"
            alt="cover_image"
          />
        </div>
        <div className="w-11/12 bg-white -mt-28 md:-mt-20 border border-neutral-light rounded mx-2">
          <form>
            <div>
              <div className="bg-base-white px-6 py-4 border-b border-neutral-light rounded-t">
                <h3 className="text-xl font-semibold">Pincode Master</h3>
                <p className="text-neutral-darker mt-1 text-sm">
                  A brief snapshot of your financial activity, showing incoming
                  and outgoing transactions
                </p>
              </div>
              <div className="px-6 py-4 pb-8">
                <div className="flex justify-between flex-col md:flex-row items-center">
                  <div>
                    <button
                      type="button"
                      onClick={() => setShowPincodeDialog(true)}
                      className="flex items-center bg-primary text-white active:bg-primary-darkest text-sm px-3 py-1 rounded-sm mb-2 md:mb-0"
                    >
                      <span className="material-symbols-outlined flex items-center justify-center">
                        add
                      </span>
                      Add Pincode
                    </button>
                  </div>
                  <div className="flex items-center">
                    <select
                      id="searchField"
                      className="mr-2 text-neutral-darkest text-sm"
                    >
                      <option value="state_name">State</option>
                      {/* <option value="district_name">District</option> */}
                      <option value="city_or_village_name">City</option>
                      {/* <option value="area_name">Area</option> */}
                      <option value="pin_code">Pincode</option>
                    </select>
                    <input
                      type="text"
                      id="search"
                      placeholder="Search..."
                      className="px-3 py-1 border border-neutral-dark rounded-l-sm focus:outline-primary text-base-black text-sm w-full md:w-60"
                    />
                    <button
                      type="button"
                      onClick={() => {
                        setPayload((prev) => ({
                          ...prev,
                          search: {
                            field: document.getElementById("searchField").value,
                            value: document.getElementById("search").value,
                          },
                        }));
                      }}
                      className="bg-primary text-white border border-primary px-2 py-0.5 rounded-r-sm"
                    >
                      <span className="material-symbols-outlined flex items-center justify-center">
                        search
                      </span>
                    </button>
                  </div>
                </div>



                {pincodeData.length > 0 && (
                  <>
                    <div className="flex mb-4 overflow-x-scroll cursor-pointer mt-4">
                      <table
                        id="table-to-xls"
                        className="text-sm border border-neutral w-full"
                      >
                        <thead className="bg-primary text-white text-xs">
                          {pincodeTable.getHeaderGroups().map((headerGroup) => (
                            <tr key={headerGroup.id}>
                              {headerGroup.headers.map((header) => {
                                return (
                                  <th
                                    key={header.id}
                                    colSpan={header.colSpan}
                                    className="px-2 py-1 text-left"
                                  >
                                    {header.isPlaceholder ? null : (
                                      <div>
                                        {flexRender(
                                          header.column.columnDef.header,
                                          header.getContext()
                                        )}
                                      </div>
                                    )}
                                  </th>
                                );
                              })}
                            </tr>
                          ))}
                        </thead>

                        <tbody>
                          {noRecordFound ? (
                            <tr className="min-h-20">
                              <td
                                colSpan={pincodeColumns.length}
                                className="text-center p-4 min-h-20"
                              >
                                No records found
                              </td>
                            </tr>
                          ) : (
                            pincodeTable.getRowModel().rows.map((row) => (
                              <tr
                                key={row.id}
                                className="text-xs even:bg-primary-lightest"
                              >
                                {row.getVisibleCells().map((cell) => (
                                  <td key={cell.id} className="px-2 py-1">
                                    {flexRender(
                                      cell.column.columnDef.cell,
                                      cell.getContext()
                                    )}
                                  </td>
                                ))}
                              </tr>
                            ))
                          )}
                        </tbody>
                      </table>
                    </div>
                    <div className="flex flex-col md:flex-row items-center w-full justify-end text-xs gap-4">
                      <div className="flex items-center gap-2">
                        {" "}
                        <select
                          value={payload.pageSize}
                          onChange={(e) => {
                            const size = Number(e.target.value);
                            setPayload((prev) => ({
                              ...prev,
                              pagination: {
                                pageSize: size,
                                pageNumber: 1,
                              },
                            }));
                          }}
                        >
                          {[5, 10, 15, 20, 25, 30, 35, 40, 45, 50].map((pageSize) => (
                            <option key={pageSize} value={pageSize}>
                              {pageSize}
                            </option>
                          ))}
                        </select>
                        <span className="font-semibold">
                          <span>Showing </span>
                          {1 +
                            (payload.pagination.pageNumber - 1) *
                            payload.pagination.pageSize}{" "}
                          to{" "}
                          {payload.pagination.pageNumber * payload.pagination.pageSize -
                            payload.pagination.pageSize +
                            pincodeData?.length}{" "}
                          of {totalCount} Entries
                        </span>
                      </div>
                      <div className="flex items-center space-x-2 mt-2 md:mt-0">
                        <button
                          type="button"
                          disabled={isFirstPage}
                          onClick={handleFirstPage}
                          className={`material-symbols-outlined ${isFirstPage
                            ? "cursor-not-allowed text-neutral-light"
                            : "cursor-pointer"
                            }`}
                        >
                          first_page
                        </button>
                        <button
                          type="button"
                          onClick={handlePreviousPage}
                          disabled={isFirstPage}
                          className={`material-symbols-outlined ${isFirstPage
                            ? "cursor-not-allowed text-neutral-light"
                            : "cursor-pointer"
                            }`}
                        >
                          chevron_left
                        </button>
                        <button
                          type="button"
                          onClick={handleNextPage}
                          disabled={isLastPage}
                          className={`material-symbols-outlined ${isLastPage
                            ? "cursor-not-allowed text-neutral-light"
                            : "cursor-pointer"
                            }`}
                        >
                          chevron_right
                        </button>
                        <button
                          type="button"
                          className={`material-symbols-outlined ${isLastPage
                            ? "cursor-not-allowed text-neutral-light"
                            : "cursor-pointer"
                            }`}
                          onClick={handleLastPage}
                          disabled={isLastPage}
                        >
                          last_page
                        </button>
                      </div>
                    </div>
                  </>
                )}


              </div>
            </div>
          </form>
        </div>
      </div>

      <Dialog
        open={showPincodeDialog}
        onClose={() => setShowPincodeDialog(false)}
        className="relative z-50"
      >
        <div className="fixed inset-0 bg-black/75" aria-hidden="true" />

        <div className="fixed inset-0 flex items-center justify-center p-4">
          <DialogPanel className="space-y-4 border bg-white border-neutral rounded">
            <form onSubmit={pincodeForm.handleSubmit}>
              <div className="bg-neutral-lightest border-b border-neutral px-6 py-3 flex">
                <span className="font-bold text-xl py-2 text-neutral-darkest">
                  Add Pincode
                </span>
              </div>
              <div className="flex flex-col gap-4 p-4">
                <div className="md:flex md:gap-x-4 space-y-4 md:space-y-0">
                  <Select
                    {...pincodeForm.getFieldProps("stateId")}
                    formik={pincodeForm}
                    value={pincodeForm.values.stateId}
                    id="state"
                    name="state"
                    label="State"
                    onSelect={(id) => onSelectState(id)}
                    options={stateOptions}
                    className="md:mr-4"
                  />
                  <Select
                    {...pincodeForm.getFieldProps("cityId")}
                    formik={pincodeForm}
                    value={pincodeForm.values.cityId}
                    id="city"
                    name="city"
                    label="City"
                    onSelect={(id) => pincodeForm.setFieldValue("cityId", id)}
                    options={cityOptions}
                    className="md:mr-4"
                  />
                  <Input
                    {...pincodeForm.getFieldProps("pincode")}
                    formik={pincodeForm}
                    id="pincode"
                    name="pincode"
                    type="text"
                    maxLength="6"
                    label="Pincode"
                    placeholder="Pincode"
                    className="w-full"
                  />
                </div>
                <div className="flex items-center justify-center gap-4 mt-4">
                  <Button
                    type="button"
                    label="Cancel"
                    variant="ghost"
                    className="max-w-12"
                    onClick={() => setShowPincodeDialog(false)}
                  />
                  <Button
                    type="submit"
                    label="Submit"
                    variant="primary"
                    className="max-w-16"
                  />
                </div>
              </div>
            </form>
          </DialogPanel>
        </div>
      </Dialog>


    </>
  );
};
