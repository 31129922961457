import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const Input = ({
  id,
  name,
  type,
  value,
  formik,
  label,
  placeholder,
  className,
  onInputChange,
  min,
  max,
  readOnly,
  onFocus,
  maxLength
}) => {
  const handleChange = (event) => {
    if (onInputChange) {
      onInputChange(event);
    } else {
      formik.handleChange(event);
    }
  };

  const classes = classNames(
    "px-3 py-1 placeholder-neutral-dark border border-neutral-dark rounded-sm min-w-60 focus:outline-none focus:border focus:border-primary",
    className,
    
    );
  return (
    <div className="flex flex-col">
      <label htmlFor={id} className="w-60 text-sm text-neutral-darker mb-0.5">
        {label}
      </label>
      <input
        id={id}
        name={name}
        className={classes}
        type={type}
        min={min}
        max={max}
        value={formik.values[name]}
        placeholder={placeholder || "Type Here..."}
        onChange={handleChange}
        onFocus={onFocus}
        onBlur={formik.handleBlur}
        readOnly={readOnly ? true : false}
        maxLength={maxLength}
      />
      <div className="text-danger text-xs mt-1">
        {formik.touched[name] && formik.errors[name] ? (
          <div>{formik.errors[name]}</div>
        ) : null}
      </div>
    </div>
  );
};

Input.propTypes = {
  id: PropTypes.string,
  type: PropTypes.oneOf(["text", "password", "email", "date"]).isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  onInputChange: PropTypes.func,
};

Input.defaultProps = {
  type: "text",
  placeholder: "Type something...",
};

export default Input;
