import { ResponsiveBar } from "@nivo/bar";
import { ResponsivePie } from "@nivo/pie";
import { ResponsiveLine } from "@nivo/line";
import React, { useState, useEffect } from "react";
import toast from "react-hot-toast";
import { apiService } from "../../../api.service";
import chroma from "chroma-js";
import moment from "moment";
// import { Progress } from "@/components/ui/progress"

import { async } from "q";
export const SalesDashboard = () => {
  const initalPayload1 = {
    zoneId: 0,
    areaId: 0,
    month: moment().format("YYYY-MM"),
  };
  const initalPayload2 = {
    zoneId: 0,
    areaId: 0,
    month: moment().format("YYYY-MM"),
  };
  const initalPayload3 = {
    zoneId: 0,
    areaId: 0,
    month: moment().format("YYYY-MM"),
  };
  const initalPayload4 = {
    zoneId: 0,
    areaId: 0,
    month: moment().format("YYYY-MM"),
  };
  const initalPayload5 = {
    zoneId: 0,
    areaId: 0,
    month: moment().format("YYYY-MM"),
  };
  const initalPayload6 = {
    zoneId: 0,
    areaId: 0,
    month: moment().format("YYYY-MM"),
  };
  const [chartData1, setChartData1] = useState([]);
  const [zoneOptions, setZoneOptions] = useState([]);
  const [selectedZone, setSelectedZone] = useState("");
  const [selectedMonth1, setSelectedMonth1] = useState("");
  const [selectedMonth2, setSelectedMonth2] = useState("");
  const [selectedMonth3, setSelectedMonth3] = useState("");
  const [selectedMonth4, setSelectedMonth4] = useState("");
  const [selectedMonth5, setSelectedMonth5] = useState("");
  const [selectedMonth6, setSelectedMonth6] = useState("");
  const [areaOptions, setAreaOptions] = useState([]);
  const [selectedArea, setSelectedArea] = useState("");
  const [payload1, setPayload1] = useState(initalPayload1);
  const [payload2, setPayload2] = useState(initalPayload2);
  const [payload3, setPayload3] = useState(initalPayload3);
  const [payload4, setPayload4] = useState(initalPayload4);
  const [payload5, setPayload5] = useState(initalPayload5);
  const [payload6, setPayload6] = useState(initalPayload6);
  const [prevPayload1, setPrevPayload1] = useState(initalPayload1);
  const [prevPayload2, setPrevPayload2] = useState(initalPayload2);
  const [prevPayload3, setPrevPayload3] = useState(initalPayload2);
  const [prevPayload4, setPrevPayload4] = useState(initalPayload2);
  const [prevPayload5, setPrevPayload5] = useState(initalPayload2);
  const [prevPayload6, setPrevPayload6] = useState(initalPayload2);
  const formatMonthYear = (month) => {
    return moment(month, "YYYY-MM").format("MMM YYYY"); // Format: "Jul 2024"
  };
  // const [payload2, setPayload2] = useState({
  //   zoneId: 0,
  //   areaId: 0,
  //   month: moment().format("YYYY-MM"),
  // });

  const [zoneGroup, setZoneGroup] = useState({
    areaId: 0,
  });
  const getLabelTextColor = (bgColor) => {
    console.log(bgColor, chroma.contrast(bgColor, "white"));
    const textColor =
      chroma.contrast(bgColor, "white") > 4.5 ? "white" : "black";
    console.log(textColor);
    return textColor;
  };
  const chartColorSet = [
    "#092E58", // default
    "#70acf0", // light
    "#FF5733", // Vibrant Orange
    "#6B5B95", // Ultra Violet
    "#88B04B", // Greenery
    "#F7CAC9", // Rose Quartz
    "#92A8D1", // Serenity
    "#955251", // Marsala
    "#009473", // Emerald
    "#DD4124", // Tangerine Tango
    "#D94F70", // Honeysuckle
    "#45B8AC", // Turquoise
    "#EFC050", // Mimosa
    "#9B2335", // Chilli Pepper
    "#5B5EA6", // Royal Blue
    "#DFCFBE", // Sand Dollar
    "#55B4B0", // Biscay Bay
    "#E15D44", // Burnt Orange
    "#7FCDCD", // Aquamarine
    "#BC243C", // Scarlet
    "#C3447A", // Raspberry Radiance
    "#98B4D4", // Pastel Blue
  ];
  const getArcLabelColor = (dataArray, label) => {
    const whiteLabels = dataArray.map((item) => item.labelColor == "white");
    console.log(whiteLabels);
    // Customize the color based on the label value
  };

  const channelBasedVolumeData1 = [
    {
      type: "Total",
      "Total Target": 2874,
      "MTD Target": 1916,
      "Total Achieved": 3751,
    },
    {
      type: "AMD",
      "Total Target": 2600,
      "MTD Target": 1733,
      "Total Achieved": 3436,
    },
    {
      type: "AD",
      "Total Target": 274,
      "MTD Target": 183,
      "Total Achieved": 315,
    },
  ];

  const [RSAData, setRSAData] = useState([]);
  const achievementData = [{ type: "target", ad: 172, amd: 198 }];

  const achievementData1 = [
    {
      id: "AD",
      label: "AD",
      value: 172,
    },
    {
      id: "AMD",
      label: "AMD",
      value: 198,
    },
  ];

  const [planWiseData, setplanWiseData] = useState([]);

  const [renewedPoliciesCountData, setRenewedPoliciesCountData] = useState({});

  const [targetVsAchievementData, settargetVsAchievementData] = useState([]);

  const [dayWiseData, setDayWiseData] = useState([]);

  const [dayWiseTargetvsAchivedData, setdayWiseTargetvsAchivedData] = useState(
    []
  );
  const dealerTypeWiseTargetVsAchievementData = async () => {
    await apiService
      .dealerTypeWiseTargetVsAchievementReport(payload1)
      .then((res) => {
        console.log(res.data.data);
        setChartData1(res.data.data);
        setSelectedMonth1(payload1.month);
        setPrevPayload1(payload1);
      })
      .catch((error) => {
        console.error(error);
        setPayload1(prevPayload1);
        toast.error(error.response.data.message);
      });
  };
  const planWiseSalesReport = async () => {
    await apiService
      .planWiseSalesReport(payload2)
      .then((res) => {
        console.log(res.data.data);
        setplanWiseData(res.data.data);
        setSelectedMonth2(payload2.month);
        setPrevPayload2(payload2);
      })
      .catch((error) => {
        console.error(error);
        setPayload2(prevPayload2);
        toast.error(error.response.data.message);
      });
  };

  const categoryWiseDailyReport = async () => {
    await apiService
      .categoryWiseDailyReport(payload3)
      .then((res) => {
        console.log(res.data.data);
        settargetVsAchievementData(res.data.data);
        setSelectedMonth3(payload3.month);
        setPrevPayload3(payload3);
      })
      .catch((error) => {
        console.error(error);
        setPayload3(prevPayload3);
        toast.error(error.response.data.message);
      });
  };
  const planWiseDailyReport = async () => {
    await apiService
      .planWiseDailyReport(payload4)
      .then((res) => {
        console.log(res.data.data);
        setDayWiseData(res.data.data);
        setSelectedMonth4(payload4.month);
        setPrevPayload4(payload4);
      })
      .catch((error) => {
        console.error(error);
        setPayload4(prevPayload4);
        toast.error(error.response.data.message);
      });
  };
  const generateKeys = () => {
    if (dayWiseData.length === 0) return [];
    // Get the keys from the first object in dayWiseData (excluding 'day')
    return Object.keys(dayWiseData[0]).filter((key) => key !== "day");
  };

  const dailyTargetVsAchievementReport = async () => {
    await apiService
      .dailyTargetVsAchievementReport(payload5)
      .then((res) => {
        console.log(res.data.data);
        setdayWiseTargetvsAchivedData(res.data.data);
        setSelectedMonth5(payload5.month);
        setPrevPayload5(payload5);
      })
      .catch((error) => {
        console.error(error);
        setPayload5(prevPayload5);
        toast.error(error.response.data.message);
      });
  };
  const dealerTypeWiseRSAStatsReport = async () => {
    await apiService
      .dealerTypeWiseRSAStatsReport(payload6)
      .then((res) => {
        console.log(res.data.data);
        setRSAData(res.data.data);
        setSelectedMonth6(payload6.month);
        setPrevPayload6(payload6);
      })
      .catch((error) => {
        console.error(error);
        setPayload6(prevPayload6);
        toast.error(error.response.data.message);
      });
  };
  const fetchZoneOptions = async () => {
    await apiService
      .zoneOptions()
      .then((res) => {
        console.log(res.data.data);
        setZoneOptions(res.data.data);
        if (res.data.data.length > 0) {
          setSelectedZone(res.data.data[0].id);
        }
      })
      .catch((error) => {
        console.error(error);
        toast.error(error.response.data.message);
      });
  };

  const fetchrenewedPoliciesCount = async () => {
    await apiService.renewedPoliciesCount(payload2).then((res) => {
      console.log(res.data.data);
      setRenewedPoliciesCountData(res.data.data);
      setSelectedMonth2(payload2.month);
      setPrevPayload2(payload2);
    }).catch((error) => {
      console.error(error);
      setPayload2(prevPayload2);
      toast.error(error.response.data.message);
    });
  }

  const handleZoneChange = async (event) => { };

  const handleZoneChange1 = async (event) => {
    const selectedZoneId = parseInt(event.target.value);
    setPayload1((prev) => ({
      ...prev,
      zoneId: selectedZoneId,
    }));

    if (selectedZoneId === 0) {
      // Zone "ALL" selected
      setAreaOptions([{ id: 0, name: "ALL", label: "ALL" }]);
      setSelectedArea(0);
    } else {
      // Specific zone selected, fetch corresponding areas
      try {
        await fetchAreaOptions(selectedZoneId);
      } catch (error) {
        console.error(error);
        toast.error(error.response.data.message);
      }
    }
  };

  const handleZoneChange2 = async (event) => {
    const selectedZoneId = parseInt(event.target.value);
    setPayload2((prev) => ({
      ...prev,
      zoneId: selectedZoneId,
    }));
    // const payload = {
    //   zoneId: payload2.zoneId
    // }
    // await apiService.areaOptions(payload2).then(res => {
    //   setAreaOptions(res.data.data);
    // })
    if (selectedZoneId === 0) {
      // Zone "ALL" selected
      setAreaOptions([{ id: 0, name: "ALL", label: "ALL" }]);
      setSelectedArea(0);
    } else {
      // Specific zone selected, fetch corresponding areas
      try {
        await fetchAreaOptions(selectedZoneId);
      } catch (error) {
        console.error(error);
        toast.error(error.response.data.message);
      }
    }
  };
  const handleZoneChange3 = async (event) => {
    const selectedZoneId = parseInt(event.target.value);
    setPayload3((prev) => ({
      ...prev,
      zoneId: selectedZoneId,
    }));
    // const payload = {
    //   zoneId: payload3.zoneId
    // }
    // await apiService.areaOptions(payload3).then(res => {
    //   setAreaOptions(res.data.data);
    // })
    if (selectedZoneId === 0) {
      // Zone "ALL" selected
      setAreaOptions([{ id: 0, name: "ALL", label: "ALL" }]);
      setSelectedArea(0);
    } else {
      // Specific zone selected, fetch corresponding areas
      try {
        await fetchAreaOptions(selectedZoneId);
      } catch (error) {
        console.error(error);
        toast.error(error.response.data.message);
      }
    }
  };
  const handleZoneChange4 = async (event) => {
    const selectedZoneId = parseInt(event.target.value);
    setPayload4((prev) => ({
      ...prev,
      zoneId: selectedZoneId,
    }));
    // const payload = {
    //   zoneId: payload4.zoneId
    // }
    // await apiService.areaOptions(payload4).then(res => {
    //   setAreaOptions(res.data.data);
    // })
    if (selectedZoneId === 0) {
      // Zone "ALL" selected
      setAreaOptions([{ id: 0, name: "ALL", label: "ALL" }]);
      setSelectedArea(0);
    } else {
      // Specific zone selected, fetch corresponding areas
      try {
        await fetchAreaOptions(selectedZoneId);
      } catch (error) {
        console.error(error);
        toast.error(error.response.data.message);
      }
    }
  };
  const handleZoneChange5 = async (event) => {
    const selectedZoneId = parseInt(event.target.value);
    setPayload5((prev) => ({
      ...prev,
      zoneId: parseInt(event.target.value),
    }));
    // const payload = {
    //   zoneId: payload5.zoneId
    // }
    // await apiService.areaOptions(payload5).then(res => {
    //   setAreaOptions(res.data.data);
    // })
    if (selectedZoneId === 0) {
      // Zone "ALL" selected
      setAreaOptions([{ id: 0, name: "ALL", label: "ALL" }]);
      setSelectedArea(0);
    } else {
      // Specific zone selected, fetch corresponding areas
      try {
        await fetchAreaOptions(selectedZoneId);
      } catch (error) {
        console.error(error);
        toast.error(error.response.data.message);
      }
    }
  };
  const handleZoneChange6 = async (event) => {
    const selectedZoneId = parseInt(event.target.value);
    setPayload6((prev) => ({
      ...prev,
      zoneId: selectedZoneId,
    }));
    // const payload = {
    //   zoneId: payload6.zoneId
    // }
    // await apiService.areaOptions(payload6).then(res => {
    //   setAreaOptions(res.data.data);
    // })
    if (selectedZoneId === 0) {
      // Zone "ALL" selected
      setAreaOptions([{ id: 0, name: "ALL", label: "ALL" }]);
      setSelectedArea(0);
    } else {
      // Specific zone selected, fetch corresponding areas
      try {
        await fetchAreaOptions(selectedZoneId);
      } catch (error) {
        console.error(error);
        toast.error(error.response.data.message);
      }
    }
  };

  const fetchAreaOptions = async (zoneId) => {
    try {
      const payload = {
        zoneId: zoneId,
      };
      const res = await apiService.areaOptions(payload);
      console.log(res.data.data);
      setAreaOptions(res.data.data);
      if (res.data.data.length > 0) {
        setSelectedArea(res.data.data[0].id); // Assuming you want to select the first area by default
      }
    } catch (error) {
      console.error(error);
      toast.error(error.response.data.message);
    }
  };
  const handleAreaChange = async (event) => { };
  const handleAreaChange1 = async (event) => {
    setPayload1((prev) => ({
      ...prev,
      areaId: parseInt(event.target.value),
    }));
    const payload = {
      areaId: payload1.areaId,
    };
  };

  const handleAreaChange2 = async (event) => {
    setPayload2((prev) => ({
      ...prev,
      areaId: parseInt(event.target.value),
    }));
    const payload = {
      areaId: payload2.areaId,
    };
  };
  const handleAreaChange3 = async (event) => {
    setPayload3((prev) => ({
      ...prev,
      areaId: parseInt(event.target.value),
    }));
    const payload = {
      areaId: payload3.areaId,
    };
  };
  const handleAreaChange4 = async (event) => {
    setPayload4((prev) => ({
      ...prev,
      areaId: parseInt(event.target.value),
    }));
    const payload = {
      areaId: payload4.areaId,
    };
  };
  const handleAreaChange5 = async (event) => {
    setPayload5((prev) => ({
      ...prev,
      areaId: parseInt(event.target.value),
    }));
    const payload = {
      areaId: payload5.areaId,
    };
  };
  const handleAreaChange6 = async (event) => {
    setPayload6((prev) => ({
      ...prev,
      areaId: parseInt(event.target.value),
    }));
    const payload = {
      areaId: payload6.areaId,
    };
  };
  const handleMonthChange1 = (event) => {
    const selectedMonth = event.target.value;
    //const { value } = event.target;
    setPayload1((prev) => ({
      ...prev,
      month: selectedMonth,
    }));
  };
  const handleMonthChange2 = (event) => {
    const selectedMonth = event.target.value;
    //const { value } = event.target;
    setPayload2((prev) => ({
      ...prev,
      month: selectedMonth,
    }));
  };
  const handleMonthChange3 = (event) => {
    const selectedMonth = event.target.value;
    //const { value } = event.target;
    setPayload3((prev) => ({
      ...prev,
      month: selectedMonth,
    }));
  };
  const handleMonthChange4 = (event) => {
    const selectedMonth = event.target.value;
    //const { value } = event.target;
    setPayload4((prev) => ({
      ...prev,
      month: selectedMonth,
    }));
  };
  const handleMonthChange5 = (event) => {
    const selectedMonth = event.target.value;
    // const { value } = event.target;
    setPayload5((prev) => ({
      ...prev,
      month: selectedMonth,
    }));
  };
  const handleMonthChange6 = (event) => {
    const selectedMonth = event.target.value;
    // const { value } = event.target;
    setPayload6((prev) => ({
      ...prev,
      month: selectedMonth,
    }));
  };

  useEffect(() => {
    fetchZoneOptions();
  }, []);
  useEffect(() => {
    if (selectedZone !== "") {
      fetchAreaOptions(selectedZone);
    }
  }, [selectedZone]);

  useEffect(() => {
    fetchAreaOptions();
  }, []);

  useEffect(() => {
    dealerTypeWiseTargetVsAchievementData();
  }, [payload1]); // Empty dependency array means this effect runs only once after initial render
  useEffect(() => {
    planWiseSalesReport();
  }, [payload2]);
  useEffect(() => {
    categoryWiseDailyReport();
  }, [payload3]);
  useEffect(() => {
    planWiseDailyReport();
  }, [payload4]);
  useEffect(() => {
    dailyTargetVsAchievementReport();
  }, [payload5]);
  useEffect(() => {
    dealerTypeWiseRSAStatsReport();
  }, [payload6]);
  useEffect(() => {
    fetchrenewedPoliciesCount();
  }, [payload2])
  return (
    <>
      <div className="flex flex-col w-full min-h-screen bg-gray-100 pt-2 px-6 pb-5">
        <div className="pt-1 pb-4"></div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 w-full mb-6">
          <div className="bg-white shadow rounded-lg p-6 transform transition duration-300 border border-neutral-light">
            <div className="flex flex-col w  justify-between font-medium text-gray-700 mb-4">
              <div className="flex  justify-between items-center mb-4">
                <span className="text-lg ">
                  Dealer Type Wise Target vs Achieved -{" "}
                  {formatMonthYear(selectedMonth1)}
                </span>
                <button
                  className="flex items-center justify-center active:bg-neutral-lightest w-8 h-8 rounded-full"
                  onClick={() => setPayload1(initalPayload1)}
                >
                  <span className="material-symbols-outlined font-bold text-neutral-darker hover:text-black ">
                    refresh
                  </span>
                </button>
              </div>

              <div className="flex text-sm space-x-4 w-full justify-end">
                <div className="flex flex-col">
                  <label for="zone" className="text-xs text-neutral-darker">
                    Zone
                  </label>
                  <select
                    name="zone"
                    id="zone"
                    className="w-28"
                    value={payload1.zoneId}
                    onChange={handleZoneChange1}
                  >
                    {zoneOptions.map((zone) => (
                      <option key={zone.id} value={zone.id}>
                        {zone.name}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="flex flex-col">
                  <label for="area" className="text-xs text-neutral-darker">
                    Area
                  </label>
                  <select
                    name="area"
                    id="area"
                    className="w-28"
                    value={payload1.areaId}
                    onChange={handleAreaChange1}
                    disabled={payload1.zoneId === 0}
                  >
                    {areaOptions.map((area) => (
                      <option key={area.id} value={area.id}>
                        {area.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="flex flex-col">
                  <label
                    htmlFor="month"
                    className="text-xs text-neutral-darker"
                  >
                    Month
                  </label>
                  <input
                    type="month"
                    id="month"
                    name="month"
                    className="w-28"
                    value={payload1.month}
                    onChange={handleMonthChange1}
                  />
                </div>
              </div>
            </div>

            <div className="h-80">
              <ResponsiveBar
                data={chartData1}
                keys={["MTD Target", "Total Achieved", "Total Target"]}
                indexBy="type"
                margin={{ top: 20, right: 130, bottom: 30, left: 80 }}
                padding={0.4}
                groupMode="grouped"
                valueScale={{ type: "linear" }}
                indexScale={{ type: "band", round: true }}
                colors={chartColorSet}
                borderColor={{
                  from: "color",
                  modifiers: [["darker", 1.6]],
                }}
                axisTop={null}
                axisRight={null}
                axisLeft={{
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: "No. of Policies",
                  legendPosition: "middle",
                  legendOffset: -40,
                  truncateTickAt: 0,
                }}
                enableLabel={false}
                labelSkipWidth={12}
                labelSkipHeight={12}
                labelTextColor={{
                  from: "color",
                  modifiers: [["darker", 1.6]],
                }}
                legends={[
                  {
                    anchor: "top-right",
                    direction: "column",
                    justify: false,
                    translateX: 120,
                    translateY: 80,
                    itemsSpacing: 2,
                    itemDirection: "left-to-right",
                    itemWidth: 100,
                    itemHeight: 20,
                    itemOpacity: 0.75,
                    symbolSize: 12,
                    symbolBorderColor: "rgba(0, 0, 0, .5)",
                    effects: [
                      {
                        on: "hover",
                        style: {
                          itemBackground: "rgba(0, 0, 0, .03)",
                          itemOpacity: 1,
                        },
                      },
                    ],
                  },
                ]}
                role="application"
                ariaLabel="Nivo bar chart demo"
                transitionMode="pushOut"
              />
            </div>
          </div>

          <div className="bg-white shadow rounded-lg p-6 w-full transform transition duration-300 border border-neutral-light">
            <div className="flex flex-col justify-between font-medium text-gray-700 mb-4">
              <div className="flex  justify-between items-center mb-4">
                <span className="text-lg ">
                  Dealer Type Wise RSA Stats - {formatMonthYear(selectedMonth6)}
                </span>
                {/* <button className="flex items-center justify-center active:bg-neutral-lightest w-8 h-8 rounded-full">
                  <span className="material-symbols-outlined font-bold text-neutral-darker hover:text-black ">
                    download
                  </span>
                </button> */}
                <button
                  className="flex items-center justify-center active:bg-neutral-lightest w-8 h-8 rounded-full"
                  onClick={() => setPayload6(initalPayload6)}
                >
                  <span className="material-symbols-outlined font-bold text-neutral-darker hover:text-black ">
                    refresh
                  </span>
                </button>
              </div>
              <div className="flex text-sm space-x-4 justify-end">
                <div className="flex flex-col">
                  <label for="zone" className="text-xs text-neutral-darker">
                    Zone
                  </label>
                  <select
                    name="zone"
                    id="zone"
                    className="w-28"
                    value={payload6.zoneId}
                    onChange={handleZoneChange6}
                  >
                    {zoneOptions.map((zone) => (
                      <option key={zone.id} value={zone.id}>
                        {zone.name}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="flex flex-col">
                  <label for="area" className="text-xs text-neutral-darker">
                    Area
                  </label>
                  <select
                    name="area"
                    id="area"
                    className="w-28"
                    value={payload6.areaId}
                    onChange={handleAreaChange6}
                    disabled={payload6.zoneId === 0}
                  >
                    {areaOptions.map((area) => (
                      <option key={area.id} value={area.id}>
                        {area.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="flex flex-col">
                  <label
                    htmlFor="month"
                    className="text-xs text-neutral-darker"
                  >
                    Month
                  </label>
                  <input
                    type="month"
                    id="month"
                    name="month"
                    className="w-28"
                    value={payload6.month}
                    onChange={handleMonthChange6}
                  />
                </div>
              </div>
            </div>
            <div className="h-80">
              <ResponsiveBar
                data={RSAData}
                keys={["Selling RSA", "Not Selling RSA"]}
                indexBy="type"
                margin={{ top: 20, right: 130, bottom: 30, left: 60 }}
                padding={0.6}
                groupMode="stacked"
                valueScale={{ type: "linear" }}
                indexScale={{ type: "band", round: true }}
                colors={chartColorSet}
                borderColor={{
                  from: "color",
                  modifiers: [["darker", 1.6]],
                }}
                enableLabel={true}
                labelTextColor={(obj) => getLabelTextColor(obj.color)}
                axisTop={null}
                axisRight={null}
                axisLeft={{
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: "",
                  legendPosition: "middle",
                  legendOffset: -40,
                  truncateTickAt: 0,
                }}
                labelSkipWidth={12}
                labelSkipHeight={12}
                // labelTextColor={{
                //   from: "color",
                //   modifiers: [["darker", 1.6]],
                // }}
                //labelTextColor={"red"}
                legends={[
                  {
                    anchor: "top-right",
                    direction: "column",
                    justify: false,
                    translateX: 120,
                    translateY: 80,
                    itemsSpacing: 2,
                    itemDirection: "left-to-right",
                    itemWidth: 100,
                    itemHeight: 20,
                    itemOpacity: 0.75,
                    symbolSize: 12,
                    symbolBorderColor: "rgba(0, 0, 0, .5)",
                    effects: [
                      {
                        on: "hover",
                        style: {
                          itemBackground: "rgba(0, 0, 0, .03)",
                          itemOpacity: 1,
                        },
                      },
                    ],
                  },
                ]}
                role="application"
                ariaLabel="Nivo bar chart demo"
                barAriaLabel={(e) =>
                  e.id +
                  ": " +
                  e.formattedValue +
                  " in country: " +
                  e.indexValue
                }
                enableTotals={true}
              />
            </div>
          </div>
        </div>
        <div className="bg-white shadow rounded-lg p-6 transform transition duration-300 border border-neutral-light mb-6">
          <div className="flex flex-col justify-between font-medium text-gray-700 mb-4">
            <div className="flex  justify-between items-center mb-4">
              <span className="text-lg ">
                Daily Target vs Achieved - {formatMonthYear(selectedMonth5)}
              </span>
              {/* <button className="flex items-center justify-center active:bg-neutral-lightest w-8 h-8 rounded-full">
                <span className="material-symbols-outlined font-bold text-neutral-darker hover:text-black ">
                  download
                </span>
              </button> */}
              <button
                className="flex items-center justify-center active:bg-neutral-lightest w-8 h-8 rounded-full"
                onClick={() => setPayload5(initalPayload5)}
              >
                <span className="material-symbols-outlined font-bold text-neutral-darker hover:text-black ">
                  refresh
                </span>
              </button>
            </div>
            <div className="flex text-sm space-x-4 justify-end">
              <div className="flex flex-col">
                <label for="zone" className="text-xs text-neutral-darker">
                  Zone
                </label>
                <select
                  name="zone"
                  id="zone"
                  className="w-28"
                  value={payload5.zoneId}
                  onChange={handleZoneChange5}
                >
                  {zoneOptions.map((zone) => (
                    <option key={zone.id} value={zone.id}>
                      {zone.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="flex flex-col">
                <label for="area" className="text-xs text-neutral-darker">
                  Area
                </label>
                <select
                  name="area"
                  id="area"
                  className="w-28"
                  value={payload5.areaId}
                  onChange={handleAreaChange5}
                  disabled={payload5.zoneId === 0}
                >
                  {areaOptions.map((area) => (
                    <option key={area.id} value={area.id}>
                      {area.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="flex flex-col">
                <label htmlFor="month" className="text-xs text-neutral-darker">
                  Month
                </label>
                <input
                  type="month"
                  id="month"
                  name="month"
                  className="w-28"
                  value={payload5.month}
                  onChange={handleMonthChange5}
                />
              </div>
            </div>
          </div>
          <div className="h-80">
            <ResponsiveBar
              data={dayWiseTargetvsAchivedData}
              keys={["Target", "Achieved"]}
              enableLabel={false}
              indexBy="day"
              margin={{ top: 20, right: 130, bottom: 70, left: 60 }}
              padding={0.3}
              groupMode="grouped"
              valueScale={{ type: "linear" }}
              indexScale={{ type: "band", round: true }}
              colors={chartColorSet}
              borderColor={{
                from: "color",
                modifiers: [["darker", 1.6]],
              }}
              axisTop={null}
              axisRight={null}
              axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: "No. of Policies",
                legendPosition: "middle",
                legendOffset: -40,
                truncateTickAt: 0,
              }}
              labelSkipWidth={12}
              labelSkipHeight={12}
              labelTextColor={{
                from: "color",
                modifiers: [["darker", 1.6]],
              }}
              legends={[
                {
                  anchor: "top-right",
                  direction: "column",
                  justify: false,
                  translateX: 120,
                  translateY: 80,
                  itemsSpacing: 2,
                  itemDirection: "left-to-right",
                  itemWidth: 100,
                  itemHeight: 20,
                  itemOpacity: 0.75,
                  symbolSize: 12,
                  symbolBorderColor: "rgba(0, 0, 0, .5)",
                  effects: [
                    {
                      on: "hover",
                      style: {
                        itemBackground: "rgba(0, 0, 0, .03)",
                        itemOpacity: 1,
                      },
                    },
                  ],
                },
              ]}
              role="application"
              ariaLabel="Nivo bar chart demo"
              barAriaLabel={(e) =>
                e.id + ": " + e.formattedValue + " in country: " + e.indexValue
              }
            />
          </div>
        </div>
        <div className="bg-white shadow rounded-lg p-6 transform transition duration-300 w-full border border-neutral-light mb-6">
          <div className="flex justify-between font-medium text-gray-700 mb-4 w-full flex-col">
            <div className="flex  justify-between items-center mb-4">
              <span className="text-lg ">
                Category Wise Daily Performance -{" "}
                {formatMonthYear(selectedMonth3)}
              </span>
              {/* <button className="flex items-center justify-center active:bg-neutral-lightest w-8 h-8 rounded-full">
                <span className="material-symbols-outlined font-bold text-neutral-darker hover:text-black ">
                  download
                </span>
              </button> */}
              <button
                className="flex items-center justify-center active:bg-neutral-lightest w-8 h-8 rounded-full"
                onClick={() => setPayload3(initalPayload3)}
              >
                <span className="material-symbols-outlined font-bold text-neutral-darker hover:text-black ">
                  refresh
                </span>
              </button>
            </div>
            <div className="flex text-sm space-x-4 justify-end">
              <div className="flex flex-col">
                <label for="zone" className="text-xs text-neutral-darker">
                  Zone
                </label>
                <select
                  name="zone"
                  id="zone"
                  className="w-28"
                  value={payload3.zoneId}
                  onChange={handleZoneChange3}
                >
                  {zoneOptions.map((zone) => (
                    <option key={zone.id} value={zone.id}>
                      {zone.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="flex flex-col">
                <label for="area" className="text-xs text-neutral-darker">
                  Area
                </label>
                <select
                  name="area"
                  id="area"
                  className="w-28"
                  value={payload3.areaId}
                  onChange={handleAreaChange3}
                  disabled={payload3.zoneId === 0}
                >
                  {areaOptions.map((area) => (
                    <option key={area.id} value={area.id}>
                      {area.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="flex flex-col">
                <label htmlFor="month" className="text-xs text-neutral-darker">
                  Month
                </label>
                <input
                  type="month"
                  id="month"
                  name="month"
                  className="w-28"
                  value={payload3.month}
                  onChange={handleMonthChange3}
                />
              </div>
            </div>
          </div>
          <div className="h-80 w-full">
            <ResponsiveLine
              data={targetVsAchievementData}
              margin={{ top: 20, right: 40, bottom: 50, left: 60 }}
              xScale={{ type: "point" }}
              yScale={{
                type: "linear",
                min: "auto",
                max: "auto",
                stacked: false,
                reverse: false,
              }}
              curve="catmullRom"
              axisTop={null}
              axisRight={null}
              axisBottom={{
                orient: "bottom",
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: "Month",
                legendOffset: 36,
                legendPosition: "middle",
              }}
              axisLeft={{
                orient: "left",
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: "Value",
                legendOffset: -40,
                legendPosition: "middle",
              }}
              enableGridX={false}
              colors={chartColorSet}
              pointSize={4}
              pointColor={{ theme: "background" }}
              pointBorderWidth={2}
              pointBorderColor={{ from: "serieColor" }}
              pointLabelYOffset={-12}
              useMesh={true}
              legends={[
                {
                  anchor: "top-right",
                  direction: "column",
                  justify: false,
                  translateX: 40,
                  translateY: 80,
                  itemsSpacing: 2,
                  itemDirection: "left-to-right",
                  itemWidth: 100,
                  itemHeight: 20,
                  itemOpacity: 0.75,
                  symbolSize: 12,
                  symbolBorderColor: "rgba(0, 0, 0, .5)",
                  effects: [
                    {
                      on: "hover",
                      style: {
                        itemBackground: "rgba(0, 0, 0, .03)",
                        itemOpacity: 1,
                      },
                    },
                  ],
                },
              ]}
            />
          </div>
        </div>

        <div className="bg-white shadow rounded-lg p-6 transform transition duration-300 border border-neutral-light mb-6">
          <div className="flex flex-col justify-between font-medium text-gray-700 mb-4 w-full">
            <div className="flex  justify-between items-center mb-4">
              <span className="text-lg ">
                Plan Wise Daily Sales - {formatMonthYear(selectedMonth4)}
              </span>
              {/* <button className="flex items-center justify-center active:bg-neutral-lightest w-8 h-8 rounded-full">
                <span className="material-symbols-outlined font-bold text-neutral-darker hover:text-black ">
                  download
                </span>
              </button> */}
              <button
                className="flex items-center justify-center active:bg-neutral-lightest w-8 h-8 rounded-full"
                onClick={() => setPayload4(initalPayload4)}
              >
                <span className="material-symbols-outlined font-bold text-neutral-darker hover:text-black ">
                  refresh
                </span>
              </button>
            </div>
            <div className="flex text-sm space-x-4 justify-end">
              <div className="flex flex-col">
                <label for="zone" className="text-xs text-neutral-darker">
                  Zone
                </label>
                <select
                  name="zone"
                  id="zone"
                  className="w-28"
                  value={payload4.zoneId}
                  onChange={handleZoneChange4}
                >
                  {zoneOptions.map((zone) => (
                    <option key={zone.id} value={zone.id}>
                      {zone.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="flex flex-col">
                <label for="area" className="text-xs text-neutral-darker">
                  Area
                </label>
                <select
                  name="area"
                  id="area"
                  className="w-28"
                  value={payload4.areaId}
                  onChange={handleAreaChange4}
                  disabled={payload4.zoneId === 0}
                >
                  {areaOptions.map((area) => (
                    <option key={area.id} value={area.id}>
                      {area.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="flex flex-col">
                <label htmlFor="month" className="text-xs text-neutral-darker">
                  Month
                </label>
                <input
                  type="month"
                  id="month"
                  name="month"
                  className="w-28"
                  value={payload4.month}
                  onChange={handleMonthChange4}
                />
              </div>
            </div>
          </div>
          <div className="h-80">
            <ResponsiveBar
              data={dayWiseData}
              keys={generateKeys()}
              indexBy="day"
              innerPadding={1}
              margin={{ top: 20, right: 130, bottom: 70, left: 60 }}
              padding={0.5}
              groupMode="stacked"
              valueScale={{ type: "linear" }}
              indexScale={{ type: "band", round: true }}
              colors={chartColorSet}
              borderColor={{
                from: "color",
                modifiers: [["darker", 1.6]],
              }}
              enableLabel={false}
              axisTop={null}
              axisRight={null}
              axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: "No. of Policies",
                legendPosition: "middle",
                legendOffset: -40,
                truncateTickAt: 0,
              }}
              labelSkipWidth={12}
              labelSkipHeight={12}
              labelTextColor={{
                from: "color",
                modifiers: [["darker", 1.6]],
              }}
              legends={[
                {
                  anchor: "bottom-right",
                  direction: "column",
                  justify: false,
                  translateX: 120,
                  translateY: 0,
                  itemsSpacing: 2,
                  itemDirection: "left-to-right",
                  itemWidth: 100,
                  itemHeight: 20,
                  itemOpacity: 0.75,
                  symbolSize: 12,
                  symbolBorderColor: "rgba(0, 0, 0, .5)",
                  effects: [
                    {
                      on: "hover",
                      style: {
                        itemBackground: "rgba(0, 0, 0, .03)",
                        itemOpacity: 1,
                      },
                    },
                  ],
                },
              ]}
              role="application"
              ariaLabel="Nivo bar chart demo"
              barAriaLabel={(e) =>
                e.id + ": " + e.formattedValue + " in country: " + e.indexValue
              }
              enableTotals={true}
            />
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 w-full mb-6">
          <div className="bg-white shadow rounded-lg p-6 transform transition duration-300 border border-neutral-light">
            <div className="flex flex-col justify-between font-medium text-gray-700 mb-4">
              <div className="flex  justify-between items-center mb-4">
                <span className="text-lg ">
                  Plan Wise Sales - {formatMonthYear(selectedMonth2)}
                </span>
                {/* <button className="flex items-center justify-center active:bg-neutral-lightest w-8 h-8 rounded-full">
                  <span className="material-symbols-outlined font-bold text-neutral-darker hover:text-black ">
                    download
                  </span>
                </button> */}
                <button
                  className="flex items-center justify-center active:bg-neutral-lightest w-8 h-8 rounded-full"
                  onClick={() => setPayload2(initalPayload2)}
                >
                  <span className="material-symbols-outlined font-bold text-neutral-darker hover:text-black ">
                    refresh
                  </span>
                </button>
              </div>
              <div className="flex text-sm space-x-4 justify-end">
                <div className="flex flex-col">
                  <label for="zone" className="text-xs text-neutral-darker">
                    Zone
                  </label>
                  <select
                    name="zone"
                    id="zone"
                    className="w-28"
                    value={payload2.zoneId}
                    onChange={handleZoneChange2}
                  >
                    {zoneOptions.map((zone) => (
                      <option key={zone.id} value={zone.id}>
                        {zone.name}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="flex flex-col">
                  <label for="area" className="text-xs text-neutral-darker">
                    Area
                  </label>
                  <select
                    name="area"
                    id="area"
                    className="w-28"
                    value={payload2.areaId}
                    onChange={handleAreaChange2}
                    disabled={payload2.zoneId === 0}
                  >
                    {areaOptions.map((area) => (
                      <option key={area.id} value={area.id}>
                        {area.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="flex flex-col">
                  <label
                    htmlFor="month"
                    className="text-xs text-neutral-darker"
                  >
                    Month
                  </label>
                  <input
                    type="month"
                    id="month"
                    name="month"
                    className="w-28"
                    value={payload2.month}
                    onChange={handleMonthChange2}
                  />
                </div>
              </div>
            </div>
            <div className="h-60 mb-4">
              <ResponsivePie
                data={planWiseData}
                //data ={policyWiseData}
                margin={{ top: 20, right: 20, bottom: 20, left: -250 }}
                innerRadius={0.7}
                padAngle={0}
                cornerRadius={0}
                colors={chartColorSet}
                activeOuterRadiusOffset={8}
                borderWidth={1}
                borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
                radialLabelsSkipAngle={10}
                radialLabelsTextColor="#333333"
                radialLabelsLinkColor={{ from: "color" }}
                sliceLabelsSkipAngle={10}
                sliceLabelsTextColor="#333333"
                enableArcLinkLabels={false}
                padding={{ bottom: 20 }}
                enableArcLabels={true}
                // arcLabelTextColor="white"
                arcLabelsTextColor={(obj) => getLabelTextColor(obj.color)}
                //arcLabelTextColor={({ datum }) => getArcLabelColor(policyWiseData, datum.label)}
                arcLabelTextColor={({ datum }) =>
                  getArcLabelColor(planWiseData, datum.label)
                }
                legends={[
                  {
                    anchor: "top-right",
                    direction: "column",
                    justify: false,
                    translateX: -120,
                    translateY: 20,
                    itemsSpacing: 2,
                    itemDirection: "left-to-right",
                    itemWidth: 100,
                    itemHeight: 20,
                    itemOpacity: 0.75,
                    symbolSize: 12,
                    symbolBorderColor: "rgba(0, 0, 0, .5)",
                    effects: [
                      {
                        on: "hover",
                        style: {
                          itemBackground: "rgba(0, 0, 0, .03)",
                          itemOpacity: 1,
                        },
                      },
                    ],
                  },
                ]}
              />
            </div>
            <div>
              <div className="mb-1 flex justify-between">
                <span className="text-sm">Renewal Sales</span>
                <span className="font-semibold">{renewedPoliciesCountData.renewedPoliciesCount}/{renewedPoliciesCountData.totalSalesCount} ({renewedPoliciesCountData.renewedPoliciesPerc}%)</span>
                {/* <Progress value={33} /> */}

              </div>
              <div className="w-full h-3">
                <div className="rounded-full bg-primary-lightest h-full">
                  {/*   <div className={`rounded-l-full bg-primary  h-full w-[${renewedPoliciesCountData.renewedPoliciesPerc}%]`}></div>  */}
                  {/* <div className="rounded-l-full bg-primary h-full" style={{ width: `${renewedPoliciesCountData.renewedPoliciesPerc}%` || 0 }}></div> */}
                  <div
                    className="rounded-l-full bg-primary h-full"
                    style={{ width: `${renewedPoliciesCountData.renewedPoliciesPerc || 0}%` }}
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
