import React from 'react'
import coverImage from "../assets/img/coverImage.png";
import policyPurchaseSuccess from "../assets/img/policy_purchased_success.svg";
import statuspending from "../assets/img/statuspending.svg";
import Button from "../components/ui/Button";

export default function DealerRegistrationStatus() {
  return (
    <>
    <div className="flex flex-col w-full items-center">
        <div className="sticky -z-10 top-12 w-full">
          {/* <i class="material-symbols-outlined hidden md:block fixed text-white ml-4 mt-2">
            arrow_back
          </i> */}
          <img
            src={coverImage}
            className=" w-full h-36 object-cover"
            alt="cover_image"
          />
        </div>
        {/* <div className=" md:w-1/2 min-w-fit">
          
          <div className="flex justify-center">
            <div className="flex justify-center py-4 pb-8 w-72 md:w-96 space-x-4">
              <Button type="button" label="Go to Dashboard" variant="ghost" />
              <Button
                type="submit"
                label="Create New Policy"
                variant="primary"
              />
            </div>
          </div>
        </div> */}
        <div className="w-11/12 md:w-1/2 min-w-fit">
          <div className=" bg-white -mt-28 px-6 py-4 pb-8 md:-mt-15 border border-neutral-light rounded">
            <div className="flex flex-col items-center">
              <img
                src={statuspending}
                className="w-44 mt-4"
                alt="policy_purchase_failed"
              />
              <h3 className="mt-4 text-xl font-semibold">
              Pending Confirmation!
              </h3>
              <p className="text-sm text-center">Your registration is under process. Kindly wait for approval. </p>
              {/* <div className="w-28 mt-4">
                <Button type="button" label="Retry" variant="primary" />
              </div> */}
            </div>
          </div>
        </div>
      </div>
    
    
    
    </>
  )
}
