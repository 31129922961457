import React, { useEffect, useState } from "react";
import coverImage from "../assets/img/coverImage.png";
import Input from "../components/ui/Input";
import Button from "../components/ui/Button";
import toast from "react-hot-toast";
import { useFormik } from "formik";
import * as Yup from "yup";
import Select from "../components/ui/Select";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { apiService } from "../api.service";
import { service } from "../service";

export default function DealerRegistration() {
  const navigate = useNavigate();
  const [companyTypes, setCompanyTypes] = useState([]);
  const [accountTypes, setAccountTypes] = useState([]);
  const [bankNames, setBankNames] = useState([]);
  const [ics, setIcs] = useState([]);
  const [ifsc, setIfsc] = useState([]);
  const [selectedCompanyType, setSelectedCompanyType] = useState("");
  const [selectedAccountType, setSelectedAccountType] = useState("");
  const [selectedBankName, setSelectedBankName] = useState("");
  const [selectedIcs, setSelectedIcs] = useState("");

  const getCompanyTypes = async () => {
    try {
      const companyTypes = await apiService.getCompanyTypes();
      setCompanyTypes(companyTypes.data.data);
    } catch (error) {
      console.error("Error fetching company types:", error);
    }
  };

  const getAccountTypes = async () => {
    try {
      const accountTypes = await apiService.getAccountTypes();
      setAccountTypes(accountTypes.data.data);
    } catch (error) {
      console.error("Error fetching account types : ", error);
    }
  };

  const getBankNames = async () => {
    try {
      const bankNames = await apiService.getBankNames();
      setBankNames(bankNames.data.data);
    } catch (error) {
      console.error("Error fetching bank names : ", error);
    }
  };

  const getIcs = async () => {
    try {
      const ics = await apiService.getIcs();
      setIcs(ics.data.data);
    } catch (error) {
      console.error("Error fetching ics : ", error);
      toast.error("Error fetching ics");
    }
  };

  useEffect(() => {
    getCompanyTypes();
    getAccountTypes();
    getBankNames();
    getIcs();
  }, []);

  const validationSchema = Yup.object().shape({
    firstName: Yup.string()
      .min(2, "Too Short!")
      .required("Required")
      .matches(/^[a-zA-Z]+$/, "First name should contain only letters"),
    lastName: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Required")
      .matches(/^[a-zA-Z]+$/, "Last name should contain only letters"),
    email: Yup.string()
      .email("Invalid email")
      .required("Required")
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        "Invalid email format"
      ),
    dealershipName: Yup.string().required("Required"),
    companyType: Yup.string().required("Required"),
    mobileNumber: Yup.number()
      .required("Required")
      .typeError("Mobile number must be a valid number")
      .required("Mobile number is required")
      .positive("Mobile number must be a positive number")
      .integer("Mobile number must be an integer")
      .min(1000000000, "Mobile number must have at least 10 digits")
      .max(9999999999, "Mobile number must have at most 10 digits"),
    landlineNumber: Yup.number()
      .required("Required")
      .typeError("landlineNumber must be a valid number")
      .required("landlineNumber is required")
      .positive("landlineNumber must be a positive number")
      .integer("landlineNumber must be an integer")
      .min(1000000000, "landlineNumber must have at least 10 digits")
      .max(9999999999, "landlineNumber must have at most 10 digits"),
    tinNumber: Yup.string()
      .required("Required")
      .matches(/^\d{10,}$/, "TIN must be a minimum of 10 digits")
      .required("TIN is required"),
    gstin: Yup.string()
      .required("Required")
      .matches(
        /^[0-9]{2}[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9a-zA-Z]{1}[a-zA-Z]{1}[0-9a-zA-Z]{1}$/,
        "Invalid GSTIN format"
      ),
    aadhaarNumber: Yup.number()
      .required("Required")
      .positive("aadhaarNumber must be a positive number")
      .integer("aadhaarNumber must be an integer")
      .min(100000000000, "aadhaarNumber must have at least 12 digits")
      .max(999999999999, "aadhaarNumber must have at most 12 digits"),
    pan: Yup.string()
      .required("Required")
      .matches(/^[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}$/, "Invalid PAN format"),
    addressLine1: Yup.string().required("Required"),
    addressLine2: Yup.string().required("Required"),
    pincode: Yup.number()
      .required("Required")
      .min(100000, "pin must have at least 6 digits"),
    city: Yup.string().required("Required"),
    state: Yup.string().required("Required"),
    ifsc: Yup.string()
      .required("Required")
      .matches(
        /^[A-Za-z]{4}[0-9]{7}$/,
        "Invalid IFSC code format: IFSC code must be exactly 11 characters long and contain only letters and digits"
      ),
    // Other validation rules...,
    bankName: Yup.string().required("Required"),
    accountHolderName: Yup.string()
      .required("Required")
      .min(3, "Account holder name must be at least 3 characters long.")
      .max(20, "Account holder cannot be longer than 20 characters.")
      .matches(
        /^[a-zA-Z ]+$/,
        "Account holder name should contain only letters"
      ),
    accountNumber: Yup.string()
      .required("Required")
      .matches(/^[^\s]+$/, "Account number cannot contain whitespace")
      .matches(/^[a-zA-Z0-9]{9,}$/, "Invalid Account number"),
    // Other validation rules...,
    branchAddress: Yup.string().required("Required"),
    accountType: Yup.string().required("Required"),
    insuranceCompany: Yup.string().required("Required"),
  });
  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      dealershipName: "",
      companyType: "",
      mobileNumber: "",
      landlineNumber: "",
      tinNumber: "",
      gstin: "",
      aadhaarNumber: "",
      pan: "",
      addressLine1: "",
      addressLine2: "",
      pincode: "",
      city: "",
      state: "",
      ifsc: "",
      bankName: "",
      accountHolderName: "",
      accountNumber: "",
      branchAddress: "",
      accountType: "",
      insuranceCompany: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      // service.setLocalStorageItem("dealerRegistrationForm", values);
      // navigate("/upload-dealer-documents");

      if (!formik.isValid) {
        toast.error("Form is invalid");
      }

      console.log(values);
      const payload = {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        dealershipName: values.dealershipName,
        companyType: values.companyType,
        mobileNumber: Number(values.mobileNumber),
        landlineNumber: Number(values.landlineNumber),
        tinNumber: values.tinNumber,
        gstin: values.gstin,
        aadhaarNumber: Number(values.aadhaarNumber),
        pan: values.pan,
        addressLine1: values.addressLine1,
        addressLine2: values.addressLine2,
        pincode: Number(values.pincode),
        city: values.city,
        state: values.state,
        ifsc: values.ifsc,
        bankName: values.bankName,
        accountHolderName: values.accountHolderName,
        accountNumber: values.accountNumber,
        branchAddress: values.branchAddress,
        accountType: values.accountType,
        insuranceCompany: values.insuranceCompany,
      };
      apiService
        .registerDealer(payload)
        .then((response) => {
          toast.success(response.data.message);
          navigate("/upload-dealer-documents");
        })
        .catch((error) => {
          console.log(error);
          toast.error(error.response.data.message);
        });
    },
  });

  // useEffect(() => {
  //   const storedValues = service.getLocalStorageItem("dealerRegistrationForm");
  //   if (storedValues) {
  //     formik.setValues(storedValues);
  //     console.log(storedValues.insuranceCompany);
  //     formik.setFieldValue("insuranceCompany", storedValues.insuranceCompany);
  //     formik.setFieldValue("companyType", storedValues.companyType);
  //     formik.setFieldValue("accountType", storedValues.accountType);
  //     // setSelectedCompanyType(storedValues.companyType);
  //     // setSelectedAccountType(storedValues.accountType);
  //     // setSelectedBankName(storedValues.bankName);
  //     // setSelectedIcs(storedValues.insuranceCompany);
  //   }
  // }, []);

  const onSelectCompanyType = (value) => {
    formik.setFieldValue("companyType", value);
    setSelectedCompanyType(value);
    service.setLocalStorageItem("selectedCompanyType", value);
  };

  const onSelectAccountType = (value) => {
    formik.setFieldValue("accountType", value);
    setSelectedAccountType(value);
    service.setLocalStorageItem("selectedAccountType", value);
  };
  const onSelectBankNames = (value) => {
    formik.setFieldValue("bankName", value);
    setSelectedBankName(value);
    service.setLocalStorageItem("selectedBankName", value);
  };
  const onSelectIcs = (value) => {
    formik.setFieldValue("insuranceCompany", value);
    setSelectedIcs(value);
    service.setLocalStorageItem("selectedIcs", value);
  };
  const onPincodeChange = (e) => {
    const { value } = e.target;
    if (!isNaN(value) && value.length <= 6) {
      formik.handleChange(e);
      if (value.length === 6) {
        apiService
          .location(value)
          .then((response) => {
            formik.setFieldValue("city", response.data.data.city);
            formik.setFieldValue("state", response.data.data.state);
          })
          .catch((error) => {
            formik.setFieldValue("city", "");
            formik.setFieldValue("state", "");
            toast.error(error.response.data.message)
          });
      } else {
        formik.setFieldValue("city", "");
        formik.setFieldValue("state", "");
      }
    }
  };
  const onIfscCodeChange = (e) => {
    const { value } = e.target;
    formik.handleChange(e); // Always handle the change event
    // !isNaN(value) &&
    if (value.length <= 11) {
      if (value.length === 11) {
        apiService
          .ifscCode(value)
          .then((response) => {
            const { data } = response.data;
            formik.setFieldValue("bankName", data.name);
            formik.setFieldValue("branchAddress", data.branch);
          })
          .catch(() => {
            formik.setFieldValue("bankName", "");
            formik.setFieldValue("branchAddress", "");
          });
      } else {
        formik.setFieldValue("bankName", "");
        formik.setFieldValue("branchAddress", "");
      }
    }
  };

  return (
    <>
      <div className="flex flex-col w-full items-center">
        <div className="sticky -z-10 top-12 w-full">
          {/* <i className="material-symbols-outlined hidden md:block fixed text-white ml-4 mt-2">
            arrow_back
          </i> */}
          <img
            src={coverImage}
            className=" w-full h-36 object-cover"
            alt="cover_image"
          />
        </div>
        <form onSubmit={formik.handleSubmit}>
          <div className="w-11/12 md:w-1/2 min-w-fit bg-white -mt-28 md:-mt-20 border border-neutral-light rounded mx-2 md:mx-0">
            <div className="bg-base-white px-6 py-4 border-b border-neutral-light rounded-t">
              <h3 className="text-xl font-semibold">Dealer Registration</h3>
              <p className="text-neutral-darker mt-1 text-sm ">
                Start punching policy by filling in the dealer's details
              </p>
            </div>
            <div className="px-10 py-4 pb-8">
              <h4 className="text-lg font-medium pb-2">Dealer details</h4>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-y-2 gap-x-8">
                <div className="flex flex-col">
                  <Input
                    {...formik.getFieldProps("firstName")}
                    formik={formik}
                    id="firstName"
                    name="firstName"
                    type="text"
                    label="First Name"
                    placeholder="Enter your firstname"
                  />
                </div>
                <div className="flex flex-col">
                  <Input
                    {...formik.getFieldProps("lastName")}
                    formik={formik}
                    id="lastName"
                    name="lastName"
                    type="text"
                    label="Last Name"
                    placeholder="Enter your lastname"
                  />
                </div>
                <div className="flex flex-col">
                  <Input
                    {...formik.getFieldProps("email")}
                    formik={formik}
                    id="email"
                    name="email"
                    type="text"
                    label="Email"
                    placeholder="Enter your email"
                  />
                </div>
                <div className="flex flex-col">
                  <Input
                    {...formik.getFieldProps("dealershipName")}
                    formik={formik}
                    id="dealershipName"
                    name="dealershipName"
                    type="text"
                    label="Dealership Name"
                    placeholder="Enter Dealership Name"
                  />
                </div>
                <div className="flex flex-col">
                  <Select
                    {...formik.getFieldProps("companyType")}
                    formik={formik}
                    value={formik.values.companyType}
                    id="companyType"
                    name="companyType"
                    label="Company type"
                    onSelect={onSelectCompanyType}
                    options={companyTypes}
                    className="md:mr-4 mr-0 flex-col md:w-60 w-full"
                  />
                </div>
                <div className="flex flex-col">
                  <Input
                    {...formik.getFieldProps("mobileNumber")}
                    formik={formik}
                    id="mobileNumber"
                    name="mobileNumber"
                    type="text"
                    maxLength="10"
                    label="Mobile Number"
                    placeholder="Enter your mobile number"
                  />
                </div>
                <div className="flex flex-col">
                  <Input
                    {...formik.getFieldProps("landlineNumber")}
                    formik={formik}
                    id="landlineNumber"
                    name="landlineNumber"
                    type="text"
                    label="Landline Number"
                    placeholder="Enter your landline number"
                  />
                </div>
                <div className="flex flex-col">
                  <Input
                    {...formik.getFieldProps("tinNumber")}
                    formik={formik}
                    id="tinNumber"
                    name="tinNumber"
                    type="text"
                    label="TIN Number"
                    placeholder="Enter your TIN number"
                  />
                </div>
                <div className="flex flex-col">
                  <Input
                    {...formik.getFieldProps("gstin")}
                    formik={formik}
                    id="gstin"
                    name="gstin"
                    type="text"
                    label="GST Number"
                    placeholder="Enter your GST number"
                  />
                </div>
                <div className="flex flex-col">
                  <Input
                    {...formik.getFieldProps("aadhaarNumber")}
                    formik={formik}
                    id="aadhaarNumber"
                    name="aadhaarNumber"
                    type="text"
                    label="Aadhaar Number"
                    placeholder="Enter your Aadhar number"
                  />
                </div>
                <div className="flex flex-col">
                  <Input
                    {...formik.getFieldProps("pan")}
                    formik={formik}
                    id="pan"
                    name="pan"
                    type="text"
                    label="PAN Number"
                    placeholder="Enter your PAN number"
                  />
                </div>
                <div className="flex flex-col">
                  <Input
                    {...formik.getFieldProps("addressLine1")}
                    formik={formik}
                    id="addressLine1"
                    name="addressLine1"
                    type="text"
                    label="Address Line 1"
                    placeholder="Address line 1"
                  />
                </div>
                <div className="flex flex-col">
                  <Input
                    {...formik.getFieldProps("addressLine2")}
                    formik={formik}
                    id="addressLine2"
                    name="addressLine2"
                    type="text"
                    label="Address Line 2"
                    placeholder="Address line 2"
                  />
                </div>
                <div className="flex flex-col">
                  <Input
                    {...formik.getFieldProps("pincode")}
                    formik={formik}
                    id="pincode"
                    name="pincode"
                    type="text"
                    label="Pincode"
                    placeholder="Pincode"
                    onInputChange={(text) => onPincodeChange(text)}
                  />
                </div>
                <div className="flex flex-col">
                  <Input
                    {...formik.getFieldProps("city")}
                    formik={formik}
                    id="city"
                    name="city"
                    type="text"
                    label="City"
                    placeholder="City"
                    readOnly // Add the readOnly attribute here
                  />
                </div>
                <div className="flex flex-col">
                  <Input
                    {...formik.getFieldProps("state")}
                    formik={formik}
                    id="state"
                    name="state"
                    type="text"
                    label="State"
                    placeholder="State"
                    readOnly // Add the readOnly attribute here
                  />
                </div>
              </div>
              <h4 className="py-2 text-lg font-medium ">Account details</h4>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-y-2 gap-x-8">
                <div className="flex flex-col">
                  <Input
                    {...formik.getFieldProps("accountHolderName")}
                    formik={formik}
                    id="accountHolderName"
                    name="accountHolderName"
                    type="text"
                    label="Account Holder Name"
                    placeholder="Account holder name"
                  />
                </div>
                <div className="flex flex-col">
                  <Input
                    {...formik.getFieldProps("accountNumber")}
                    formik={formik}
                    id="accountNumber"
                    name="accountNumber"
                    type="text"
                    label="Account Number"
                    placeholder="Account number"
                  />
                </div>
                <div className="flex flex-col">
                  <Select
                    {...formik.getFieldProps("accountType")}
                    formik={formik}
                    value={formik.values.accountType}
                    id="accountType"
                    name="accountType"
                    label="Account type"
                    onSelect={onSelectAccountType}
                    options={accountTypes}
                    className="md:mr-4 mr-0 flex-col md:w-60 w-full"
                  />
                </div>

                <div className="flex flex-col">
                  <Input
                    {...formik.getFieldProps("ifsc")}
                    formik={formik}
                    id="ifsc"
                    name="ifsc"
                    type="text"
                    label="Ifsc code"
                    placeholder="Enter Ifsc code"
                    onInputChange={(text) => onIfscCodeChange(text)}
                  />
                </div>
                <div className="flex flex-col">
                  <Input
                    {...formik.getFieldProps("bankName")}
                    formik={formik}
                    id="bankName"
                    name="bankName"
                    label="Bank"
                    readOnly
                  />
                </div>
                <div className="flex flex-col">
                  <Input
                    {...formik.getFieldProps("branchAddress")}
                    formik={formik}
                    id="branchAddress"
                    name="branchAddress"
                    type="text"
                    label="Branch Address"
                    placeholder="Branch address"
                    readOnly
                  />
                </div>
                <div className="flex flex-col">
                  <Select
                    {...formik.getFieldProps("insuranceCompany")}
                    formik={formik}
                    value={formik.values.insuranceCompany}
                    id="insuranceCompany"
                    name="insuranceCompany"
                    label="Insurance company"
                    onSelect={onSelectIcs}
                    options={ics}
                    className="md:mr-4 mr-0 flex-col md:w-60 w-full"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-center">
            <div className="flex justify-center pt-4 pb-8 w-72">
              <Button
                type="button"
                label="Reset"
                variant="ghost"
                onClick={formik.resetForm}
              />
              <Button type="submit" label="Register" variant="primary" />
            </div>
          </div>
        </form>
      </div>
    </>
  );
}
