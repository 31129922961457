import React, { useState, useEffect } from "react";
import coverImage from "../../../assets/img/coverImage.png";
import Input from "../../../components/ui/Input";
import Button from "../../../components/ui/Button";
import toast, { Toaster } from "react-hot-toast";
import { useFormik } from "formik";
import * as Yup from "yup";
import Select from "../../../components/ui/Select";
import File from "../../../components/ui/File";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { useNavigate } from "react-router-dom";
import ReactHTMLTableToExcel from "react-html-table-to-excel"; // Import ReactHTMLTableToExcel
//import { Data } from "../pages/defaultData";
import { apiService } from "../../../api.service";
import FileSaver from "file-saver";
import Tippy from "@tippyjs/react";
import {
    Description,
    Dialog,
    DialogPanel,
    DialogTitle,
} from "@headlessui/react";
import moment from "moment";
import {
    createColumnHelper,
    flexRender,
    getCoreRowModel,
    useReactTable,
    SortingState,
    getSortedRowModel,
    getPaginationRowModel,
} from "@tanstack/react-table";
import { tab } from "@testing-library/user-event/dist/tab";


// Dummy data example
const dummyData = [
    {
        srNo: 1,
        policyNumber: "12345",
        customerName: "John Doe",
        engineNumber: "ABC123",
        chassisNumber: "XYZ789",
        modelName: "Model X",
        dealerCode: "56789",
        location: "City ABC",
        dealerName: "XYZ Motors",
        customerName: "John Doe",
        planName: "Basic Plan",
        policyStartDate: "2024-01-01",
        policyEndDate: "2024-12-31",
    },
    {
        srNo: 2,
        policyNumber: "67890",
        customerName: "Jane Smith",
        engineNumber: "DEF456",
        chassisNumber: "UVW012",
        modelName: "Model Y",
        dealerCode: "23456",
        location: "City XYZ",
        dealerName: "ABC Autos",
        customerName: "Jane Smith",
        planName: "Premium Plan",
        policyStartDate: "2024-02-01",
        policyEndDate: "2024-11-30",
    },
    {
        srNo: 3,
        policyNumber: "67890",
        customerName: "Jane Smith",
        engineNumber: "DEF456",
        chassisNumber: "UVW012",
        modelName: "Model Y",
        dealerCode: "23456",
        location: "City XYZ",
        dealerName: "ABC Autos",
        customerName: "Jane Smith",
        planName: "Premium Plan",
        policyStartDate: "2024-02-01",
        policyEndDate: "2024-11-30",
    },
    {
        srNo: 4,
        policyNumber: "67890",
        customerName: "Jane Smith",
        engineNumber: "DEF456",
        chassisNumber: "UVW012",
        modelName: "Model Y",
        dealerCode: "23456",
        location: "City XYZ",
        dealerName: "ABC Autos",
        customerName: "Jane Smith",
        planName: "Premium Plan",
        policyStartDate: "2024-02-01",
        policyEndDate: "2024-11-30",
    },
    {
        srNo: 5,
        policyNumber: "67890",
        customerName: "Jane Smith",
        engineNumber: "DEF456",
        chassisNumber: "UVW012",
        modelName: "Model Y",
        dealerCode: "23456",
        location: "City XYZ",
        dealerName: "ABC Autos",
        customerName: "Jane Smith",
        planName: "Premium Plan",
        policyStartDate: "2024-02-01",
        policyEndDate: "2024-11-30",
    },
    {
        srNo: 6,
        policyNumber: "67890",
        customerName: "Jane Smith",
        engineNumber: "DEF456",
        chassisNumber: "UVW012",
        modelName: "Model Y",
        dealerCode: "23456",
        location: "City XYZ",
        dealerName: "ABC Autos",
        customerName: "Jane Smith",
        planName: "Premium Plan",
        policyStartDate: "2024-02-01",
        policyEndDate: "2024-11-30",
    },
    {
        srNo: 7,
        policyNumber: "67890",
        customerName: "Jane Smith",
        engineNumber: "DEF456",
        chassisNumber: "UVW012",
        modelName: "Model Y",
        dealerCode: "23456",
        location: "City XYZ",
        dealerName: "ABC Autos",
        customerName: "Jane Smith",
        planName: "Premium Plan",
        policyStartDate: "2024-02-01",
        policyEndDate: "2024-11-30",
    },

    // Add more dummy data objects as needed
];

const columns = [
    { accessorKey: "srNo", header: "#" },
    { accessorKey: "policyNumber", header: "Certificate No" },
    { accessorKey: "customerName", header: "Master Policy No" },
    { accessorKey: "engineNumber", header: "Engine No" },
    { accessorKey: "chassisNumber", header: "Chassis No" },
    { accessorKey: "modelName", header: "Model Name" },
    { accessorKey: "dealerCode", header: "Dealer Code" },
    { accessorKey: "location", header: "Location" },
    { accessorKey: "dealerName", header: "Dealer Name" },
    { accessorKey: "customerName", header: "Customer Name" },
    { accessorKey: "planName", header: "Plan Name" },
    { accessorKey: "policyStartDate", header: "Start Date" },
    { accessorKey: "policyEndDate", header: "End Date" }
];

export const SoldPolicies = () => {

    const [selectedTabIndex, setSelectedTabIndex] = useState(0);
    const [data, setData] = useState(dummyData);
    const [totalCount, setTotalCount] = useState(7);
    const [disableAboutToExpireSelect, setDisableAboutToExpireSelect] =
        useState(true);
    const [filteredData, setFilteredData] = useState([]);
    const [activeTabName, setActiveTabName] = useState("");
    const [sorting, setSorting] = React.useState([]);
    const [payload, setPayload] = useState({

        pagination: {

            pageNumber: 1,
            pageSize: 5,
        }
    });
    const [policyNumberToCancel, setPolicyNumberToCancel] = useState();
    const [showDateRangeModal, setShowDateRangeModal] = useState(false);
    const [loading, setLoading] = useState(true);
    const [noRecordFound, setNoRecordFound] = useState(false);
    const [showCancelPolicyModal, setShowCancelPolicyModal] = useState(false);

    const navigate = useNavigate();

    const columnHelper = createColumnHelper();

    const adminServicePolicyListDateFilterForm = useFormik({
        initialValues: {
            fromDate: "",
            toDate: "",
        },
        validationSchema: Yup.object().shape({
            fromDate: Yup.date().required("Required"),
            toDate: Yup.date().required("Required"),
        }),
        onSubmit: async (values) => {
            console.log(values);
            setPayload((prev) => ({
                ...prev,
                fromDate: values.fromDate,
                toDate: values.toDate,
            }));
            console.log(payload);
            setShowDateRangeModal(false);
        },
    });

    const cancelPolicyForm = useFormik({
        initialValues: {
            reason: "",
            type: "",
            file: "",
        },
        // validationSchema: Yup.object().shape({
        //   reason: Yup.string().required("Required"),
        //   type: Yup.string().required(),
        //   file: Yup.mixed().required("Required"),
        // }),
        onSubmit: async (values) => {
            console.log(values);
            const payload = {
                policyNumber: policyNumberToCancel,
                file: values.file,
                reason: values.reason,
                type: values.type,
            };
            await apiService.requestPolicyCancellation(payload).then((res) => {
                console.log(res);
                toast.success(res.data.message);
                setShowCancelPolicyModal(false);
            });
        },
    });



    // const columns = [
    //     columnHelper.accessor("srNo", {
    //         accessorKey: "srNo",
    //         header: "#",
    //     }),
    //     columnHelper.accessor("policyNumber", {
    //         header: "Certificate No",
    //     }),
    //     columnHelper.accessor("customerName", {
    //         header: "Master Policy No",
    //     }),
    //     // columnHelper.accessor("icName", {
    //     //     header: "IC Name",
    //     // }),

    //     columnHelper.accessor("engineNumber", {
    //         header: "Engine No",
    //     }),
    //     columnHelper.accessor("chassisNumber", {
    //         header: "Chassis No",
    //     }),
    //     columnHelper.accessor("ModelNo", {
    //         header: "Model Name",
    //       }),
    //     columnHelper.accessor("ModelNo", {
    //     header: "Dealer Code",
    //     }),
    //     columnHelper.accessor("ModelNo", {
    //         header: "Location",
    //     }),
    //     columnHelper.accessor("ModelNo", {
    //         header: "Dealer Name",
    //     }),
    //     columnHelper.accessor("ModelNo", {
    //         header: "Customer Name",
    //     }),
    //     columnHelper.accessor("ModelNo", {
    //         header: "Plan Name",
    //     }),

    //     columnHelper.accessor("policyStartDate", {
    //         header: "Start Date",
    //     }),
    //     columnHelper.accessor("policyEndDate", {
    //         header: "End Date",
    //     }),

    //     columnHelper.accessor("", {
    //         header: "Action",
    //         // cell: (data) => (
    //         //     <div className="text-center">
    //         //         <Tippy
    //         //             content="Cancel Policy"
    //         //             placement="right"
    //         //             arrow={false}
    //         //             className="rounded-sm text-xs"
    //         //             delay={1000}
    //         //         >
    //         //             <button
    //         //                 type="button"
    //         //                 onClick={() => {
    //         //                     setShowCancelPolicyModal(true);
    //         //                     setPolicyNumberToCancel(data.row.getValue("policyNumber"));
    //         //                 }}
    //         //             >
    //         //                 <span className="material-symbols-outlined mr-3 md:mr-1 text-sm  font-bold text-danger">
    //         //                     cancel
    //         //                 </span>
    //         //             </button>
    //         //         </Tippy>
    //         //     </div>
    //         // ),
    //     }),
    // ];

    const table = useReactTable({
        data: filteredData.length > 0 ? filteredData : data,
        columns,
        onSortingChange: setSorting,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        //debugTable: true,
        manualPagination: true,
    });

    const requestPolicyCancellation = async () => { };

    // const handleNextPage = () => {
    //   setPayload((prev) => ({
    //     ...prev,
    //     pageNumber: prev.pageNumber + 1,
    //   }));
    // };

    // const handlePreviousPage = () => {
    //   setPayload((prev) => ({
    //     ...prev,
    //     pageNumber: prev.pageNumber - 1 > 0 ? prev.pageNumber - 1 : 1,
    //   }));
    // };

    const totalPages = Math.ceil(totalCount / payload.pagination.pageSize);
    const isLastPage = payload.pagination.pageNumber === totalPages;
    const isFirstPage = payload.pagination.pageNumber === 1;

    const handleNextPage = () => {
        setPayload((prev) => {
            const nextPage = prev.pagination.pageNumber + 1;
            return {
                ...prev,
                pagination: {
                    ...prev.pagination,
                    pageNumber: nextPage > totalPages ? totalPages : nextPage,
                },
            };
        });
    };

    const handleLastPage = () => {
        setPayload((prev) => ({
            ...prev,
            pagination: {
                ...prev.pagination,
                pageNumber: totalPages,
            },
        }));
    };
    const handlePreviousPage = () => {
        setPayload((prev) => {
            const prevPage = prev.pagination.pageNumber - 1;
            return {
                ...prev,
                pagination: {
                    ...prev.pagination,
                    pageNumber: prevPage < 1 ? 1 : prevPage,
                },
            };
        });
    };

    const handleFirstPage = () => {
        setPayload((prev) => ({
            ...prev,
            pagination: {
                ...prev.pagination,
                pageNumber: 1,
            },
        }));
    };

    const downloadExcel = async () => {
        const loadingToast = toast.loading("Downloading Excel file...", {
            duration: 5000,
        });
        try {
            const response = await apiService.downloadExcel(payload);

            const blob = new Blob([response.data], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });

            // Use FileSaver to save the file
            FileSaver.saveAs(blob, "data.xlsx");
            //   const url = window.URL.createObjectURL(new Blob([response.data]));
            //   const link = document.createElement("a");
            //   link.href = url;
            //   link.setAttribute("download", "file.xlsx");
            //   document.body.appendChild(link);
            //   link.click();
        } catch (error) {
            console.error("Error downloading the file", error);
            toast.error("Error downloading the file");
        } finally {
            toast.dismiss(loadingToast);
        }
    };

    //   useEffect(() => {
    //     if (activeTabName !== "") {
    //       const sortedData = [...filteredData].sort((a, b) =>
    //         a[activeTabName].localeCompare(b[activeTabName])
    //       );
    //       setFilteredData(sortedData);
    //     }
    //   }, [activeTabName]);

    // const fetchPolicyData = async () => {
    //     const loadingToast = toast.loading("Loading data...", { duration: 5000 });
    //     setLoading(true);
    //     try {
    //         // payload is changed on each scenario and it will call fetchPolicyData
    //         const response = await apiService.policyData(payload);
    //         console.log(response.data.data);
    //         setData(response.data.data);
    //         setTotalCount(response.data.totalCount);
    //         console.log(data);
    //     } catch (error) {
    //         console.error("Error fetching policy data:", error);
    //         toast.error("Error fetching policy data");
    //     } finally {
    //         toast.dismiss(loadingToast);
    //         setLoading(false);
    //     }
    // };

    useEffect(() => {
        // fetchPolicyData();
    }, [payload]);

    useEffect(() => {
        if (data.length == 0) {
            setNoRecordFound(true);
        } else {
            setNoRecordFound(false);
        }
    }, [data]);

    const reasonOptions = [
        { id: 1, label: "Wrong Data" },
        { id: 2, label: "Other" },
    ];

    return (
        <>
            <Dialog
                open={showCancelPolicyModal}
                onClose={() => setShowCancelPolicyModal(false)}
                className="relative z-50"
            >
                <div className="fixed inset-0 bg-black/75" aria-hidden="true" />

                <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
                    <DialogPanel className="max-w-xl w-full space-y-4 border bg-white border-neutral rounded">
                        <div className="bg-neutral-lightest border-b border-neutral px-6 py-3 flex justify-center text-left">
                            <span className="font-bold text-xl text-left w-full">
                                Policy Cancellation Request
                            </span>
                        </div>

                        <div className="px-2 pb-6">
                            <div className="text-sm text-center px-8">
                                <span >
                                    Would you like to proceed with the cancellation of policy
                                    number <strong>{policyNumberToCancel}</strong>?
                                </span>
                            </div>
                            <form onSubmit={cancelPolicyForm.handleSubmit}>
                                <div className="flex flex-col md:flex-row flex-wrap justify-between mt-4 px-8">
                                    <div className="flex flex-col items-start justify-start w-full md:w-[45%]">
                                        <Input
                                            {...cancelPolicyForm.getFieldProps("reason")}
                                            formik={cancelPolicyForm}
                                            id="reason"
                                            name="reason"
                                            type="text"
                                            label="Reason"
                                            placeholder="Enter reason"
                                            className="w-full"
                                        />
                                    </div>
                                    <div className="flex flex-col w-full  md:w-[45%]">
                                        <Select
                                            {...cancelPolicyForm.getFieldProps("type")}
                                            formik={cancelPolicyForm}
                                            value={cancelPolicyForm.values.type}
                                            id="type"
                                            name="type"
                                            label="Type"
                                            onSelect={(value) =>
                                                cancelPolicyForm.setFieldValue("type", value)
                                            }
                                            options={reasonOptions}
                                            className="w-full"
                                        />
                                    </div>
                                    <div className="mt-4 flex flex-col w-full  md:w-[45%]">
                                        <File
                                            {...cancelPolicyForm.getFieldProps("file")}
                                            formik={cancelPolicyForm}
                                            id="file"
                                            name="file"
                                            label="Upload Pdf"
                                            className="w-full"
                                            icon="picture_as_pdf"
                                            accept=".pdf"
                                            onFileChange={(name, file) => {
                                                cancelPolicyForm.setFieldValue("file", file);
                                            }}
                                        />
                                    </div>
                                </div>

                                <div className="flex items-center justify-end gap-4 mt-4 px-12">
                                    <Button
                                        type="button"
                                        label="No, Go Back"
                                        variant="ghost"
                                        onClick={() => setShowCancelPolicyModal(false)}
                                        className="w-fit"
                                    />
                                    <Button
                                        type="submit"
                                        label="Yes, Cancel Policy"
                                        variant="primary"
                                        className="text-sm md:text-base w-fit"
                                    />
                                </div>
                            </form>
                        </div>
                    </DialogPanel>
                </div>
            </Dialog>
            <Dialog
                open={showDateRangeModal}
                onClose={() => setShowDateRangeModal(false)}
                className="relative z-50"
            >
                <div className="fixed inset-0 bg-black/75" aria-hidden="true" />

                <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
                    <DialogPanel className=" space-y-4 border bg-white border-neutral rounded">
                        <form onSubmit={adminServicePolicyListDateFilterForm.handleSubmit}>
                            <div className="bg-neutral-lightest  border-b border-neutral px-6 py-3 flex">
                                <span className="font-bold text-xl py-2 text-neutral-darkest ">
                                    Custom Date
                                </span>
                            </div>
                            <div className="flex gap-x-4 px-8 pt-4">
                                <div className="flex flex-col">
                                    <Input
                                        {...adminServicePolicyListDateFilterForm.getFieldProps("fromDate")}
                                        formik={adminServicePolicyListDateFilterForm}
                                        id="fromDate"
                                        name="fromDate"
                                        type="date"
                                        max={adminServicePolicyListDateFilterForm.getFieldProps("toDate").value}
                                        label="From Date"
                                        className="w-full md:w-60"
                                    />
                                </div>
                                <div className="flex flex-col">
                                    <Input
                                        {...adminServicePolicyListDateFilterForm.getFieldProps("toDate")}
                                        formik={adminServicePolicyListDateFilterForm}
                                        id="toDate"
                                        name="toDate"
                                        type="date"
                                        min={adminServicePolicyListDateFilterForm.getFieldProps("fromDate").value}
                                        label="To Date"
                                        className="w-full md:w-60"
                                    />
                                </div>
                            </div>

                            <div className="flex items-center justify-center gap-4 mt-4 px-12 pb-8">
                                <Button
                                    type="button"
                                    label="Cancel"
                                    variant="ghost"
                                    className="max-w-12"
                                    onClick={() => setShowDateRangeModal(false)}
                                />
                                <Button
                                    type="submit"
                                    label="Submit"
                                    variant="primary"
                                    className="max-w-16"
                                />
                            </div>
                        </form>
                    </DialogPanel>
                </div>
            </Dialog>
            <div className="flex flex-col w-full items-center overflow-hidden">
                <div className="sticky -z-10 w-full">
                    <img
                        src={coverImage}
                        className=" w-full h-36 object-cover"
                        alt="cover_image"
                    />
                </div>
                <div className="w-11/12 bg-white -mt-28 md:-mt-20 border border-neutral-light rounded mx-2">
                    <form>
                        <div>
                            <div className="bg-base-white px-6 py-4 border-b border-neutral-light rounded-t">
                                <h3 className="text-xl font-semibold">
                                    Sold Policies
                                </h3>
                                <p className="text-neutral-darker mt-1 text-sm">
                                    A brief snapshot of your financial activity, showing incoming
                                    and outgoing transactions
                                </p>
                            </div>
                            <div className="px-6 py-4 pb-8">
                                <div>
                                    <Tabs
                                        selectedIndex={selectedTabIndex}
                                        selectedTabClassName="text-primary border-b-2 border-primary font-medium"
                                    >
                                        <TabList className="flex mb-4 text-neutral-dark text-sm items-center justify-between">
                                            <div className="flex">
                                                {/* <Tab className="uppercase px-4 pb-2 active:text-secondary-darkest cursor-pointer focus:outline-none">
                                Monthly Bank Statement
                              </Tab> */}
                                                {/* <Tab className="uppercase px-4 pb-2 cursor-pointer focus:outline-none">
                                Commission Bank
                              </Tab> */}
                                            </div>
                                            <div className="md:flex flex-col md:flex-row justify-between items-center w-full">
                                                <div className="flex items-center md:gap-x-4 gap-0 mb-2 md:mb-0">
                                                    {/* <div className="flex">
                                                        <input
                                                            type="checkbox"
                                                            onChange={(e) => {
                                                                setPayload((prev) => ({
                                                                    ...prev,
                                                                    toDate: e.target.checked
                                                                        ? moment().format("YYYY-MM-DD")
                                                                        : undefined,
                                                                }));
                                                            }}
                                                        />
                                                        <span className="mr-4 ml-1 text-neutral-darkest">
                                                            Show Expired
                                                        </span>
                                                    </div>
                                                    <div className="flex">
                                                        <input
                                                            type="checkbox"
                                                            onChange={(e) => {
                                                                if (e.target.checked) {
                                                                    setDisableAboutToExpireSelect(false);
                                                                    setPayload((prev) => ({
                                                                        ...prev,
                                                                        fromDate: moment().format("YYYY-MM-DD"),
                                                                        toDate: moment()
                                                                            .add(30, "days")
                                                                            .format("YYYY-MM-DD"),
                                                                    }));
                                                                } else {
                                                                    setDisableAboutToExpireSelect(true);
                                                                    setPayload((prev) => ({
                                                                        ...prev,
                                                                        fromDate: undefined,
                                                                        toDate: undefined,
                                                                    }));
                                                                }
                                                            }}
                                                        />
                                                        <select
                                                            disabled={disableAboutToExpireSelect}
                                                            className="mr-2 text-neutral-darkest"
                                                            onChange={(e) => {
                                                                const duration = Number(e.target.value);
                                                                setPayload((prev) => ({
                                                                    ...prev,
                                                                    fromDate: moment().format("YYYY-MM-DD"),
                                                                    toDate: moment()
                                                                        .add(duration, "days")
                                                                        .format("YYYY-MM-DD"),
                                                                }));
                                                                console.log(payload);
                                                            }}
                                                        >
                                                            {[30, 45, 60].map((day) => (
                                                                <option key={day} value={day}>
                                                                   
                                                                    Expiry in {day} days
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div> */}
                                                </div>
                                                <div className="flex flex-col md:flex-row items-center md:gap-x-4 gap-2">
                                                    <div className="flex items-center md:gap-x-4 md: gap-x-6 w-full md:w-auto">
                                                        <button
                                                            type="button"
                                                            className="mt-1"
                                                            onClick={() => {
                                                                setShowDateRangeModal(true);
                                                            }}
                                                        >
                                                            <span className="material-symbols-outlined text-neutral-darkest">
                                                                date_range
                                                            </span>
                                                        </button>
                                                        <button
                                                            type="button"
                                                            className="mt-1"
                                                            onClick={downloadExcel}
                                                        >
                                                            <span className="material-symbols-outlined text-neutral-darkest">
                                                                download
                                                            </span>
                                                        </button>
                                                    </div>

                                                    <div className="flex flex-col md:flex-row items-center md:gap-x-4 gap-2 w-full md:w-auto mt-2 md:mt-0">
                                                        <select
                                                            id="searchField"
                                                            className="mr-2 text-neutral-darkest w-full md:w-auto"
                                                        >
                                                            <option value="policyNumber">Certificate No</option>
                                                            <option value="customerName">Master Policy No</option>
                                                            <option value="icName">Dealer Code</option>
                                                            <option value="engineNumber">Engine No</option>
                                                            <option value="chassisNumber">Chassis No</option>
                                                        </select>

                                                        <div className="flex w-full md:w-auto">
                                                            <input
                                                                type="text"
                                                                id="search"
                                                                placeholder="Search..."
                                                                className="px-3 py-1 border border-neutral-dark rounded-l-sm focus:outline-primary text-base-black w-full"
                                                            />
                                                            <button
                                                                type="button"
                                                                onClick={() => {
                                                                    setPayload((prev) => ({
                                                                        ...prev,
                                                                        search: {
                                                                            field: document.getElementById("searchField").value,
                                                                            value: document.getElementById("search").value,
                                                                        }
                                                                    }));
                                                                }}
                                                                className="bg-primary text-white border border-primary px-2 py-1 rounded-r-sm"
                                                            >
                                                                <span className="material-symbols-outlined flex items-center">
                                                                    search
                                                                </span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </TabList>

                                        <TabPanel>
                                            <div className="flex mb-4 overflow-x-scroll cursor-pointer">
                                                <table
                                                    id="table-to-xls"
                                                    className="text-sm border border-neutral w-full"
                                                >
                                                    <thead className="bg-primary text-white text-xs">
                                                        {table.getHeaderGroups().map((headerGroup) => (
                                                            <tr key={headerGroup.id}>
                                                                {headerGroup.headers.map((header) => {
                                                                    return (
                                                                        <th
                                                                            key={header.id}
                                                                            colSpan={header.colSpan}
                                                                            className="px-2 py-1 text-left"
                                                                        >
                                                                            {header.isPlaceholder ? null : (
                                                                                <div
                                                                                    className={
                                                                                        header.column.getCanSort()
                                                                                            ? "cursor-pointer select-none"
                                                                                            : ""
                                                                                    }
                                                                                    onClick={header.column.getToggleSortingHandler()}
                                                                                    title={
                                                                                        header.column.getCanSort()
                                                                                            ? header.column.getNextSortingOrder() ===
                                                                                                "asc"
                                                                                                ? "Sort ascending"
                                                                                                : header.column.getNextSortingOrder() ===
                                                                                                    "desc"
                                                                                                    ? "Sort descending"
                                                                                                    : "Clear sort"
                                                                                            : undefined
                                                                                    }
                                                                                >
                                                                                    {flexRender(
                                                                                        header.column.columnDef.header,
                                                                                        header.getContext()
                                                                                    )}
                                                                                    {{
                                                                                        asc: " 🔼",
                                                                                        desc: " 🔽",
                                                                                    }[header.column.getIsSorted()] ??
                                                                                        null}
                                                                                </div>
                                                                            )}
                                                                        </th>
                                                                    );
                                                                })}
                                                            </tr>
                                                        ))}
                                                    </thead>
                                                    {/* <tbody>
                                  {table.getRowModel().rows.map((row) => {
                                    return (
                                      <tr
                                        key={row.id}
                                        className="text-xs even:bg-primary-lightest"
                                      >
                                        {row.getVisibleCells().map((cell) => {
                                          return (
                                            <td key={cell.id} className="px-2 py-1">
                                              {" "}
                                              
                                              {flexRender(
                                                cell.column.columnDef.cell,
                                                cell.getContext()
                                              )}
                                            </td>
                                          );
                                        })}
                                      </tr>
                                    );
                                  })}
                                </tbody> */}
                                                    <tbody>
                                                        {noRecordFound ? (
                                                            <tr className="min-h-20">
                                                                <td
                                                                    colSpan={columns.length}
                                                                    className="text-center p-4 min-h-20"
                                                                >
                                                                    No records found
                                                                </td>
                                                            </tr>
                                                        ) : (
                                                            table.getRowModel().rows.map((row) => (
                                                                <tr
                                                                    key={row.id}
                                                                    className="text-xs even:bg-primary-lightest"
                                                                >
                                                                    {row.getVisibleCells().map((cell) => (
                                                                        <td key={cell.id} className="px-2 py-1">
                                                                            {flexRender(
                                                                                cell.column.columnDef.cell,
                                                                                cell.getContext()
                                                                            )}
                                                                        </td>
                                                                    ))}
                                                                </tr>
                                                            ))
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>

                                            <div className="flex flex-col md:flex-row items-center w-full justify-end text-xs gap-4">
                                                <div className="flex items-center gap-2">
                                                    {" "}
                                                    <select
                                                        value={payload.pageSize}
                                                        onChange={(e) => {
                                                            const size = Number(e.target.value);
                                                            setPayload((prev) => ({
                                                                ...prev,
                                                                pagination: {
                                                                    pageSize: size,
                                                                    pageNumber: 1
                                                                },
                                                            }));
                                                        }}
                                                    >
                                                        {[5, 10, 15, 20, 25, 30, 35, 40, 45, 50].map(
                                                            (pageSize) => (
                                                                <option key={pageSize} value={pageSize}>
                                                                    {pageSize}
                                                                </option>
                                                            )
                                                        )}
                                                    </select>
                                                    <span className="font-semibold">
                                                        <span>Showing </span>
                                                        {1 +
                                                            (payload.pagination.pageNumber - 1) *
                                                            payload.pagination.pageSize}{" "}
                                                        to{" "}
                                                        {payload.pagination.pageNumber *
                                                            payload.pagination.pageSize -
                                                            payload.pagination.pageSize +
                                                            data?.length}{" "}
                                                        of {totalCount} Entries
                                                    </span>
                                                </div>
                                                {/* <button type="button" disabled={isFirstPage} onClick={handleFirstPage}>
                                <span className="material-symbols-outlined">
                                  first_page
                                </span>
                              </button> */}
                                                <div className="flex items-center space-x-2 mtt-2 md:mt-0">
                                                    <button
                                                        type="button"
                                                        disabled={isFirstPage}
                                                        onClick={handleFirstPage}
                                                        className={`material-symbols-outlined ${isFirstPage ? 'cursor-not-allowed text-neutral-light' : 'cursor-pointer'
                                                            }`}
                                                    >
                                                        first_page
                                                    </button>
                                                    {/* <button type="button" onClick={handlePreviousPage} disabled={isFirstPage}>
                                <span class="material-symbols-outlined">
                                  chevron_left
                                </span>
                              </button> */}
                                                    <button
                                                        type="button"
                                                        onClick={handlePreviousPage}
                                                        disabled={isFirstPage}
                                                        className={`material-symbols-outlined ${isFirstPage ? 'cursor-not-allowed text-neutral-light' : 'cursor-pointer'
                                                            }`}
                                                    >
                                                        chevron_left
                                                    </button>
                                                    {/* <button type="button" onClick={handleNextPage} disabled={isLastPage}>
                                <span class="material-symbols-outlined">
                                  chevron_right
                                </span>
                              </button> */}
                                                    <button
                                                        type="button"
                                                        onClick={handleNextPage}
                                                        disabled={isLastPage}
                                                        className={`material-symbols-outlined ${isLastPage ? 'cursor-not-allowed text-neutral-light' : 'cursor-pointer'
                                                            }`}
                                                    >
                                                        chevron_right
                                                    </button>
                                                    {/* <button type="button" className="disabled:text-neutral-light" onClick={handleLastPage} disabled={isLastPage}>
                                <span className="material-symbols-outlined text-inherit">
                                  last_page
                                </span>
                              </button> */}
                                                    <button
                                                        type="button"
                                                        className={`material-symbols-outlined ${isLastPage ? 'cursor-not-allowed text-neutral-light' : 'cursor-pointer'
                                                            }`}
                                                        onClick={handleLastPage}
                                                        disabled={isLastPage}
                                                    >
                                                        last_page
                                                    </button>
                                                </div>

                                            </div>

                                        </TabPanel>
                                    </Tabs>
                                </div>
                            </div>
                        </div>

                    </form>
                </div>
            </div>
        </>
    );

}
