import React, { useState, useEffect } from "react";
import toast, { Toaster } from "react-hot-toast";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { useNavigate } from "react-router-dom";
import FileSaver from "file-saver";
import Tippy from "@tippyjs/react";
import {
  Description,
  Dialog,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";
import moment from "moment";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
  SortingState,
  getSortedRowModel,
  getPaginationRowModel,
} from "@tanstack/react-table";
import CurrencyFormat from "react-currency-format";

import coverImage from "../../assets/img/coverImage.png";
import Input from "../../components/ui/Input";
import Button from "../../components/ui/Button";
import Select from "../../components/ui/Select";
import File from "../../components/ui/File";
import { apiService } from "../../api.service";

export const ICPayments = () => {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [data, setData] = useState([]);
  const [icOptions, setIcOptions] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [disableAboutToExpireSelect, setDisableAboutToExpireSelect] =
    useState(true);
  const [filteredData, setFilteredData] = useState([]);
  const [activeTabName, setActiveTabName] = useState("");
  const [sorting, setSorting] = React.useState([]);
  const [payload, setPayload] = useState({
    pagination: {
      pageNumber: 1,
      pageSize: 5,
    },
    // search: "",
  });
  const [policyNumberToCancel, setPolicyNumberToCancel] = useState();
  const [showDateRangeModal, setShowDateRangeModal] = useState(false);
  const [showPaymentDialog, setShowPaymentDialog] = useState(false);

  const [loading, setLoading] = useState(true);
  const [noRecordFound, setNoRecordFound] = useState(false);
  const [showCancelPolicyModal, setShowCancelPolicyModal] = useState(false);

  const navigate = useNavigate();

  const columnHelper = createColumnHelper();

  const dateFilterForm = useFormik({
    initialValues: {
      fromDate: "",
      toDate: "",
    },
    validationSchema: Yup.object().shape({
      fromDate: Yup.date().required("Required"),
      toDate: Yup.date().required("Required"),
    }),
    onSubmit: async (values) => {
      console.log(values);
      setPayload((prev) => ({
        ...prev,
        dates: {
          fromDate: values.fromDate,
          toDate: values.toDate,
        },
      }));
      console.log(payload);
      setShowDateRangeModal(false);
    },
  });

  const icPaymentForm = useFormik({
    initialValues: {
      icId: "",
      transactionNumber: "",
      ifscCode: "",
      bank: "",
      accountNumber: "",
      amount: "",
      paymentDate: "",
    },
    validationSchema: Yup.object().shape({
      icId: Yup.number().required('Required'),
      transactionNumber: Yup.string().required('Required'),
      ifscCode: Yup.string().required('Required'),
      bank: Yup.string().required('Required'),
      accountNumber: Yup.string().required('Required'),
      amount: Yup.number().required('Required'),
      paymentDate: Yup.date().required('Required'),
    }),
    onSubmit: async (values) => {
      const payload = {
        ...values
      }
      await apiService.addIcPayment(payload).then(res => {
        fetchIcPaymentData()
        toast.success(res.data.data.message)
      }).catch(error => {
        toast.error(error.response.data.message)
      }).finally(() => {
        setShowPaymentDialog(false)
      })
      console.log(payload);
      setShowDateRangeModal(false);
    },
  });

  const columns = [
    columnHelper.accessor("srNo", {
      accessorKey: "srNo",
      header: "#",
    }),
    columnHelper.accessor("transactionNumber", {
      header: "Transaction No.",
    }),
    columnHelper.accessor("bankName", {
      header: "Bank",
    }),
    columnHelper.accessor("ifscCode", {
      header: "IFSC Code",
    }),
    columnHelper.accessor("amount", {
      header: "Amount",
      cell: (row) => {
        return (
          <span>
            {row.getValue() ? (
              <CurrencyFormat
                value={row.getValue()}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"₹ "}
              />
            ) : (
              "-"
            )}
          </span>
        );
      },
    }),
    columnHelper.accessor("paymentDate", {
      header: "Payment Date",
      cell: (data) => {
        return (
          <span>
            {data.getValue()
              ? moment(data.getValue()).format("Do MMM YYYY")
              : "-"}
          </span>
        );
      },
    }),
  ];

  const table = useReactTable({
    data: data,
    columns: columns,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    manualPagination: true,
  });

  const totalPages = Math.ceil(totalCount / payload.pagination.pageSize);
  const isLastPage = payload.pagination.pageNumber === totalPages;
  const isFirstPage = payload.pagination.pageNumber === 1;

  const handleFirstPage = () => {
    setPayload((prev) => ({
      ...prev,
      pagination: {
        ...prev.pagination,
        pageNumber: 1,
      },
    }));
  };

  const handlePreviousPage = () => {
    setPayload((prev) => {
      const prevPage = prev.pagination.pageNumber - 1;
      return {
        ...prev,
        pagination: {
          ...prev.pagination,
          pageNumber: prevPage < 1 ? 1 : prevPage,
        },
      };
    });
  };

  const handleNextPage = () => {
    setPayload((prev) => {
      const nextPage = prev.pagination.pageNumber + 1;
      return {
        ...prev,
        pagination: {
          ...prev.pagination,
          pageNumber: nextPage > totalPages ? totalPages : nextPage,
        },
      };
    });
  };

  const handleLastPage = () => {
    setPayload((prev) => ({
      ...prev,
      pagination: {
        ...prev.pagination,
        pageNumber: totalPages,
      },
    }));
  };

  const downloadExcel = async () => {
    const loadingToast = toast.loading("Downloading Excel file...", {
      duration: 5000,
    });
    try {
      const response = await apiService.downloadExcel(payload);

      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      // Use FileSaver to save the file
      FileSaver.saveAs(blob, "data.xlsx");
      //   const url = window.URL.createObjectURL(new Blob([response.data]));
      //   const link = document.createElement("a");
      //   link.href = url;
      //   link.setAttribute("download", "file.xlsx");
      //   document.body.appendChild(link);
      //   link.click();
    } catch (error) {
      console.error("Error downloading the file", error);
      toast.error("Error downloading the file");
    } finally {
      toast.dismiss(loadingToast);
    }
  };

  const fetchIcPaymentData = async () => {
    const loadingToast = toast.loading("Loading data...", { duration: 5000 });
    setLoading(true);
    try {
      // payload is changed on each scenario and it will call fetchPolicyData
      const response = await apiService.icPayments(payload);
      console.log(response.data.data);
      setData(response.data.data);
      setTotalCount(response.data.totalCount);
      console.log(data);
    } catch (error) {
      console.error("Error fetching policy data:", error);
      toast.error("Error fetching policy data");
    } finally {
      toast.dismiss(loadingToast);
      setLoading(false);
    }
  };

  const fetchICs = async () => {
    await apiService.getIcs().then(res => {
      setIcOptions(res.data.data)
    }).catch(error => {
      toast.error(error.response.data.message)
    })
  };

  useEffect(() => {
    fetchIcPaymentData();
  }, [payload]);

  useEffect(() => {
    if (data.length == 0) {
      setNoRecordFound(true);
    } else {
      setNoRecordFound(false);
    }
  }, [data]);

  useEffect(() => {
    fetchICs()
  }, [])

  const reasonOptions = [
    { id: 1, label: "Wrong Data" },
    { id: 2, label: "Other" },
  ];

  return (
    <>
      <div className="flex flex-col w-full items-center overflow-hidden">
        <div className="sticky -z-10 w-full">
          <img
            src={coverImage}
            className=" w-full h-36 object-cover"
            alt="cover_image"
          />
        </div>
        <div className="w-11/12 bg-white -mt-28 md:-mt-20 border border-neutral-light rounded mx-2">
          <form>
            <div>
              <div className="bg-base-white px-6 py-4 border-b border-neutral-light rounded-t">
                <h3 className="text-xl font-semibold">IC Payments</h3>
                <p className="text-neutral-darker mt-1 text-sm">
                  A brief snapshot of your financial activity, showing incoming
                  and outgoing transactions
                </p>
              </div>
              <div className="px-6 py-4 pb-8">
                <div>
                  <Tabs
                    selectedIndex={selectedTabIndex}
                    selectedTabClassName="text-primary border-b-2 border-primary font-medium"
                  >
                    <TabList className="flex mb-4 text-neutral-dark text-sm items-center justify-between">
                      <div className="flex">
                        {/* <Tab className="uppercase px-4 pb-2 active:text-secondary-darkest cursor-pointer focus:outline-none">
                          Monthly Bank Statement
                        </Tab> */}
                        {/* <Tab className="uppercase px-4 pb-2 cursor-pointer focus:outline-none">
                          Commission Bank
                        </Tab> */}
                      </div>
                      <div className="md:flex flex-col md:flex-row justify-between items-center w-full ">
                        <div className="flex  items-center md:gap-x-4 gap-2 justify-between md:w-[60%] ">
                          <button
                            type="button"
                            onClick={() => setShowPaymentDialog(true)}
                            className="flex items-center bg-primary text-white active:bg-primary-darkest text-sm px-3 py-1 rounded-sm "
                          >
                            <span class="material-symbols-outlined flex items-center justify-center">
                              add
                            </span>
                            Add Payment
                          </button>

                          {/* <div className="md:flex flex-col md:flex-row items-center md:gap-x-4 gap-2"> */}
                          <div className="flex gap-4">
                            <button
                              type="button"
                              className="mt-1 "
                              onClick={() => {
                                setShowDateRangeModal(true);
                              }}
                            >
                              <span class="material-symbols-outlined text-neutral-darkest">
                                date_range
                              </span>
                            </button>
                            {
                              <button
                                type="button"
                                className="mt-1"
                                onClick={downloadExcel}
                              >
                                <span class="material-symbols-outlined text-neutral-darkest">
                                  download
                                </span>
                              </button>

                            }
                          </div>

                        </div>

                        <div className="flex items-center mt-4 md:mt-0">
                          <select
                            id="searchField"
                            className="mr-2 text-neutral-darkest"
                          >
                            <option value="transaction_no">
                              Transaction No.
                            </option>
                            <option value="bank_name">Bank</option>
                            <option value="ifsc_code">IFSC Code</option>
                            <option value="amount">Amount</option>
                            <option value="payment_date">Payment Date</option>
                            <option value="sold_policy_no">
                              Policy Number
                            </option>
                            <option value="model_name">Model</option>
                          </select>
                          <input
                            type="text"
                            id="search"
                            placeholder="Search..."
                            className="px-3 py-1 border border-neutral-dark rounded-l-sm focus:outline-primary text-base-black w-full md:w-60"
                          />
                          <button
                            type="button"
                            onClick={() => {
                              setPayload((prev) => ({
                                ...prev,
                                search: {
                                  field: document.getElementById("searchField").value,
                                  value: document.getElementById("search").value,
                                },
                              }));
                            }}
                            className="bg-primary text-white border border-primary px-2 py-0.5 rounded-r-sm"
                          >
                            <span class="material-symbols-outlined flex items-center justify-center">
                              search
                            </span>
                          </button>
                        </div>
                      </div>
                      {/* </div> */}


                    </TabList>

                    <TabPanel>
                      <div className="flex mb-4 overflow-x-scroll cursor-pointer">
                        <table
                          id="table-to-xls"
                          className="text-sm border border-neutral w-full"
                        >
                          <thead className="bg-primary text-white text-xs">
                            {table.getHeaderGroups().map((headerGroup) => (
                              <tr key={headerGroup.id}>
                                {headerGroup.headers.map((header) => {
                                  return (
                                    <th
                                      key={header.id}
                                      colSpan={header.colSpan}
                                      className="px-2 py-1 text-left"
                                    >
                                      {header.isPlaceholder ? null : (
                                        <div
                                          className={
                                            header.column.getCanSort()
                                              ? "cursor-pointer select-none"
                                              : ""
                                          }
                                          onClick={header.column.getToggleSortingHandler()}
                                          title={
                                            header.column.getCanSort()
                                              ? header.column.getNextSortingOrder() ===
                                                "asc"
                                                ? "Sort ascending"
                                                : header.column.getNextSortingOrder() ===
                                                  "desc"
                                                  ? "Sort descending"
                                                  : "Clear sort"
                                              : undefined
                                          }
                                        >
                                          {flexRender(
                                            header.column.columnDef.header,
                                            header.getContext()
                                          )}
                                          {{
                                            asc: " 🔼",
                                            desc: " 🔽",
                                          }[header.column.getIsSorted()] ??
                                            null}
                                        </div>
                                      )}
                                    </th>
                                  );
                                })}
                              </tr>
                            ))}
                          </thead>

                          <tbody>
                            {noRecordFound ? (
                              <tr className="min-h-20">
                                <td
                                  colSpan={columns.length}
                                  className="text-center p-4 min-h-20"
                                >
                                  No records found
                                </td>
                              </tr>
                            ) : (
                              table.getRowModel().rows.map((row) => (
                                <tr
                                  key={row.id}
                                  className="text-xs even:bg-primary-lightest"
                                >
                                  {row.getVisibleCells().map((cell) => (
                                    <td key={cell.id} className="px-2 py-1">
                                      {flexRender(
                                        cell.column.columnDef.cell,
                                        cell.getContext()
                                      )}
                                    </td>
                                  ))}
                                </tr>
                              ))
                            )}
                          </tbody>
                        </table>
                      </div>

                      <div className="flex flex-col md:flex-row items-center w-full justify-end text-xs gap-4">
                        <div className="flex items-center gap-2">
                          {" "}
                          <select
                            value={payload.pageSize}
                            onChange={(e) => {
                              const size = Number(e.target.value);
                              setPayload((prev) => ({
                                ...prev,
                                pagination: {
                                  pageSize: size,
                                  pageNumber: 1,
                                },
                              }));
                            }}
                          >
                            {[5, 10, 15, 20, 25, 30, 35, 40, 45, 50].map(
                              (pageSize) => (
                                <option key={pageSize} value={pageSize}>
                                  {pageSize}
                                </option>
                              )
                            )}
                          </select>
                          <span className="font-semibold">
                            <span>Showing </span>
                            {1 +
                              (payload.pagination.pageNumber - 1) *
                              payload.pagination.pageSize}{" "}
                            to{" "}
                            {payload.pagination.pageNumber *
                              payload.pagination.pageSize -
                              payload.pagination.pageSize +
                              data?.length}{" "}
                            of {totalCount} Entries
                          </span>
                        </div>
                        {/* <button type="button" disabled={isFirstPage} onClick={handleFirstPage}>
                          <span className="material-symbols-outlined">
                            first_page
                          </span>
                        </button> */}
                        <div className="flex items-center space-x-2 mt-2 md:mt-0">
                          <button
                            type="button"
                            disabled={isFirstPage}
                            onClick={handleFirstPage}
                            className={`material-symbols-outlined ${isFirstPage
                              ? "cursor-not-allowed text-neutral-light"
                              : "cursor-pointer"
                              }`}
                          >
                            first_page
                          </button>
                          {/* <button type="button" onClick={handlePreviousPage} disabled={isFirstPage}>
                          <span class="material-symbols-outlined">
                            chevron_left
                          </span>
                        </button> */}
                          <button
                            type="button"
                            onClick={handlePreviousPage}
                            disabled={isFirstPage}
                            className={`material-symbols-outlined ${isFirstPage
                              ? "cursor-not-allowed text-neutral-light"
                              : "cursor-pointer"
                              }`}
                          >
                            chevron_left
                          </button>
                          {/* <button type="button" onClick={handleNextPage} disabled={isLastPage}>
                          <span class="material-symbols-outlined">
                            chevron_right
                          </span>
                        </button> */}
                          <button
                            type="button"
                            onClick={handleNextPage}
                            disabled={isLastPage}
                            className={`material-symbols-outlined ${isLastPage
                              ? "cursor-not-allowed text-neutral-light"
                              : "cursor-pointer"
                              }`}
                          >
                            chevron_right
                          </button>
                          {/* <button type="button" className="disabled:text-neutral-light" onClick={handleLastPage} disabled={isLastPage}>
                          <span className="material-symbols-outlined text-inherit">
                            last_page
                          </span>
                        </button> */}
                          <button
                            type="button"
                            className={`material-symbols-outlined ${isLastPage
                              ? "cursor-not-allowed text-neutral-light"
                              : "cursor-pointer"
                              }`}
                            onClick={handleLastPage}
                            disabled={isLastPage}
                          >
                            last_page
                          </button>
                        </div>
                      </div>
                    </TabPanel>
                  </Tabs>
                </div>
              </div>
            </div>
          </form>
        </div >
      </div >
      <Dialog
        open={showPaymentDialog}
        onClose={() => setShowPaymentDialog(false)}
        className="relative z-50"
      >
        <div className="fixed inset-0 bg-black/75" aria-hidden="true" />

        <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
          <DialogPanel className="space-y-4 border bg-white border-neutral rounded">
            <form onSubmit={icPaymentForm.handleSubmit}>
              <div className="bg-neutral-lightest border-b border-neutral px-6 py-3 flex">
                <span className="font-bold text-xl py-2 text-neutral-darkest">
                  Add IC Payment
                </span>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-x-4 gap-y-2 px-8 pt-4">
                <div className="flex flex-col">
                  <Select
                    {...icPaymentForm.getFieldProps("icId")}
                    formik={icPaymentForm}
                    value={icPaymentForm.values.icId}
                    id="state"
                    name="state"
                    label="State"
                    onSelect={(id) => icPaymentForm.setFieldValue("icId", id)}
                    options={icOptions}
                  // className="mr-4 flex-col"
                  />
                </div>
                <div className="flex flex-col">
                  <Input
                    {...icPaymentForm.getFieldProps("transactionNumber")}
                    formik={icPaymentForm}
                    id="transactionNumber"
                    name="transactionNumber"
                    type="text"
                    label="Transaction Number"
                    placeholder="Transaction Number"
                  />
                </div>
                <div className="flex flex-col">
                  <Input
                    {...icPaymentForm.getFieldProps("ifscCode")}
                    formik={icPaymentForm}
                    id="ifscCode"
                    name="ifscCode"
                    type="text"
                    label="IFSC Code"
                    placeholder="IFSC Code"
                  />
                </div>
                <div className="flex flex-col">
                  <Input
                    {...icPaymentForm.getFieldProps("bank")}
                    formik={icPaymentForm}
                    id="bank"
                    name="bank"
                    type="text"
                    label="Bank"
                    placeholder="Bank"
                  />
                </div>
                <div className="flex flex-col">
                  <Input
                    {...icPaymentForm.getFieldProps("accountNumber")}
                    formik={icPaymentForm}
                    id="accountNumber"
                    name="accountNumber"
                    type="text"
                    label="Account Number"
                    placeholder="Account Number"
                  />
                </div>
                <div className="flex flex-col">
                  <Input
                    {...icPaymentForm.getFieldProps("amount")}
                    formik={icPaymentForm}
                    id="amount"
                    name="amount"
                    type="text"
                    label="Amount"
                    placeholder="Amount"
                  />
                </div>
                <div className="flex flex-col">
                  <Input
                    {...icPaymentForm.getFieldProps("paymentDate")}
                    formik={icPaymentForm}
                    id="paymentDate"
                    name="paymentDate"
                    type="date"
                    label="Payment Date"
                    placeholder="Payment Date"
                  />
                </div>
              </div>

              <div className="flex items-center justify-center gap-4 mt-4 px-12 pb-8">
                <Button
                  type="button"
                  label="Cancel"
                  variant="ghost"
                  className="max-w-12"
                  onClick={() => setShowPaymentDialog(false)}
                />
                <Button
                  type="submit"
                  label="Submit"
                  variant="primary"
                  className="max-w-16"
                />
              </div>
            </form>
          </DialogPanel>
        </div>
      </Dialog>
      <Dialog
        open={showDateRangeModal}
        onClose={() => setShowDateRangeModal(false)}
        className="relative z-50"
      >
        <div className="fixed inset-0 bg-black/75" aria-hidden="true" />

        <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
          <DialogPanel className=" space-y-4 border bg-white border-neutral rounded">
            <form onSubmit={dateFilterForm.handleSubmit}>
              <div className="bg-neutral-lightest  border-b border-neutral px-6 py-3 flex">
                <span className="font-bold text-xl py-2 text-neutral-darkest ">
                  Custom Date
                </span>
              </div>
              <div className="flex flex-col md:flex-row md:gap-x-4 px-4 md:px-8 pt-4">
                <div className="flex flex-col mb-4 md:mb-0">
                  <Input
                    {...dateFilterForm.getFieldProps("fromDate")}
                    formik={dateFilterForm}
                    id="fromDate"
                    name="fromDate"
                    type="date"
                    max={dateFilterForm.getFieldProps("toDate").value}
                    label="From Date"
                    className="w-full md:w-60"
                  />
                </div>
                <div className="flex flex-col">
                  <Input
                    {...dateFilterForm.getFieldProps("toDate")}
                    formik={dateFilterForm}
                    id="toDate"
                    name="toDate"
                    type="date"
                    min={dateFilterForm.getFieldProps("fromDate").value}
                    label="To Date"
                    className="w-full md:w-60"
                  />
                </div>
              </div>

              <div className="flex items-center justify-center gap-4 mt-4 px-12 pb-8">
                <Button
                  type="button"
                  label="Cancel"
                  variant="ghost"
                  className="w-full md:w-auto"
                  onClick={() => setShowDateRangeModal(false)}
                />
                <Button
                  type="submit"
                  label="Submit"
                  variant="primary"
                  className="w-full md:w-auto"
                />
              </div>
            </form>
          </DialogPanel>
        </div>
      </Dialog>
    </>
  );
};
