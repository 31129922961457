import React, { useState } from "react";
import coverImage from "../../../assets/img/coverImage.png";
import Input from "../../../components/ui/Input";

import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
  SortingState,
  getSortedRowModel,
  getPaginationRowModel,
} from "@tanstack/react-table";



const dummyData = [
  {
    areaManager: "Amit Kumar",
    ftd: 477,
    mtd: 1556,
    ytd: 53356
  },
  {
    areaManager: "Neha Sharma",
    ftd: 520,
    mtd: 1670,
    ytd: 54000
  },
  {
    areaManager: "Rajesh Singh",
    ftd: 450,
    mtd: 1450,
    ytd: 51000
  },
  {
    areaManager: "Priya Patel",
    ftd: 490,
    mtd: 1600,
    ytd: 52500
  },
  {
    areaManager: "Anjali Mehta",
    ftd: 530,
    mtd: 1720,
    ytd: 55000
  },
  {
    areaManager: "Ravi Verma",
    ftd: 460,
    mtd: 1500,
    ytd: 52000
  },
  {
    areaManager: "Suman Reddy",
    ftd: 480,
    mtd: 1580,
    ytd: 53500
  },
  {
    areaManager: "Vikas Gupta",
    ftd: 510,
    mtd: 1650,
    ytd: 54500
  },
  {
    areaManager: "Kiran Nair",
    ftd: 470,
    mtd: 1520,
    ytd: 53000
  }
];

export const TotalRsaReport = () => {
  const [data, setData] = useState(dummyData);
  const [totalCount, setTotalCount] = useState(dummyData.length);
  const [payload, setPayload] = useState({
    pagination: {
      pageNumber: 1,
      pageSize: 5,
    },
  });
  const [noRecordFound, setNoRecordFound] = useState(false);

  const columnHelper = createColumnHelper();

  const columns = [
    { accessorKey: "srNo", header: "#" },
    { accessorKey: "areaManager", header: "Area Manager" },
    { accessorKey: "ftd", header: "FTD" },
    { accessorKey: "mtd", header: "MTD" },
    { accessorKey: "ytd", header: "YTD" },
  ];


  const table = useReactTable({
    data: data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    manualPagination: true,
  });

  const totalPages = Math.ceil(totalCount / payload.pagination.pageSize);
  const isLastPage = payload.pagination.pageNumber === totalPages;
  const isFirstPage = payload.pagination.pageNumber === 1;

  const handleNextPage = () => {
    setPayload((prev) => {
      const nextPage = prev.pagination.pageNumber + 1;
      return {
        ...prev,
        pagination: {
          ...prev.pagination,
          pageNumber: nextPage > totalPages ? totalPages : nextPage,
        },
      };
    });
  };

  const handleLastPage = () => {
    setPayload((prev) => ({
      ...prev,
      pagination: {
        ...prev.pagination,
        pageNumber: totalPages,
      },
    }));
  };
  const handlePreviousPage = () => {
    setPayload((prev) => {
      const prevPage = prev.pagination.pageNumber - 1;
      return {
        ...prev,
        pagination: {
          ...prev.pagination,
          pageNumber: prevPage < 1 ? 1 : prevPage,
        },
      };
    });
  };

  const handleFirstPage = () => {
    setPayload((prev) => ({
      ...prev,
      pagination: {
        ...prev.pagination,
        pageNumber: 1,
      },
    }));
  };

  return (
    <>
      <div className="flex flex-col w-full items-center overflow-hidden">
        <div className="sticky -z-10 w-full">
          <img
            src={coverImage}
            className=" w-full h-36 object-cover"
            alt="cover_image"
          />
        </div>
        <div className="w-11/12 bg-white -mt-28 md:-mt-20 border border-neutral-light rounded mx-2">
          <form>
            <div>
              <div className="bg-base-white px-6 py-4 border-b border-neutral-light rounded-t">
                <h3 className="text-xl font-semibold">Total RSA Report</h3>
                <p className="text-neutral-darker mt-1 text-sm">
                  A brief snapshot of your financial activity, showing incoming
                  and outgoing transactions
                </p>
              </div>
              <div className="px-6 py-4 pb-8">
                <div className="flex justify-end mb-4 space-x-4">
                  <button
                    type="button"
                    className="mt-1"
                  >
                    <span class="material-symbols-outlined text-neutral-darkest">
                      download
                    </span>
                  </button>
                  <input
                    type="date"
                    className="px-3 py-1 placeholder-neutral-dark border border-neutral-dark rounded-sm min-w-60 focus:outline-none focus:border focus:border-primary"
                  />
                </div>
                <div className="flex mb-4 overflow-x-scroll cursor-pointer">
                  <table className="text-sm border border-neutral w-full">
                    <thead className="bg-primary text-white text-xs">
                      {table.getHeaderGroups().map((headerGroup) => (
                        <tr key={headerGroup.id}>
                          {headerGroup.headers.map((header) => {
                            return (
                              <th
                                key={header.id}
                                colSpan={header.colSpan}
                                className="px-2 py-1 text-left"
                              >
                                {header.isPlaceholder ? null : (
                                  <div
                                    className={
                                      header.column.getCanSort()
                                        ? "cursor-pointer select-none"
                                        : ""
                                    }
                                    onClick={header.column.getToggleSortingHandler()}
                                    title={
                                      header.column.getCanSort()
                                        ? header.column.getNextSortingOrder() ===
                                          "asc"
                                          ? "Sort ascending"
                                          : header.column.getNextSortingOrder() ===
                                            "desc"
                                            ? "Sort descending"
                                            : "Clear sort"
                                        : undefined
                                    }
                                  >
                                    {flexRender(
                                      header.column.columnDef.header,
                                      header.getContext()
                                    )}
                                    {{
                                      asc: " 🔼",
                                      desc: " 🔽",
                                    }[header.column.getIsSorted()] ?? null}
                                  </div>
                                )}
                              </th>
                            );
                          })}
                        </tr>
                      ))}
                    </thead>
                    {/* <tbody>
                                  {table.getRowModel().rows.map((row) => {
                                    return (
                                      <tr
                                        key={row.id}
                                        className="text-xs even:bg-primary-lightest"
                                      >
                                        {row.getVisibleCells().map((cell) => {
                                          return (
                                            <td key={cell.id} className="px-2 py-1">
                                              {" "}
                                              
                                              {flexRender(
                                                cell.column.columnDef.cell,
                                                cell.getContext()
                                              )}
                                            </td>
                                          );
                                        })}
                                      </tr>
                                    );
                                  })}
                                </tbody> */}
                    <tbody>
                      {noRecordFound ? (
                        <tr className="min-h-20">
                          <td
                            colSpan={columns.length}
                            className="text-center p-4 min-h-20"
                          >
                            No records found
                          </td>
                        </tr>
                      ) : (
                        table.getRowModel().rows.map((row) => (
                          <tr
                            key={row.id}
                            className="text-xs even:bg-primary-lightest"
                          >
                            {row.getVisibleCells().map((cell) => (
                              <td key={cell.id} className="px-2 py-1">
                                {flexRender(
                                  cell.column.columnDef.cell,
                                  cell.getContext()
                                )}
                              </td>
                            ))}
                          </tr>
                        ))
                      )}
                    </tbody>
                  </table>
                </div>

                <div className="flex flex-col md:flex-row items-center w-full justify-end text-xs gap-4">
                  <div className="flex items-center gap-2">
                    {" "}
                    <select
                      value={payload.pageSize}
                      onChange={(e) => {
                        const size = Number(e.target.value);
                        setPayload((prev) => ({
                          ...prev,
                          pagination: {
                            pageSize: size,
                            pageNumber: 1,
                          },
                        }));
                      }}
                    >
                      {[5, 10, 15, 20, 25, 30, 35, 40, 45, 50].map((pageSize) => (
                        <option key={pageSize} value={pageSize}>
                          {pageSize}
                        </option>
                      ))}
                    </select>
                    <span className="font-semibold">
                      <span>Showing </span>
                      {1 +
                        (payload.pagination.pageNumber - 1) *
                        payload.pagination.pageSize}{" "}
                      to{" "}
                      {payload.pagination.pageNumber *
                        payload.pagination.pageSize -
                        payload.pagination.pageSize +
                        data?.length}{" "}
                      of {totalCount} Entries
                    </span>
                  </div>
                  {/* <button type="button" disabled={isFirstPage} onClick={handleFirstPage}>
                                <span className="material-symbols-outlined">
                                  first_page
                                </span>
                              </button> */}
                  <div className="flex items-center space-x-2 mt-2 md:mt-0">
                    <button
                      type="button"
                      disabled={isFirstPage}
                      onClick={handleFirstPage}
                      className={`material-symbols-outlined ${isFirstPage
                          ? "cursor-not-allowed text-neutral-light"
                          : "cursor-pointer"
                        }`}
                    >
                      first_page
                    </button>
                    {/* <button type="button" onClick={handlePreviousPage} disabled={isFirstPage}>
                                <span class="material-symbols-outlined">
                                  chevron_left
                                </span>
                              </button> */}
                    <button
                      type="button"
                      onClick={handlePreviousPage}
                      disabled={isFirstPage}
                      className={`material-symbols-outlined ${isFirstPage
                          ? "cursor-not-allowed text-neutral-light"
                          : "cursor-pointer"
                        }`}
                    >
                      chevron_left
                    </button>
                    {/* <button type="button" onClick={handleNextPage} disabled={isLastPage}>
                                <span class="material-symbols-outlined">
                                  chevron_right
                                </span>
                              </button> */}
                    <button
                      type="button"
                      onClick={handleNextPage}
                      disabled={isLastPage}
                      className={`material-symbols-outlined ${isLastPage
                          ? "cursor-not-allowed text-neutral-light"
                          : "cursor-pointer"
                        }`}
                    >
                      chevron_right
                    </button>
                    {/* <button type="button" className="disabled:text-neutral-light" onClick={handleLastPage} disabled={isLastPage}>
                                <span className="material-symbols-outlined text-inherit">
                                  last_page
                                </span>
                              </button> */}
                    <button
                      type="button"
                      className={`material-symbols-outlined ${isLastPage
                          ? "cursor-not-allowed text-neutral-light"
                          : "cursor-pointer"
                        }`}
                      onClick={handleLastPage}
                      disabled={isLastPage}
                    >
                      last_page
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
