import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const Select = ({
  id,
  name,
  formik,
  label,
  placeholder,
  className,
  options,
  onSelect,
  value,
}) => {
  const [selectedOption, setSelectedOption] = useState("");
  const handleSelectChange = (event) => {
    const value = event.target.value;
    setSelectedOption(value);
    onSelect(value);
  };

 // console.log(value);
  const classes = classNames(
    "px-3 py-1.5 placeholder-neutral-dark border border-neutral-dark rounded-sm w-60 focus:outline-none focus:border focus:border-primary",
    className
  );

  return (
    <div className="flex flex-col">
      <label htmlFor={id} className="w-60 text-sm text-neutral-darker mb-0.5">
        {label}
      </label>
      {/* {console.log(value)} */}
      <select
        id={id}
        name={name}
        value={selectedOption || value || ""}
        onChange={handleSelectChange}
        onBlur={formik.handleBlur}

        className={classes}
      >
        <option value=''>
          {placeholder}
        </option>
        {options.map(
          (
            option,
            index // Added index as key
          ) => (
            <option
              key={index} // Use index as key
              value={option.id}
              className="text-black"
              selected={selectedOption}
            >
              {option.label}
            </option>
          )
        )}
      </select>
      {formik &&
        formik.touched &&
        formik.touched[name] &&
        formik.errors &&
        formik.errors[name] && (
          <div className="text-danger text-xs mt-1">{formik.errors[name]}</div>
        )}
    </div>
  );
};

Select.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  formik: PropTypes.object, // PropType for formik object
};

Select.defaultProps = {
  placeholder: "Select your option",
};

export default Select;
