import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const Radio = ({
  id,
  name,
  type,
  value,
  formik,
  label,
  placeholder,
  className,
  onChange,
  onFocus,
}) => {
  const handleChange = (event) => {
    if (onChange) {
      onChange(event);
    } else {
      formik.handleChange(event);
    }
  };

  const classes = classNames(
    "mr-1 py-1",
    className
  );
  
  return (
    <div className="flex flex-col pr-4">
      <div className="flex">
        
        <input
          id={id}
          name={name}
          className={classes}
          type='radio'
          value={value}
          checked={formik.values[name] === value}
          onChange={handleChange}
          onFocus={onFocus}
        onBlur={formik.handleBlur}

        />
        <label for={id} className=" text-neutral-darker">
          {label}
        </label>
      </div>
      <div className="text-danger text-xs mt-1">
        {formik.touched[name] && formik.errors[name] ? (
          <div>{formik.errors[name]}</div>
        ) : null}
      </div>
    </div>
  );
};

Radio.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func,
};

export default Radio;
