import React, { useEffect, useState } from "react";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import { Link, useLocation } from "react-router-dom";
import { isMobile } from "react-device-detect";
import {apiService} from "../api.service"

export default function Sidebar({ opened }) {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [selectedNavItem, setSelectedNavItem] = useState(null);
  const [navItems, setNavItems] = useState([]);
  const location = useLocation();

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  useEffect(() => {
    const currentPath = location.pathname;
    // const currentItem = navItems.find(item => item.path === currentPath);
    const currentItem = Array.isArray(navItems) ? navItems.find(item => item.path === currentPath) : null;
    setSelectedNavItem(currentItem ? currentItem.id : null);
  }, [location]);

  useEffect(() => {
    const fetchNavItems = async () => {
      try {
        const response = await apiService.sidebar();
        console.log("API Response:", response);
        setNavItems(response.data.data || []);
        console.log(navItems);
      } catch (error) {
        console.error("Failed to fetch navigation items:", error);
    }
    };

    fetchNavItems();
  }, []);

  const showSidebar = () => {
    const currentPath = location.pathname;
    if (
      currentPath === "/upload-dealer-documents" ||
      currentPath === "/dealer-registration" ||
      currentPath === "/dealer-registration-status"||
      currentPath.startsWith("/service/generate-policy/")||
      currentPath.startsWith("/sales/generate-policy/")
    ) {
      return false;
    }
    if (windowWidth > 768) {
      // Show sidebar for web view
      return true;
    } else {
      // Show sidebar for mobile view based on the 'opened' prop
      return opened;
    }
  };

  return (
    <>
      {showSidebar() && (
        <aside className="bg-primary text-white fixed md:sticky left-0 top-12 h-[calc(100vh-48px)] md:min-w-14">
          
          <ul className="flex flex-col items-start md:items-center">
          {Array.isArray(navItems) && navItems.map((navItem) => (
              <li
                className={`hover:bg-primary-darkest focus-within:bg-secondary w-full ${
                  selectedNavItem === navItem.id ? "bg-secondary" : ""
                }`}
                key={navItem.id}
                onClick={() => setSelectedNavItem(navItem.id)}
              >
                <Tippy
                  content={navItem.label}
                  placement="right"
                  arrow={false}
                  className="rounded-sm text-xs"
                >
                  <Link to={navItem.path}>
                    <button className="flex w-full py-2 px-4 items-center justify-start md:justify-center">
                      <span className="material-symbols-outlined mr-3 md:mr-0">
                        {navItem.icon}
                      </span>
                      <span className="md:hidden text-white text-sm">
                        {navItem.label}
                      </span>
                    </button>
                  </Link>
                </Tippy>
              </li>
            ))}
          </ul>
        </aside>
      )}
    </>
  );
}
