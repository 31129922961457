import axios from "axios";
import * as helper from "./helper";
import { toast } from "react-hot-toast";
import { async } from "q";
import { service } from "./service";
// import { useNavigate } from "react-router-dom";

// const navigate = useNavigate()

const api = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
  //baseURL: process.env.REACT_APP_API_URL || 'http://localhost:3000',
});
api.interceptors.request.use(
  async (config) => {
    const accessToken = service.getSessionStorageItem("accessToken");

    if (accessToken) {
      console.log(accessToken);
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    console.log(config);
    return config;
  },
  (error) => Promise.reject(error)
);

api.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    console.log("int Err : ", error);

    if (error.code === "ERR_NETWORK") {
      window.location.href = `${process.env.REACT_APP_BASE_URL}/server-down`;
      return Promise.reject(error);
    }

    const originalRequest = error.config;
    if (
      error.response &&
      error.response.status === 401 &&
      error.response.data &&
      error.response.data.message === "Unauthorized - Refresh token expired"
    ) {
      // If refresh token is expired
      toast.error((t) => (
        <span>
          Your session has been expired. Please login! &nbsp;
          <button
            className="underline text-primary text-right"
            onClick={() =>
              window.location.replace(`${process.env.REACT_APP_BASE_URL}/login`)
            }
          >
            Goto Login
          </button>
        </span>
      ));
      return Promise.reject(error);
    }

    // Check if the error is due to an expired token
    if (
      error.response &&
      error.response.status === 401 &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;

      // Refresh the access token using your refresh token
      const refreshToken = service.getSessionStorageItem("refreshToken");
      if (refreshToken) {
        try {
          const newAccessToken = await api.post("/refresh-token", {
            refreshToken,
          });

          localStorage.setItem("accessToken", newAccessToken.data.accessToken);

          // Retry the original request with the new access token
          originalRequest.headers.authorization = `Bearer ${newAccessToken.data.accessToken}`;
          return api(originalRequest);
        } catch (refreshError) {
          console.error("Failed to refresh token:", refreshError);
        }
      }
    }

    return Promise.reject(error);
  }
);

export const apiService = {
  updateQRReport: async (payload) => {
    return await api.post("/updateQRReport", payload);
  },

  makePayment: async () => {
    return await api.post("/generateChecksum");
  },

  requestPolicyCancellation: async (payload) => {
    const config = { headers: { "content-type": "multipart/form-data" } };
    return await api.post("/requestPolicyCancellation", payload, config);
  },

  downloadExcel: async (payload) => {
    return await api.post("/downloadExcel", payload, {
      responseType: "blob", // Important to handle the file download
    });
  },

  getPolicySummary: async (policyNumber) => {
    console.log(policyNumber);
    return await api.get(`/policySummary/${policyNumber}`);
  },
  getDealerInfo: async () => {
    return await api.get("/dealerInfo");
  },
  getRoles: async () => {
    return await api.get("/roles");
  },

  getCompanyTypes: async () => {
    return await api.get("/companyTypes");
  },

  getAccountTypes: async () => {
    return await api.get("/accountTypes");
  },

  getSalutationOptions: async () => {
    return await api.get("/salutations");
  },

  getPolicyHolderTypes: async () => {
    return await api.get("/policyHolderTypes");
  },

  getBankNames: async () => {
    return await api.get("/bankNames");
  },

  getIcs: async () => {
    return await api.get("/ics");
  },

  location: async (payload) => {
    return await api.post("/location", { pincode: payload });
  },
  ifscCode: async (ifsc) => {
    return await api.get(`/ifscCode/${ifsc}`);
  },
  gender: async () => {
    return await api.get("/gender");
  },
  vehicleTypes: async () => {
    return await api.get("/vehicleTypes");
  },
  getMakes: async () => {
    return await api.get("/getMakes");
  },
  getModels: async (payload) => {
    return await api.post("/getModels", payload);
  },
  generatePolicy: async (payload) => {
    return await api.post("/generatePolicy", { ...payload });
  },
  //Login Page
  login: async (request) => {
    return await api.post("/login", request);
  },

  logout: async () => {
    return await api.post("/logout");
  },

  refreshAccessToken: async (refreshToken) => {
    const request = {
      refreshToken,
    };
    const response = await api.post("/refresh-token", request);
    const newAccessToken = response.data.data.accessToken;
    localStorage.setItem("accessToken", newAccessToken);

    return newAccessToken;
  },

  // Generate Policy Page
  getPlans: async (payload) => {
    return await api.post("/getPlans", payload);
  },

  policyData: async (payload) => {
    return await api.post("/policyData", payload);
  },

  search: async (payload) => {
    return await api.post("/search", payload);
  },

  uploadDealerDocuments: async (payload) => {
    const config = { headers: { "content-type": "multipart/form-data" } };
    return await api.post("/uploadDealerDocuments", payload, config);
  },
  bankTransaction: async (payload) => {
    return await api.post("/bankTransaction", payload);
  },

  registerDealer: async (payload) => {
    return await api.post("/registerDealer", payload);
  },

  getFile: async () => {
    return await api.get("getFile");
  },

  downloadPolicyPdf: async (policyNumber) => {
    return await api.get(`/downloadPolicy/${policyNumber}`);
  },

  sidebar: async () => {
    return await api.get("/sidebar");
  },

  bankTransactionRequests: async (payload) => {
    return await api.post("/bankTransactionRequests", payload);
  },
  relationships: async () => {
    return await api.get("/relationships");
  },
  invoiceSummary: async (payload) => {
    return await api.post("/invoiceSummary", payload);
  },
  invoice: async (payload) => {
    return await api.post("/invoice", payload);
  },
  generateInvoice: async (payload) => {
    return await api.post("/generateInvoice", payload);
  },
  gstTransactions: async (payload) => {
    return await api.post("/gstTransactions", payload);
  },
  uploadGSTFile: async (payload) => {
    const config = { headers: { "content-type": "multipart/form-data" } };
    return await api.post("/uploadGSTFile", payload, config);
  },
  transactionReport: async (payload) => {
    return await api.post("/transactionReport", payload);
  },
  qrReport: async () => {
    return await api.post("/qrReport");
  },

  // ADMIN
  soldPolicies: async (payload) => {
    return await api.post("/admin/soldPolicies", payload);
  },

  rmPolicies: async (payload) => {
    return await api.post("/admin/rmData", payload);
  },

  fetchPincode: async (payload) => {
    return await api.post("/admin/pincode", payload);
  },

  fetchState: async (payload) => {
    return await api.get("/admin/state", payload);
  },

  fetchCity: async (stateId) => {
    return await api.get(`/admin/city/${stateId}`);
  },

  addPincode: async (payload) => {
    return await api.post("/admin/addPincode", payload);
  },

  icPayments: async (payload) => {
    return await api.post("/admin/icPayments", payload);
  },

  addIcPayment: async (payload) => {
    return await api.post("/admin/addICPayment", payload);
  },

  downloadFeedFile: async () => {
    return await api.post("/admin/downloadFeedFile");
  },
  cancelPolicies: async (payload) => {
    return await api.post("/admin/cancelPolicies", payload);
  },
  approvePolicyCancellationRequest: async (payload) => {
    return await api.post("/admin/approvePolicyCancellationRequest", payload);
  },
  rejectPolicyCancellationRequest: async (payload) => {
    return await api.post("/admin/rejectPolicyCancellationRequest", payload);
  },
  dealerTypeWiseTargetVsAchievementReport: async (payload) => {
    return await api.post(
      "/admin/dealerTypeWiseTargetVsAchievementReport",
      payload
    );
  },
  planWiseSalesReport: async (payload) => {
    return await api.post("/admin/planWiseSalesReport", payload);
  },
  categoryWiseDailyReport: async (payload) => {
    return await api.post("/admin/categoryWiseDailyReport", payload);
  },
  planWiseDailyReport: async (payload) => {
    return await api.post("/admin/planWiseDailyReport", payload);
  },
  dailyTargetVsAchievementReport: async (payload) => {
    return await api.post("/admin/dailyTargetVsAchievementReport", payload);
  },
  dealerTypeWiseRSAStatsReport: async (payload) => {
    return await api.post("/admin/dealerTypeWiseRSAStatsReport", payload);
  },
  zoneOptions: async () => {
    return await api.post("/admin/zoneOptions");
  },
  areaOptions: async (payload) => {
    return await api.post("/admin/areaOptions", payload);
  },
  loggedInUserCount: async () => {
    return await api.post("/admin/loggedInUserCount");
  },
  totalSoldPoliciesCount: async (payload) => {
    return await api.post("/admin/totalSoldPoliciesCount", payload);
  },
  activeUsersCount: async () => {
    return await api.post("/admin/activeUsersCount");
  },
  topDealers: async (payload) => {
    return await api.post("/admin/topDealers", payload);
  },
  modelWiseReport: async (payload) => {
    return await api.post("/admin/modelWiseReport", payload);
  },
  channelBasedVolumeReport: async (payload) => {
    return await api.post("/admin/channelBasedVolumeReport", payload);
  },
  serviceTargetVsAchievement: async (payload) => {
    return await api.post("/admin/serviceTargetVsAchievement", payload);
  },
  zoneId: async (payload) => {
    return await api.post("/admin/zoneId", payload);
  },
  renewedPoliciesCount: async (payload)=>{
    return await api.post("/admin/renewedPoliciesCount", payload);
  },
};
