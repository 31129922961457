import React, { useState } from "react";
import tvsBrandLogo from "../assets/img/tvs-brand-logo.png";
import { apiService } from "../api.service";
import { toast } from "react-hot-toast";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate, useLocation } from "react-router-dom";
import { service } from "../service";
import { Popover, Transition } from "@headlessui/react";
import { Fragment } from "react";
import "tippy.js/dist/tippy.css"; // Optional for styling
import Button from "./ui/Button";

import {
  Description,
  Dialog,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";

export default function Header({ toggle }) {
  const navigate = useNavigate();
  const location = useLocation();
  const targetRoute = "/bank-transactions"; // replace with your specific route
  const isTargetRoute = location.pathname === targetRoute;
  // console.log('isTargetRoute', isTargetRoute)
  const [showConfirmLogout, setShowConfirmLogout] = useState(false);
  const [showConfirmLogoutDialog, setShowConfirmLogoutDialog] = useState(false);
  const [showDateRangeModal, setShowDateRangeModal] = useState(false);
  const [payload, setPayload] = useState({
    // pagination: {
    //   pageNumber: 1,
    //   pageSize: 5,
    // },
    // search: "",
  });

  const dealerName = service.getSessionStorageItem("dealerInfo")?.dealerName;

  const logout = () => {
    apiService
      .logout()
      .then((response) => {
        localStorage.clear();
        navigate("/login");
      })
      .catch((error) => {
        console.error(error);
        toast.error(error.response.data.message);
      });
  };
  const dateFilterForm = useFormik({
    initialValues: {
      fromDate: "",
      toDate: "",
    },
    validationSchema: Yup.object().shape({
      fromDate: Yup.date().required("Required"),
      toDate: Yup.date().required("Required"),
    }),
    onSubmit: async (values) => {
      console.log(values);
      setPayload((prev) => ({
        ...prev,
        fromDate: values.fromDate,
        toDate: values.toDate,
      }));
      console.log(payload);
      setShowDateRangeModal(false);
    },
  });

  const confirmLogout = () => {
    setShowConfirmLogout(true);
  };

  const cancelLogout = () => {
    setShowConfirmLogout(false);
  };

  const showMenuButton = () => {
    const currentPath = location.pathname;
    if (
      currentPath.startsWith("/service/generate-policy/") ||
      currentPath.startsWith("/sales/generate-policy/")
    ) {
      return false;
    }
    return true;
  };
  return (
    <>
      <header className="bg-white flex justify-between items-center sticky top-0 w-full h-12 px-6 border-b border-neutral-light z-50">
        <div className="flex">
          {showMenuButton() && (
            <span
              onClick={toggle}
              className="material-symbols-outlined md:hidden mr-4"
            >
              menu
            </span>
          )}

          <img src={tvsBrandLogo} alt="tvs-brand-logo" className="w-32" />
        </div>
        {isTargetRoute && (
          <div className="absolute top-25 right-5 ">

            <button
              type="button"
              className="mt-1 "
              onClick={() => {
                setShowDateRangeModal(true);
              }}
            >
              <span className=" material-symbols-outlined text-neutral-darkest justify-end block md:hidden">
                info
              </span>
            </button>
          </div>
        )}
        <Dialog
          open={showDateRangeModal}
          onClose={() => setShowDateRangeModal(false)}
          className="relative z-50"
        >
          <div className="fixed inset-0 bg-black/75" aria-hidden="true" />

          <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
            <DialogPanel className="space-y-4 border bg-white border-neutral rounded w-full md:w-1/2 lg:w-1/3 mx-auto">
              <form onSubmit={dateFilterForm.handleSubmit}>

                <div className="w-full">
                  <div className="bg-white border border-neutral-light rounded">
                    <div className="bg-base-white px-6 py-4 border-b border-neutral-light rounded-t">
                      <h3 className="text-xl font-semibold">Indicosmic Bank Details</h3>
                      <p className="text-neutral-darker mt-1 text-sm">
                        You may use these data to conduct bank transactions
                      </p>
                    </div>
                    <div className="px-6 py-4 pb-8 flex justify-center">
                      <table className="text-sm">
                        <tbody>
                          <tr>
                            <td className="w-36">Beneficiary Name</td>
                            <td className="font-medium">INDICOSMIC PVT LTD</td>
                          </tr>
                          <tr>
                            <td className="w-36">Bank Name</td>
                            <td className="font-medium">ICICI Bank</td>
                          </tr>
                          <tr>
                            <td className="w-36">Branch Name</td>
                            <td className="font-medium">MIDC Andheri (E)</td>
                          </tr>
                          <tr>
                            <td className="w-36">Account Number</td>
                            <td className="font-medium">ICCP00011111</td>
                          </tr>
                          <tr>
                            <td className="w-36">IFSC Code</td>
                            <td className="font-medium">ICIC0000104</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>

                </div>
              </form>
            </DialogPanel>

          </div>
        </Dialog>
        <div className="hidden md:flex items-center text-sm">
          <span className="mr-4">{dealerName}</span>
          <Popover className="relative">
            <Popover.Button className="bg-primary-lightest border border-primary rounded-full h-8 w-8 flex items-center justify-center">
              {/* <span className="material-symbols-outlined text-primary">person</span> */}
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute right-0 z-10 mt-2 w-48 bg-white rounded-sm shadow-lg ring-1 ring-black ring-opacity-5 origin-top-right">
                <div className="">
                  <button
                    className="flex items-center space-x-2 px-3 py-1 w-full hover:bg-neutral-lightest hover:text-primary first:pt-2"
                    onClick={() => alert("User Profile")}
                  >
                    <span className="material-symbols-outlined">person</span>
                    <span>My Profile</span>
                  </button>

                  <button
                    className="flex items-center space-x-2 px-3 py-1 w-full hover:bg-neutral-lightest hover:text-primary last:pb-2"
                    onClick={() => setShowConfirmLogoutDialog(true)}
                  >
                    <span className="material-symbols-outlined">logout</span>
                    <span>Logout</span>
                  </button>
                </div>
              </Popover.Panel>
            </Transition>
          </Popover>
        </div>
      </header>

      <Dialog
        open={showConfirmLogoutDialog}
        onClose={() => setShowConfirmLogoutDialog(false)}
        className="relative z-50"
      >
        <div className="fixed inset-0 bg-black/75" aria-hidden="true" />

        <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
          <DialogPanel className=" space-y-4 border bg-white border-neutral rounded">
            <div className="bg-neutral-lightest border-b border-neutral px-6 py-3 flex justify-center text-left">
              <span className="font-bold text-xl w-full">Confirm Logout</span>
            </div>
            <div className="px-20 pb-6">
              <span className="text-center">
                Are you sure you want to logout?
              </span>

              <div className="flex items-center justify-center gap-4 mt-4 ">
                <Button
                  type="button"
                  label="Cancel"
                  variant="ghost"
                  className="max-w-12"
                  onClick={() => setShowConfirmLogoutDialog(false)}
                />
                <Button
                  type="submit"
                  label="Logout"
                  variant="primary"
                  className="max-w-16"
                  onClick={logout}
                />
              </div>
            </div>
          </DialogPanel>
        </div>
      </Dialog>

      {showConfirmLogout && (
        <div className="fixed inset-0 z-50 bg-black bg-opacity-50 pt-20 flex justify-center ">
          <div className="bg-white px-6 py-4 rounded shadow-md mt-4 flex flex-col items-center h-28">
            <p>Are you sure you want to logout?</p>

            <div className="flex justify-end pt-4 pb-8 w-72">
              <Button
                type="button"
                label="Cancel"
                className="w-20"
                onClick={cancelLogout}
                variant="ghost"
              />
              <Button
                type="button"
                label="Logout"
                className="w-20"
                onClick={logout}
                variant="primary"
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
}
