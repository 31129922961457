import React, { useState, useEffect } from "react";
import CurrencyFormat from "react-currency-format";
import coverImage from "../assets/img/coverImage.png";
import Input from "../components/ui/Input";
import Button from "../components/ui/Button";
import toast, { Toaster } from "react-hot-toast";
import { useFormik } from "formik";
import * as Yup from "yup";
import Select from "../components/ui/Select";
import File from "../components/ui/File";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
//import { useHistory } from 'react-router-dom';
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { apiService } from "../api.service";
import Radio from "../components/ui/Radio";
import { service } from "../service";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import moment from "moment";

export const GeneratePolicy = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { dealerId } = useParams();
  const tvsTypeId = location.pathname === "/sales/generate-policy" ? 1 : 2;
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  // const [plans, setPlans] = useState([]);
  const [planData, setPlanData] = useState([]);
  const [genderOptions, setGenderOptions] = useState([]);
  const [makeOptions, setMakesOptions] = useState([]);
  const [vehicleTypeOptions, setVehicleTypeOptions] = useState([]);
  const [modelOptions, setModelOptions] = useState([]);
  const [policyHolderTypeOptions, setPolicyHolderTypeOptions] = useState([]);
  const [salutationOptions, setSalutationOptions] = useState([]);
  const [showNominee, setShowNominee] = useState(false);
  const [relationshipOptions, setRelationshipOptions] = useState([]);
  const [selectedModel, setSelectedModel] = useState(null);
  const [placeholder, setPlaceholder] = useState("MH-01-AB-6831");

  const columnHelper = createColumnHelper();

  const columns = [
    columnHelper.display({
      id: "actions",
      cell: (props) => (
        <input
          type="radio"
          name="id"
          value={props.row.original.id}
          checked={
            generatePolicyForm.getFieldProps("planId").value ===
            props.row.original.id
          }
          onChange={() => onChangePlan(props.row.original.id)}
        />
      ),
    }),
    // columnHelper.accessor("id", {
    //   header: "Plan Id",
    // }),
    columnHelper.accessor("name", {
      header: "Plan Name",
    }),
    columnHelper.accessor("rsaTenure", {
      header: "Rsa Tenure",
    }),
    columnHelper.accessor("rsaCoveredKms", {
      header: "Rsa Covered Kms",
    }),
    columnHelper.accessor("policyPrice", {
      header: "Price",
    }),
  ];

  const table = useReactTable({
    data: planData,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });
  const searchValidationSchema = Yup.object().shape({
    searchValue: Yup.string().required("Required"),
    searchField: Yup.string().required("Required"),
  });
  const rsaCareFormValidationSchema = Yup.object().shape({
    planId: Yup.number().required("Required"),
    engineNumber: Yup.string()
      .matches(/^[A-Z0-9]{10,17}$/, "Invalid")
      .required("Required"),
    chassisNumber: Yup.string()
      .matches(/^[A-HJ-NPR-Z0-9]{17}$/, "Invalid")
      .required("Required"),
    vehicleType: Yup.string().required("Required"),
    manufacturer: Yup.string().required("Required"),
    model: Yup.string().required("Required"),
    seriesType: Yup.string().required("Please select a series type"),
    registrationNumber: Yup.string()
      .transform((value, originalValue) => {
        if (!originalValue) return value; // If the field is empty, return the value as is
        // Convert to uppercase and remove non-alphanumeric characters
        value = originalValue.toUpperCase().replace(/[^\w\s]/gi, "");
        // Define the regular expression pattern based on the series type
        const pattern = originalValue.startsWith("BH")
          ? /^(.{2})(.{2})(\d{4})(.{2})$/ // BH Series format
          : /^(.{2})(\d{2})(.{2})(\d{4})$/; // Normal Series format
        // Add dashes in the appropriate positions
        return value.replace(pattern, "$1-$2-$3-$4");
      })
      .when("seriesType", {
        is: (value) => value === "bh", // Change 'new' to whatever value represents the BH Series
        then: () =>
          Yup.string().matches(
            /^(?:\d{2})-BH-\d{4}-[A-Z]{2}$/,
            "Incorrect format for BH Series"
          ),
        otherwise: () =>
          Yup.string().matches(
            /^[A-Z]{2}-\d{2}-[A-Z]{2}-\d{4}$/,
            "Incorrect format for Normal Series"
          ),
      })
      .when("vehicleType", {
        is: (value) => value == 2, // New
        then: () => Yup.string().required("Required for new Vehicle"),
        otherwise: () => Yup.string(),
      }),

    //registrationNumber: Yup.string().required('Required'),
    policyHolderType: Yup.string().required("Required"),
    salutation: Yup.string().required("Required"),
    firstName: Yup.string()
      .required("Required")
      .min(2, "Too Short!")
      .required("Required")
      .matches(/^[a-zA-Z]+$/, "First name should contain only letters"),
    middleName: Yup.string()
      .min(2, "Too Short!")
      // .required("Required")
      .matches(/^[a-zA-Z]+$/, "Middle name should contain only letters"),
    lastName: Yup.string()
      .required("Required")
      .min(2, "Too Short!")
      .required("Required")
      .matches(/^[a-zA-Z]+$/, "Last name should contain only letters"),
    email: Yup.string()
      .email("Invalid email")
      .required("Required")
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        "Invalid email format"
      ),
    mobileNumber: Yup.number()
      .required("Required")
      .typeError("Mobile number must be a valid number")
      .required("Mobile number is required")
      .positive("Mobile number must be a positive number")
      .integer("Mobile number must be an integer")
      .min(1000000000, "Mobile number must have at least 10 digits")
      .max(9999999999, "Mobile number must have at most 10 digits"),
    gender: Yup.string().required("Required"),
    dateOfBirth: Yup.string().required("Required"),
    addressLine1: Yup.string().required("Required"),
    addressLine2: Yup.string().required("Required"),
    pincode: Yup.string().required("Required"),
    city: Yup.string().required("Required"),
    state: Yup.string().required("Required"),
    nomineeFullName: Yup.string(),
    age: Yup.string(),
    relationship: Yup.string(),
  });

  const searchForm = useFormik({
    initialValues: {
      searchField: "engine_no",
      searchValue: "",
    },
    // validationSchema: searchValidationSchema,
    onSubmit: async (values) => {
      generatePolicyForm.resetForm();
      console.log(values);
      // navigate("/bank-statement");

      const payload = {
        engineNumber: values.searchValue.toUpperCase(),
        tvsTypeId: tvsTypeId,
      };
      try {
        const result = (await apiService.search(payload)).data.data;

        console.log(result);
        generatePolicyForm.setFieldValue("engineNumber", result.engineNumber);
        generatePolicyForm.setFieldValue("chassisNumber", result.chassisNumber);
        generatePolicyForm.setFieldValue("vehicleType", result.vehicleType);
        generatePolicyForm.setFieldValue("manufacturer", result.manufacturer);
        generatePolicyForm.setFieldValue("model", result.model);
        generatePolicyForm.setFieldValue(
          "seriesType",
          result.registrationSeriesTypeId.toString()
        );
        generatePolicyForm.setFieldValue(
          "registrationNumber",
          result.registrationNumber
        );
        generatePolicyForm.setFieldValue(
          "policyHolderType",
          result.policyHolderType
        );
        generatePolicyForm.setFieldValue("salutation", result.salutation);
        generatePolicyForm.setFieldValue("firstName", result.firstName);
        generatePolicyForm.setFieldValue("middleName", result.middleName);
        generatePolicyForm.setFieldValue("lastName", result.lastName);
        generatePolicyForm.setFieldValue("email", result.email);
        generatePolicyForm.setFieldValue("mobileNumber", result.mobileNumber);
        generatePolicyForm.setFieldValue("gender", result.gender);
        generatePolicyForm.setFieldValue("dateOfBirth", result.dateOfBirth);
        generatePolicyForm.setFieldValue("addressLine1", result.addressLine1);
        generatePolicyForm.setFieldValue("addressLine2", result.addressLine2);
        generatePolicyForm.setFieldValue("pincode", result.pincode);
        generatePolicyForm.setFieldValue("city", result.city);
        generatePolicyForm.setFieldValue("state", result.state);
        generatePolicyForm.setFieldValue(
          "nomineeFullName",
          result.nomineeFullName
        );
        generatePolicyForm.setFieldValue("age", result.age);
        generatePolicyForm.setFieldValue("relationship", result.relationship);

        console.log(generatePolicyForm.values);
      } catch (error) {
        console.error(error);
        toast.error(error.response.data.message);
      }
    },
  });
  const generatePolicyForm = useFormik({
    initialValues: {
      productType: "",
      planId: "",
      engineNumber: "",
      chassisNumber: "",
      vehicleType: "",
      manufacturer: "",
      model: "",
      seriesType: "1",
      registrationNumber: "",
      policyHolderType: "",
      salutation: "",
      firstName: "",
      middleName: "",
      lastName: "",
      email: "",
      mobileNumber: "",
      gender: "",
      dateOfBirth: "",
      addressLine1: "",
      addressLine2: "",
      pincode: "",
      city: "",
      state: "",
      nomineeFullName: "",
      age: "",
      relationship: "",
    },
    validationSchema: rsaCareFormValidationSchema,
    validateOnBlur: true,
    onSubmit: async (values) => {
      // alert(8);
      let payload = {
        dealerId: dealerId,
        tvsTypeId: tvsTypeId,
        planId: values.planId,
        productType: values.productType,
        vehicle: {
          engineNumber: values.engineNumber,
          chassisNumber: values.chassisNumber,
          vehicleType: values.vehicleType,
          manufacturer: values.manufacturer,
          model: values.model,
          registrationNumber: values.registrationNumber,
        },
        owner: {
          salutation: values.salutation,
          firstName: values.firstName,
          middleName: values.middleName,
          lastName: values.lastName,
          email: values.email,
          mobileNumber: values.mobileNumber,
          gender: values.gender,
          dateOfBirth: values.dateOfBirth,
          addressLine1: values.addressLine1,
          addressLine2: values.addressLine2,
          pincode: values.pincode,
          city: values.city,
          state: values.state,
          policyHolderType: values.policyHolderType,
        },
      };

      console.log("sdfs  :P ", values.planId === 30);

      let hasNominee = planData.find(
        (plan) => plan.id === values.planId
      )?.showNominee;
      console.log(hasNominee);
      if (hasNominee) {
        payload = {
          ...payload,
          nominee: {
            nomineeFullName: values.nomineeFullName,
            age: values.age,
            relationship: values.relationship,
          },
        };
      }
      console.log(payload);
      await apiService
        .generatePolicy(payload)
        .then((res) => {
          if (res.data.isPaytmPayment) {
            invokePaytmCheckoutPage(res.data);
          }
          console.log(res.data.data.policyNumber);
          navigate(`/purchase-status/${res.data.data.policyNumber}`);

          toast.success(res.data.message);
          // navigate("/bank-statement");
        })
        .catch((error) => {
          console.log(error.response.data.message);
          toast.error(error.response.data.message);
        });
    },
  });

  const transformRegistrationNumber = (value, seriesType) => {
    if (!value) return value; // If the field is empty, return the value as is
    value = value.toUpperCase().replace(/[^\w\s]/gi, ""); // Convert to uppercase and remove non-alphanumeric characters

    if (seriesType === "bh") {
      // BH Series format
      return value.replace(/^(\w{2})(\w{2})(\d{4})(\w{2})$/, "$1-$2-$3-$4");
    } else {
      // Normal Series format
      return value.replace(/^(\w{2})(\d{2})(\w{2})(\d{4})$/, "$1-$2-$3-$4");
    }
  };

  const formatRegistrationNumber = (seriesType, value) => {
    console.log(moment().format("YY"));
    let cleaned = value.replace(/[^a-zA-Z0-9]/g, "");
    if (seriesType === "1") {
      // Limit the length to 10 characters (for MH01CG1234)
      cleaned = cleaned.substring(0, 10);

      // Add the dashes in the appropriate positions
      let formatted = cleaned;
      if (cleaned.length > 2) {
        formatted = cleaned.slice(0, 2) + "-" + cleaned.slice(2);
      }
      if (cleaned.length > 5) {
        formatted = formatted.slice(0, 5) + "-" + formatted.slice(5);
      }
      if (cleaned.length > 8) {
        formatted = formatted.slice(0, 8) + "-" + formatted.slice(8);
      }
      return formatted.toUpperCase();
    } else {
      // BH series
      let formatted = value.toUpperCase().replace(/[^A-Z0-9]/g, "");

      if (formatted.length > 2) {
        formatted = formatted.slice(0, 2) + "-" + formatted.slice(2);
      }
      if (formatted.length > 5) {
        formatted = formatted.slice(0, 5) + "-" + formatted.slice(5);
      }
      if (formatted.length > 10) {
        formatted = formatted.slice(0, 10) + "-" + formatted.slice(10);
      }

      return formatted.slice(0, 13);
    }
  };

  function isDate(val) {
    // Cross realm comptatible
    return Object.prototype.toString.call(val) === "[object Date]";
  }

  function isObj(val) {
    return typeof val === "object";
  }

  function stringifyValue(val) {
    if (isObj(val) && !isDate(val)) {
      return JSON.stringify(val);
    } else {
      return val;
    }
  }

  function buildForm({ action, params }) {
    const form = document.createElement("form");
    form.setAttribute("method", "post");
    form.setAttribute("action", action);

    Object.keys(params).forEach((key) => {
      const input = document.createElement("input");
      input.setAttribute("type", "hidden");
      input.setAttribute("name", key);
      input.setAttribute("value", stringifyValue(params[key]));
      form.appendChild(input);
    });

    return form;
  }

  function post(details) {
    const form = buildForm(details);
    document.body.appendChild(form);
    form.submit();
    form.remove();
  }

  const invokePaytmCheckoutPage = (data) => {
    let information = {
      action: "https://securegw.paytm.in/order/process",
      params: data,
    };
    console.log(information);
    // return
    post(information);
  };
  const handleSeriesTypeChange = (e) => {
    const selectedType = e.target.value;
    console.log("ser : ", e);
    generatePolicyForm.setFieldValue("seriesType", selectedType);
    if (selectedType === "2") {
      setPlaceholder("21-BH-1234-AB");
    } else {
      setPlaceholder("MH-01-AB-6831");
    }
  };

  const onChangePlan = (planId) => {
    const selectedPlan = planData.find((plan) => plan.id === planId);
    if (selectedPlan) {
      generatePolicyForm.setFieldValue("planId", planId);
      setShowNominee(selectedPlan.showNominee); // Update showNominee state based on selected plan
    }
  };
  const calculateMinDate = () => {
    const date = new Date();
    date.setFullYear(date.getFullYear() - 60);
    return date.toISOString().split("T")[0];
  };

  const calculateMaxDate = () => {
    const date = new Date();
    date.setFullYear(date.getFullYear() - 18);
    return date.toISOString().split("T")[0];
  };
  const getPlans = async (modelId) => {
    try {
      const payload = {
        dmsIcId: 5,
        tvsType: tvsTypeId,
        modelId,
      };

      const plans = await apiService.getPlans(payload);
      console.log(plans.data.data);
      setPlanData(plans.data.data);
    } catch (error) {
      setPlanData([]);

      console.error("Error fetching plans : ", error);
    }
  };

  const onSelectVehicleType = (value) => {
    console.log("onSelectVehicleType : ", value);
    generatePolicyForm.setFieldValue("vehicleType", value);
  };
  const onSelectManufacturerType = (value) => {
    generatePolicyForm.setFieldValue("manufacturer", value);
  };
  const onSelectModel = (id) => {
    console.log(id);
    getPlans(id);
    generatePolicyForm.setFieldValue("model", id);
  };
  const onSelectSalutation = (value) => {
    generatePolicyForm.setFieldValue("salutation", value);
  };
  const onSelectGender = (value) => {
    console.log(value);
    generatePolicyForm.setFieldValue("gender", value);
  };
  const onSelectPolicyHolderType = (value) => {
    generatePolicyForm.setFieldValue("policyHolderType", value);
  };
  const onSelectRelationshipType = (value) => {
    generatePolicyForm.setFieldValue("relationship", value);
  };
  const onSearch = async () => {
    const searchValue = searchForm.getFieldProps("searchValue").value;
    console.log(searchValue);
  };

  const onPincodeChange = (e) => {
    const { value } = e.target;
    if (!isNaN(value) && value.length <= 6) {
      generatePolicyForm.handleChange(e);
      if (value.length === 6) {
        // alert(999)
        apiService
          .location(value)
          .then((response) => {
            generatePolicyForm.setFieldValue("city", response.data.data.city);
            generatePolicyForm.setFieldValue("state", response.data.data.state);
          })
          .catch(() => {
            generatePolicyForm.setFieldValue("city", "");
            generatePolicyForm.setFieldValue("state", "");
          });
      } else {
        generatePolicyForm.setFieldValue("city", "");
        generatePolicyForm.setFieldValue("state", "");
      }
    }
  };

  const getGender = async () => {
    try {
      await apiService.gender().then((res) => {
        setGenderOptions(res.data.data);
      });
    } catch (error) {
      console.error("Error fetching genders:", error);
    }
  };
  const getRelationship = async () => {
    try {
      await apiService.relationships().then((res) => {
        setRelationshipOptions(res.data.data);
      });
    } catch (error) {
      console.error("Error fetching relationships:", error);
    }
  };
  const getMakes = async () => {
    try {
      await apiService.getMakes().then((res) => {
        setMakesOptions(res.data.data);
      });
    } catch (error) {
      console.error("Error fetching getMakes:", error);
    }
  };
  const vehicleTypes = async () => {
    try {
      await apiService.vehicleTypes().then((res) => {
        setVehicleTypeOptions(res.data.data);
      });
    } catch (error) {
      console.error("Error fetching vehicleTypes:", error);
    }
  };

  const getPolicyHolderTypes = async () => {
    try {
      await apiService.getPolicyHolderTypes().then((res) => {
        setPolicyHolderTypeOptions(res.data.data);
      });
    } catch (error) {
      console.error("Error fetching Policy Holder Types:", error);
    }
  };

  const getSalutationOptions = async () => {
    try {
      await apiService.getSalutationOptions().then((res) => {
        setSalutationOptions(res.data.data);
      });
    } catch (error) {
      console.error("Error fetching Salutations :", error);
    }
  };
  const handleInputChange = (event) => {
    const value = event.target.value;
    const seriesType = generatePolicyForm.getFieldProps("seriesType");
    const formattedValue = formatRegistrationNumber(seriesType, value);
    generatePolicyForm.setFieldValue("registrationNumber", formattedValue);
  };

  const getModels = async () => {
    try {
      const payload = {
        makeId: 1,
        tvsType: 1,
      };
      await apiService.getModels(payload).then((res) => {
        setModelOptions(res.data.data);
      });
    } catch (error) {
      console.error("Error fetching models:", error);
      throw error;
    }
  };

  useEffect(() => {
    console.log("dealerId : ", dealerId);
    console.log(
      "s : ",
      !service.getSessionStorageItem("isUpdateQRReportCalled")
    );

    if (dealerId && !service.getSessionStorageItem("isUpdateQRReportCalled")) {
      const payload = {
        dealerId: parseInt(dealerId),
        field: "total_scans",
      };
      apiService.updateQRReport(payload).then(() => {
        service.setSessionStorageItem("isUpdateQRReportCalled", true);
      });
    }
  }, []);

  useEffect(() => {
    getPlans(generatePolicyForm.getFieldProps("model").value);
    getGender();
    getMakes();
    getModels();
    vehicleTypes();
    getSalutationOptions();
    getPolicyHolderTypes();
    getRelationship();
  }, [tvsTypeId]);

  useEffect(() => {
    async function fetchData() {
      try {
        const modelsData = await getModels();
        console.log(modelsData);
        const formattedOptions = modelsData.map((model) => ({
          id: model.id,
          label: model.name,
          value: String(model.id),
        }));
        console.log(formattedOptions);
        setModelOptions(formattedOptions);
      } catch (error) {
        console.error("Error fetching models:", error);
      }
    }

    fetchData();
  }, []);

  useEffect(() => {
    const fetchPlans = async () => {
      await getPlans(generatePolicyForm.getFieldProps("model").value);
    };

    fetchPlans();
  }, [generatePolicyForm.getFieldProps("model").value]);

  return (
    <>
      <div className="flex flex-col w-full items-center">
        <div className="sticky -z-10 top-12 w-full">
          <img
            src={coverImage}
            className=" w-full h-36 object-cover"
            alt="cover_image"
          />
        </div>
        {/* <form onSubmit={formik.handleSubmit}> */}
        <div className="w-11/12 md:w-11/12 min-w-fit bg-white -mt-28 mx-2 md:-mt-20 border border-neutral-light rounded">
          <div className="bg-base-white px-6 py-4 border-b border-neutral-light rounded-t">
            <h3 className="text-xl font-semibold">
              Road Side Assistance (RSA) -
              {props.tvsType == "service" ? " Service" : " Sales"}
            </h3>
            <p className="text-neutral-darker mt-1 text-sm ">
              Whenever a vehicle breaks down, Roadside Assistance is available
              around-the-clock as an emergency service.{" "}
            </p>
          </div>
          <div className="px-6 pb-8">
            <div className="flex text-center justify-center mt-4">
              <div className="w-80">
                {/* <p className="text-sm mb-2">
                  Use the Engine Number of the vehicle to search Customer’s. If
                  its listed in our Database.
                </p> */}
                <form onSubmit={searchForm.handleSubmit}>
                  <div className="flex flex-col items-center md:flex-row md:justify-center">
                    {/* <div className="flex flex-col">
                      {searchOptions.length > 0 && (
                        <Select
                          {...searchForm.getFieldProps("searchField")}
                          formik={searchForm}
                          id="searchField"
                          name="searchField"
                          onSelect={onSelectSearchType}
                          options={searchOptions}
                          className="mr-4 flex-col"
                        />
                      )}
                    </div> */}
                    <div className="flex flex-col">
                      <Input
                        {...searchForm.getFieldProps("searchValue")}
                        formik={searchForm}
                        id="searchValue"
                        name="searchValue"
                        type="text"
                        placeholder="Search Engine Number..."
                        className="w-60 md:w-80 mr-4"
                      />
                    </div>
                    <div className="w-36">
                      <Button
                        type="submit"
                        label="Search"
                        variant="secondary"
                        className="w-36 mt-2 md:mt-0"
                        // onClick={searchForm.resetForm}
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>

            <div>
              <form onSubmit={generatePolicyForm.handleSubmit}>
                <div className="px-4">
                  <h4 className="text-lg font-medium py-2">Vehicle details</h4>
                  <div className="grid grid-cols-1 md:grid-cols-4 gap-y-2 gap-x-4">
                    <div className="flex flex-col">
                      <Input
                        {...generatePolicyForm.getFieldProps("engineNumber")}
                        formik={generatePolicyForm}
                        id="engineNumber"
                        name="engineNumber"
                        type="text"
                        label="Engine number"
                        placeholder="Enter engine number"
                        className="w-full md:w-60"
                        maxLength={17}
                      />
                    </div>
                    <div className="flex flex-col">
                      <Input
                        {...generatePolicyForm.getFieldProps("chassisNumber")}
                        formik={generatePolicyForm}
                        id="chassisNumber"
                        name="chassisNumber"
                        type="text"
                        label="Chassis Number"
                        placeholder="Enter chassis number"
                        className="w-full md:w-60"
                        maxLength={17}
                      />
                    </div>
                    <div className="flex flex-col">
                      <Select
                        {...generatePolicyForm.getFieldProps("vehicleType")}
                        formik={generatePolicyForm}
                        value={generatePolicyForm.values.vehicleType}
                        id="vehicleType"
                        name="vehicleType"
                        label="Vehicle Type"
                        onSelect={(value) => onSelectVehicleType(value)}
                        options={vehicleTypeOptions}
                        className="mr-4 flex-col w-full md:w-60"
                      />
                    </div>
                    <div className="flex flex-col">
                      <Select
                        {...generatePolicyForm.getFieldProps("manufacturer")}
                        formik={generatePolicyForm}
                        value={generatePolicyForm.values.manufacturer}
                        id="manufacturer"
                        name="manufacturer"
                        label="Manufacturer"
                        className="w-full md:w-60"
                        onSelect={(value) => onSelectManufacturerType(value)}
                        options={makeOptions}
                      />
                    </div>
                    <div className="flex flex-col">
                      <Select
                        {...generatePolicyForm.getFieldProps("model")}
                        formik={generatePolicyForm}
                        value={generatePolicyForm.values.model}
                        id="model"
                        name="model"
                        label="Model"
                        onSelect={(value) => onSelectModel(value)}
                        options={modelOptions}
                        className="w-full md:w-60"
                      />
                    </div>
                    <div className="flex flex-col justify-center items-left mt-2 text-left">
                      <label className="text-sm text-left">
                        Registration Series Type
                      </label>
                      <div className="flex items-center">
                        <Radio
                          {...generatePolicyForm.getFieldProps("seriesType")}
                          formik={generatePolicyForm}
                          id="normal"
                          name="seriesType"
                          label="Normal"
                          value="1"
                          onChange={(value) => handleSeriesTypeChange(value)}
                        />
                        <Radio
                          {...generatePolicyForm.getFieldProps("seriesType")}
                          formik={generatePolicyForm}
                          id="bh"
                          name="seriesType"
                          label="BH"
                          value="2"
                          // onChange={(x)=>console.log(x)}
                          onChange={(value) => handleSeriesTypeChange(value)}
                        />
                      </div>
                    </div>
                    <div className="flex flex-col">
                      <Input
                        {...generatePolicyForm.getFieldProps(
                          "registrationNumber"
                        )}
                        formik={generatePolicyForm}
                        id="registrationNumber"
                        name="registrationNumber"
                        type="text"
                        label="Registration number"
                        placeholder={placeholder}
                        value={generatePolicyForm.values.registrationNumber}
                        // onBlur={(e) => {
                        //   const value = e.target.value
                        //     .toUpperCase()
                        //     .replace(/[^A-Z0-9]/g, "")
                        //     .replace(/(.{2})(?=.)/g, "$1-");
                        //   generatePolicyForm.setFieldValue(
                        //     "registrationNumber",
                        //     value
                        //   );
                        // }}
                        onInputChange={handleInputChange}
                        maxLength="13"
                        // onChange={() => transformRegistrationNumber()}
                        //onBlur={rsaCareForm.handleBlur}
                        error={
                          generatePolicyForm.errors.registrationNumber &&
                          generatePolicyForm.touched.registrationNumber &&
                          generatePolicyForm.errors.registrationNumber
                        }
                        className="w-full md:w-60"
                      />
                      {/* <CurrencyFormat /> */}
                    </div>
                  </div>
                  <h4 className="text-lg font-medium py-2">Owner details</h4>
                  <div className="grid grid-cols-1 md:grid-cols-4 gap-y-2 gap-x-4">
                    <div className="flex flex-col">
                      <Select
                        {...generatePolicyForm.getFieldProps(
                          "policyHolderType"
                        )}
                        formik={generatePolicyForm}
                        value={generatePolicyForm.values.policyHolderType}
                        id="policyHolderType"
                        name="policyHolderType"
                        label="Policy Holder Type"
                        onSelect={(value) => onSelectPolicyHolderType(value)}
                        options={policyHolderTypeOptions}
                        className="w-full md:w-60"
                      />
                    </div>
                    <div className="flex flex-col">
                      <Select
                        {...generatePolicyForm.getFieldProps("salutation")}
                        formik={generatePolicyForm}
                        value={generatePolicyForm.values.salutation}
                        id="salutation"
                        name="salutation"
                        label="Salutation"
                        onSelect={(value) => onSelectSalutation(value)}
                        options={salutationOptions}
                        className="w-full md:w-60"
                      />
                    </div>
                    <div className="flex flex-col">
                      <Input
                        {...generatePolicyForm.getFieldProps("firstName")}
                        formik={generatePolicyForm}
                        id="firstName"
                        name="firstName"
                        type="text"
                        label="First Name"
                        placeholder="Enter first name"
                        className="w-full md:w-60"
                      />
                    </div>
                    <div className="flex flex-col">
                      <Input
                        {...generatePolicyForm.getFieldProps("middleName")}
                        formik={generatePolicyForm}
                        id="middleName"
                        name="middleName"
                        type="text"
                        label="Middle Name"
                        placeholder="Enter middle name"
                        className="w-full md:w-60"
                      />
                    </div>
                    <div className="flex flex-col">
                      <Input
                        {...generatePolicyForm.getFieldProps("lastName")}
                        formik={generatePolicyForm}
                        id="lastName"
                        name="lastName"
                        type="text"
                        label="Last Name"
                        placeholder="Enter last name"
                        className="w-full md:w-60"
                      />
                    </div>
                    <div className="flex flex-col">
                      <Input
                        {...generatePolicyForm.getFieldProps("email")}
                        formik={generatePolicyForm}
                        id="email"
                        name="email"
                        type="text"
                        label="Email"
                        placeholder="Enter email"
                        className="w-full md:w-60"
                      />
                    </div>
                    <div className="flex flex-col">
                      <Input
                        {...generatePolicyForm.getFieldProps("mobileNumber")}
                        formik={generatePolicyForm}
                        id="mobileNumber"
                        name="mobileNumber"
                        type="text"
                        label="Mobile number"
                        placeholder="Enter mobile number"
                        className="w-full md:w-60"
                        maxLength={10}
                      />
                    </div>
                    <div className="flex flex-col">
                      {/* {console.log(genderOptions)} */}
                      <Select
                        {...generatePolicyForm.getFieldProps("gender")}
                        formik={generatePolicyForm}
                        value={generatePolicyForm.values.gender}
                        id="gender"
                        name="gender"
                        label="Gender"
                        onSelect={(value) => onSelectGender(value)}
                        options={genderOptions}
                        className="w-full md:w-60"
                      />
                    </div>
                    <div className="flex flex-col">
                      <Input
                        {...generatePolicyForm.getFieldProps("dateOfBirth")}
                        formik={generatePolicyForm}
                        id="dateOfBirth"
                        name="dateOfBirth"
                        type="date"
                        label="Date of birth"
                        min={calculateMinDate()}
                        max={calculateMaxDate()}
                        value={generatePolicyForm.values.dateOfBirth}
                        onChange={generatePolicyForm.handleChange}
                        onBlur={generatePolicyForm.handleBlur}
                        placeholder="Enter date of birth"
                        className="w-full md:w-60"
                      />
                    </div>
                    <div className="flex flex-col">
                      <Input
                        {...generatePolicyForm.getFieldProps("addressLine1")}
                        formik={generatePolicyForm}
                        id="addressLine1"
                        name="addressLine1"
                        type="text"
                        label="Address line 1"
                        placeholder="Enter address line 1"
                        className="w-full md:w-60"
                      />
                    </div>
                    <div className="flex flex-col">
                      <Input
                        {...generatePolicyForm.getFieldProps("addressLine2")}
                        formik={generatePolicyForm}
                        id="addressLine2"
                        name="addressLine2"
                        type="text"
                        label="Address line 2 "
                        placeholder="Enter address line 2"
                        className="w-full md:w-60"
                      />
                    </div>
                    <div className="flex flex-col">
                      <Input
                        {...generatePolicyForm.getFieldProps("pincode")}
                        formik={generatePolicyForm}
                        id="pincode"
                        name="pincode"
                        type="text"
                        label="Pincode"
                        placeholder="Enter pincode"
                        className="w-full md:w-60"
                        // value={rsaCareForm.values.pincode}
                        // onChange={(e) => onPincodeChange(e)}
                        onInputChange={(e) => onPincodeChange(e)}
                      />
                    </div>
                    <div className="flex flex-col">
                      <Input
                        {...generatePolicyForm.getFieldProps("city")}
                        formik={generatePolicyForm}
                        id="city"
                        name="city"
                        type="text"
                        label="City"
                        placeholder="City"
                        className="w-full md:w-60"
                        readOnly
                      />
                    </div>
                    <div className="flex flex-col">
                      <Input
                        {...generatePolicyForm.getFieldProps("state")}
                        formik={generatePolicyForm}
                        id="state"
                        name="state"
                        type="text"
                        label="State"
                        placeholder="State"
                        className="w-full md:w-60"
                        readOnly
                      />
                    </div>
                  </div>
                </div>

                {planData.length > 0 && (
                  <div>
                    <hr className="text-neutral my-4" />
                    <span className="text-xl font-semibold">Select Plan</span>

                    <div className="flex justify-center pb-4">
                      <table className="text-sm border border-neutral w-full mt-2">
                        <thead className="bg-primary text-white">
                          {table.getHeaderGroups().map((headerGroup) => (
                            <tr key={headerGroup.id}>
                              {headerGroup.headers.map((header) => (
                                <td key={header.id} className="px-4 ">
                                  {header.isPlaceholder
                                    ? null
                                    : flexRender(
                                        header.column.columnDef.header,
                                        header.getContext()
                                      )}
                                </td>
                              ))}
                            </tr>
                          ))}
                        </thead>
                        <tbody>
                          {table.getRowModel().rows.map((row) => (
                            <tr
                              key={row.id}
                              className="even:bg-primary-lightest"
                            >
                              {row.getVisibleCells().map((cell) => (
                                <td key={cell.id} className="px-4 ">
                                  {flexRender(
                                    cell.column.columnDef.cell,
                                    cell.getContext()
                                  )}
                                </td>
                              ))}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}
                {showNominee && (
                  <>
                    <h4 className="text-lg font-medium py-2">
                      Nominee details
                    </h4>
                    <div className="grid grid-cols-1 md:grid-cols-4 gap-y-2 gap-x-4">
                      <div className="flex flex-col">
                        <Input
                          {...generatePolicyForm.getFieldProps(
                            "nomineeFullName"
                          )}
                          formik={generatePolicyForm}
                          id="nomineeFullName"
                          name="nomineeFullName"
                          type="text"
                          label="Nominee fullname"
                          placeholder="Enter nominee fullname"
                          className="w-full md:w-60"
                        />
                      </div>
                      <div className="flex flex-col">
                        <Input
                          {...generatePolicyForm.getFieldProps("age")}
                          formik={generatePolicyForm}
                          id="age"
                          name="age"
                          type="text"
                          label="Age"
                          placeholder="Enter age"
                          className="w-full md:w-60"
                        />
                      </div>
                      <div className="flex flex-col">
                        <Select
                          {...generatePolicyForm.getFieldProps("relationship")}
                          formik={generatePolicyForm}
                          id="relationship"
                          name="relationship"
                          label="Relationship"
                          onSelect={(value) => onSelectRelationshipType(value)}
                          options={relationshipOptions}
                          className="w-full md:w-60"
                        />
                      </div>
                    </div>
                  </>
                )}
                <div className="flex justify-center">
                  <div className="flex justify-center pt-4 pb-8 w-72">
                    {/* {alert(formik.isValid)} */}
                    <Button
                      type="button"
                      label="Reset"
                      variant="ghost"
                      onClick={() => {
                        generatePolicyForm.resetForm();
                        searchForm.resetForm();
                      }}
                    />
                    <Button type="submit" label="Submit" variant="primary" />
                  </div>
                </div>
              </form>
              {/* </TabPanel> */}
              {/* </Tabs> */}
            </div>
          </div>
        </div>
        {/* <div className="flex justify-center">
          <div className="flex justify-center pt-4 pb-8 w-72">
           
            <Button type="button" label="Reset" variant="ghost" />
            <Button type="submit" label="Submit" variant="primary" disabled={!formik.isValid} />
          </div>
        </div> */}
        {/* </form> */}
      </div>
    </>
  );
};

export default GeneratePolicy;
