import "./App.css";
import React, { useRef, useEffect } from "react";
import toast, { Toaster } from "react-hot-toast";
import { isMobile } from "react-device-detect";
import loadingBarService from "./service/loadingbar.Service";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import DealerRegistration from "./pages/DealerRegistration";
import PageNotFound from "./pages/PageNotFound";
import { useFormik } from "formik";
import * as Yup from "yup";
import AppLayout from "./AppLayout";
import DealerUploadDocuments from "./pages/DealerUploadDocuments";
import DealerBankTransactions from "./pages/DealerBankTransactions";
import PurchaseStatus from "./pages/PurchaseStatus";
import TransactionRequests from "./pages/TransactionRequests";
import ProtectedRoute from "./components/ProtectedRoute";
import GeneratePolicy from "./pages/GeneratePolicy";
import DealerRegistrationStatus from "./pages/DealerRegistrationStatus";
import { PolicyData } from "./pages/PolicyData";
import { DealerQRCode } from "./pages/DealerQRCode";
import { Invoice } from "./pages/Invoice";
import { GstTransaction } from "./pages/GstTransaction";
import { TransactionStatement } from "./pages/TransactionStatement";
import { ServerDown } from "./pages/ServerDown";
import { RMData } from "./pages/admin/RMData";
import { SoldPolicies } from "./pages/admin/SoldPolicies";
import { Pincode } from "./pages/admin/Pincode";
import { FeedFile } from "./pages/admin/FeedFile";
import { CancelPolicies } from "./pages/admin/CancelPolicies";
import { ICPayments } from "./pages/admin/ICPayments";
import { PolicyList } from "./pages/admin/service/SoldPolicies";
import { RsaReport } from "./pages/admin/service/RsaReport";
import { TotalRsaReport } from "./pages/admin/service/TotalRsaReport";
// import { ServiceDashboard } from "./pages/admin/service/ServiceDashboard";
import { SoldPolicies as ServiceSoldPolicies } from "./pages/admin/service/SoldPolicies";
import { ServiceDashboard } from "./pages/admin/service/ServiceDashboard";
import { SalesDashboard } from "./pages/admin/sales/SalesDashboard";
import { AdminDashboard } from "./pages/admin/AdminDashboard";
const App = () => {
  useEffect(() => {
    // Example: Starting the loading bar when the app component mounts
    loadingBarService.start();

    // Optionally complete the loading bar after a fixed time or event
    setTimeout(() => loadingBarService.complete(), 2000);

    // Complete the loading bar when the component unmounts
    return () => {
      loadingBarService.complete();
    };
  }, []);
  const toastPosition = isMobile ? "bottom-center" : "bottom-right";
  return (
    <>
      <Toaster
        toastOptions={{
          className: "text-sm",
          position: `${toastPosition}`,
          duration: 10000,

          style: {
            borderRadius: "4px",
            padding: "0.5rem 1rem",
          },
        }}
      />

      <BrowserRouter>
        <Routes>
          <Route element={<AppLayout />}>
            <Route exact path="/" element={<ProtectedRoute />}>
              <Route index element={<Navigate replace to="login" />}></Route>
              <Route path="dashboard" element={<Dashboard />}></Route>
              <Route
                path="dealer-registration"
                element={<DealerRegistration />}
              ></Route>
              <Route
                path="upload-dealer-documents"
                element={<DealerUploadDocuments />}
              ></Route>
              <Route
                path="bank-transactions"
                element={<DealerBankTransactions />}
              ></Route>
              <Route
                path="service/generate-policy"
                element={<GeneratePolicy tvsType="service" />}
              ></Route>
              <Route
                path="service/generate-policy/:dealerId"
                element={<GeneratePolicy tvsType="service" />}
              ></Route>
              <Route
                path="sales/generate-policy"
                element={<GeneratePolicy tvsType="sales" />}
              ></Route>
              <Route
                path="sales/generate-policy/:dealerId"
                element={<GeneratePolicy tvsType="sales" />}
              ></Route>
              <Route
                path="payment-status"
                element={<TransactionRequests />}
              ></Route>
              <Route path="sold-policies" element={<PolicyData />}></Route>
              <Route path="admin-dashboard" element={<Dashboard />}></Route>
              <Route path="qr-code" element={<DealerQRCode />}></Route>
              <Route
                path="purchase-status/:policyNumber"
                element={<PurchaseStatus />}
              ></Route>
              <Route
                path="dealer-registration-status"
                element={<DealerRegistrationStatus />}
              ></Route>
              <Route path="invoice" element={<Invoice />}></Route>
              <Route
                path="gst-transaction"
                element={<GstTransaction />}
              ></Route>
              <Route
                path="transaction-statement"
                element={<TransactionStatement />}
              ></Route>
            </Route>
            <Route path="admin" element={<ProtectedRoute />}>
              <Route path="dashboard" element={<AdminDashboard />} />
              <Route path="sold-policies" element={<SoldPolicies />} />
              <Route path="rm-data" element={<RMData />} />
              <Route path="pincode" element={<Pincode />} />
              <Route path="ic-payments" element={<ICPayments />} />
              <Route path="feed-file" element={<FeedFile />} />
              <Route path="cancel-policies" element={<CancelPolicies />} />
              <Route path="dashboard" element={<Dashboard />}></Route>
              <Route path="service" element={<ProtectedRoute />}>
                <Route
                  path="sold-policies"
                  element={<ServiceSoldPolicies />}
                ></Route>
                <Route path="dashboard" element={<ServiceDashboard />} />
                <Route path="rsa-report" element={<RsaReport />}></Route>
                <Route
                  path="total-rsa-report"
                  element={<TotalRsaReport />}
                ></Route>
              </Route>
              <Route path="sales" element={<ProtectedRoute />}>
                <Route path="dashboard" element={<SalesDashboard />} />
              </Route>
            </Route>
          </Route>

          <Route path="login" element={<Login />}></Route>
          <Route path="server-down" element={<ServerDown />}></Route>
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default App;
